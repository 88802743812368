import React, { useState, useEffect } from "react";
import Card2 from "../Images/Headers/publicity_2.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { FacebookEmbed } from "react-social-media-embed";
import { TwitterEmbed } from "react-social-media-embed";
import Card1 from "../Images/Gallery/IMG20221004143851.jpg";
import Card3 from "../Images/Gallery/Artboard 1.png";

function LabNewsFeed1() {
  const [url, setUrl] = useState(
    "https://twitter.com/CIRCODU1/status/1820395288663646547"
  );

  useEffect(() => {
    setInterval(() => {
      // Update the url prop here
      setUrl("https://twitter.com/CIRCODU1/status/1820395288663646547");
    }, 15000); // Update every 15 seconds
  }, []);

  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">News & Events</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">Publicity</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">News & Events</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                CIRCODU Laboratory a Leader in Testing Cookstoves
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row mt-2">
            <div className="col-md-8 iconb">
              <div className="row">
                <div className="col-md-12 mt-2">
                  <div style={{ padding: "10px", textAlign: "left" }}>
                    <small className="posted">Posted on: May 31, 2024</small>
                  </div>
                  <h style={{ color: "#008250", fontSize: "16px" }}>
                    <b>
                      CIRCODU Laboratory a Leader in Testing Cookstoves for
                      Efficiency, Safety, Durability, and Emissions
                    </b>
                  </h>
                  <p className="texty"></p>
                  <img
                    src={Card3}
                    alt=""
                    height="500"
                    width="100%"
                    style={{ borderRadius: "1%" }}
                  />
                  <p className="texty"></p>
                  <p className="texty">
                    The Centre for Integrated Research and Community Development
                    Uganda (CIRCODU) laboratory is a Regional Knowledge and
                    Testing Centre (RKTC) in testing cookstoves for efficiency, safety,
                    and emissions. Since its establishment in 2010, the
                    laboratory has become a knowledge hub for testing, improving
                    and evaluating cookstoves. The laboratory promotes cleaner
                    cooking solutions that safeguard human health and the
                    environment throughout East Africa.
                  </p>
                  <h style={{ color: "#008250", fontSize: "16px" }}>
                    <b>A Regional Hub of Excellence</b>
                  </h>
                  <p className="texty"></p>
                  <p className="texty">
                    Recognized as a Regional Knowledge and Testing Centre by
                    international and national standards bodies, including
                    certification from the Uganda National Bureau of Standards
                    (UNBS/LRS/0023) and compliance with ISO/IEC 17025:2017, the
                    laboratory is pivotal in upholding rigorous standards for
                    cookstove testing.
                  </p>
                  <h style={{ color: "#008250", fontSize: "16px" }}>
                    <b>Impressive Milestones and Collaborations</b>
                  </h>
                  <p className="texty"></p>
                  <p className="texty">
                    Over the past decade, the laboratory has tested, monitored
                    and evaluated over 50,000 cookstoves for different clients
                    which has thus facilited a widespread adoption of improved
                    cookstoves across Uganda and the East African region.
                  </p>
                  <h style={{ color: "#008250", fontSize: "16px" }}>
                    <b>Comprehensive Testing Activities</b>
                  </h>
                  <p className="texty"></p>
                  <p className="texty">
                    The Laboratory conducts a variety of tests on cookstoves and
                    fuels, including:
                    <ol className="mt-1">
                      <li className="mt-1">
                        <b>Durability Tests:</b> Assessing the lifespan of
                        cookstoves under normal use.{" "}
                        <i style={{ color: "red" }}>
                          {" "}
                          “How long will a cookstove last?”
                        </i>
                      </li>
                      <li className="mt-1">
                        <b>Thermal Efficiency Tests:</b> Measures how
                        efficiently a cookstove converts fuel into cooking heat.{" "}
                        <i style={{ color: "red" }}>
                          {" "}
                          “How efficiently does the cookstove convert fuel into
                          heat for cooking?”
                        </i>
                      </li>
                      <li className="mt-1">
                        <b>Safety Tests:</b> Identifying potential hazards
                        during and/ or associated with cookstove use.{" "}
                        <i style={{ color: "red" }}>
                          {" "}
                          “Are there any hazards associated with using the
                          cookstove?”
                        </i>
                      </li>
                      <li className="mt-1">
                        <b>Controlled Cooking Tests:</b> Evaluates how much fuel
                        is needed to cook a specific meal in a real-world
                        cooking scenario.{" "}
                        <i style={{ color: "red" }}>
                          {" "}
                          “How much fuel is required to cook a typical meal?”
                        </i>
                      </li>
                      <li className="mt-1">
                        <b>Emissions Testing:</b> Measuring pollutants such as
                        carbon dioxide, carbon monoxide, and particulate matter
                        released during cooking.{" "}
                        <i style={{ color: "red" }}>
                          {" "}
                          “Does the cookstove release harmful gases or
                          pollutants?”
                        </i>
                      </li>
                    </ol>
                    <p className="texty">
                      These tests are applied to various cookstove technologies
                      using different fuels including charcoal, firewood,
                      briquettes, and electricity (notably electric pressure
                      cookers).
                    </p>
                    <img
                      src={Card1}
                      alt=""
                      height="500"
                      width="100%"
                      style={{ borderRadius: "1%" }}
                    />
                    <p></p>
                    <h style={{ color: "#008250", fontSize: "16px" }}>
                      <b>
                        Impact of the Laboratory on Cookstove Manufacturers,
                        Suppliers, and Consumer Choices
                      </b>
                    </h>
                    <p className="texty"></p>
                    <p className="texty">
                      The Laboratory's work has had a significant impact.
                    </p>
                    <ol className="mt-1">
                      <li className="mt-1">
                        By providing reliable testing data, they help
                        manufacturers design and develop better cookstoves:
                        cleaner, safer, and more efficient.
                      </li>
                      <li className="mt-1">
                        The data also helps suppliers and consumers choose
                        cookstoves that meet their needs.
                      </li>
                      <li className="mt-1">
                        In addition, the Lab's work informs decisions on how to
                        reduce harmful emissions and improve indoor air quality,
                        especially protecting women and children who are most
                        affected by traditional cooking methods.
                      </li>
                    </ol>
                    <p className="texty">
                      Are you interested in getting your Cookstoves Tested,
                      Monitored and Evaluated, CIRCODU has the expertise and
                      equipment to Test the Cookstoves Efficiency, Safety,
                      Durability, and Emissions. Contact the Laborotory at{" "}
                      <a href="mailto: info@circodu.org.ug">
                        {" "}
                        info@circodu.org.ug{" "}
                      </a>{" "}
                      and/ or call us on{" "}
                      <a href="Tel:+256392004309">
                        {" "}
                        {""} +256 (0) 392 004 309/{" "}
                      </a>
                      <a href="Tel:+256782433627">
                        {" "}
                        {""} +256 (0) 782 433 627{" "}
                      </a>
                    </p>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 iconb">
              <div className="row text-center">
                <div className="col-md-12 pt-2">
                  <h3 className="bold2">Social Media Feeds</h3>
                </div>
                <div className="col-md-12 pt-2">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <FacebookEmbed
                      url="https://www.facebook.com/100057727537068/posts/pfbid021uBEQcrSD16LMuA4j7eWaDpiL4k3qyFm7N6NGJknAfLsXSjo8ANyMir28sM296g8l/?app=fbl"
                      width={325}
                    />
                  </div>
                </div>
                <div className="col-md-12 pt-2">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <TwitterEmbed
                      embedPlaceholder={
                        <div
                          style={{
                            backgroundColor: "lightsteelblue",
                            padding: "150px 0",
                            textAlign: "center",
                            width: 325,
                            height: 430,
                          }}
                        />
                      }
                      url={url}
                      width={325}
                      height={430}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default LabNewsFeed1;
