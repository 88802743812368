import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Card1 from "../Images/Caurosel/SNV Group Photo.jpg";
import Card2 from "../Images/Caurosel/Team Training.jpg";
import Card3 from "../Images/Caurosel/Training for Co2Balance.jpg";
import Card4 from "../Images/Caurosel/Charcoal Chunning (4).jpg";
import Card5 from "../Images/Caurosel/Charcoal Chunning (11).jpg";
import Card6 from "../Images/Caurosel/Stove Warming.jpg";
import Card7 from "../Images/Caurosel/MEMD Training (2).jpg";
import Card8 from "../Images/Caurosel/Training for the o2Balance (2).jpg";
import Card9 from "../Images/Caurosel/HIVOS Team.jpg";
import Card10 from "../Images/Caurosel/Dutch Spark Team.jpg";
import Card11 from "../Images/Caurosel/BSUL Verification Meeting.jpg";
import Card12 from "../Images/Caurosel/IMEU Expo Team.jpg";
import Card13 from "../Images/Caurosel/IMG20240306120928.jpg";
import Card14 from "../Images/Caurosel/IMG20240325153442.jpg";
import Card15 from "../Images/Caurosel/IMG20230705141314.jpg";
import Card16 from "../Images/Caurosel/IMG20220630104947.jpg";
import Card17 from "../Images/Caurosel/IMG20230705114602.jpg";
import Card18 from "../Images/Caurosel/IMG20230518122333.jpg";
import Card19 from "../Images/Caurosel/IMG20230509112112.jpg";
import "../Components/Carousel.css";

function Carousels() {
  return (
    <div className="container-flex carous">
      <Carousel fade interval={8000}>
        <Carousel.Item>
          <img className="imageC" src={Card14} alt="CIRCODU Team" />
          <Carousel.Caption>
            <h5 className="carouseltext">
              <b>
                CIRCODU Staff and the Cookstove Laboratory Team with CLASP
                Representatives
              </b>
            </h5>
            <p className="carouseltext">
              Representatives from CLASP on a visit to our Cookstove Laboratory
              to discuss possibilities for technical and financial support,
              March 2024.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="imageC" src={Card13} alt="IMEU EXPO CIRCODU TEAM" />
          <Carousel.Caption>
            <h5 className="carouseltext">
              <b>SEI CIRCODU Focus Group Discussion</b>
            </h5>
            <p className="carouseltext">
              A Stockholm Environment Institute (SEI) Team in collaboration with
              the CIRCODU Team conducting a Focus Group Discussion with
              different players in the Uganda Cooking Sector, at Ministry of
              Energy and Mineral Development, March 2024. Discussions would feed
              into the Uganda Clean Cooking Strategy being developed by SEI.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="imageC" src={Card15} alt="CIRCODU Team" />
          <Carousel.Caption>
            <h5 className="carouseltext">
              <b>National eCooking Strategy Stakeholders Workshop</b>
            </h5>
            <p className="carouseltext">
              CIRCODU Team with Ministry of Energy and Mineral Development/
              Modern Energy Cooking Services (MECS) Team and stakeholders during
              a meeting on the progress of the National eCooking Strategy, at
              Esella Country Hotel, July 2023.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="imageC" src={Card16} alt="CIRCODU Team" />
          <Carousel.Caption>
            <h5 className="carouseltext">
              <b>IMEU Validation Stakeholders Workshop</b>
            </h5>
            <p className="carouseltext">
              CIRCODU Director General, Mr. Joseph Ndemere Arineitwe during the
              Stakeholders presentation of the findings in the Baseline and
              Market Assessment report on Energy Efficiency Interventions in
              Agriculture and Built Environment in Uganda (IMEU) at Silver
              Springs, Bugoloobi to SNV Netherland, August 2022.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="imageC" src={Card17} alt="CIRCODU Team" />
          <Carousel.Caption>
            <h5 className="carouseltext">
              <b>National eCooking Strategy Stakeholders Workshop</b>
            </h5>
            <p className="carouseltext">
              CIRCODU Team with Ministry of Energy and Mineral Development/
              Modern Energy Cooking Services (MECS) Team and stakeholders during
              a meeting on the progress of the National eCooking Strategy, at
              Esella Country Hotel, July 2023.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="imageC" src={Card18} alt="CIRCODU Team" />
          <Carousel.Caption>
            <h5 className="carouseltext">
              <b>Data Cleaning Exercise</b>
            </h5>
            <p className="carouseltext">
              Data Cleaning Exercise at the Office Premises with Teams that
              partipated in the eCooking Baseline Study to Assess the Status Quo
              for eCooking in Uganda and determine the contribution that
              eCooking could make towards the National Clean Cooking Strategy,
              May 2023.{" "}
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="imageC" src={Card19} alt="CIRCODU Team" />
          <Carousel.Caption>
            <h5 className="carouseltext">
              <b>Training of Research Assistants</b>
            </h5>
            <p className="carouseltext">
              Training of Research Assistants for the eCooking Baseline Study to
              Assess the Status Quo for eCooking in Uganda and determine the
              contribution that eCooking could make towards the National Clean
              Cooking Strategy, May 2023.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="imageC" src={Card1} alt="CIRCODU Team" />
          <Carousel.Caption>
            <h5 className="carouseltext">
              <b>IMEU Validation Stakeholders Workshop</b>
            </h5>
            <p className="carouseltext">
              CIRCODU Team with Stakeholders after the presentation of the
              findings in the Baseline and Market Assessment report on Energy
              Efficiency Interventions in Agriculture and Built Environment in
              Uganda (IMEU) at Silver Springs, Bugoloobi to SNV Netherlands,
              August 2022.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="imageC" src={Card2} alt="eCooking Baseline Study" />
          <Carousel.Caption>
            <h5 className="carouseltext">
              <b>Training of Field Research Assistants</b>
            </h5>
            <p className="carouseltext">
              During the training of Reseach Assistants for the National
              eCooking Baseline Study and Strategy development in Uganda,
              February 2023.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="imageC"
            src={Card3}
            alt="Training of Field Research"
          />
          <Carousel.Caption>
            <h5 className="carouseltext">
              <b>Training of Field Research Assistants</b>
            </h5>
            <p className="carouseltext">
              During the training of Reseach Assistants for the Baseline Study
              for the Dutch Spark for Clean Cooking Solutions on Pre-Project
              Fuel Consumption Patterns In Different Regions of Uganda, October
              2022.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="imageC"
            src={Card4}
            alt="Laboratory Team sorting charcoal"
          />
          <Carousel.Caption>
            <h5 className="carouseltext">
              <b>Charcoal Sorting</b>
            </h5>
            <p className="carouseltext">
              Laboratory Team sorting charcoal to be used in the cookstove
              testing process.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="imageC"
            src={Card5}
            alt="Laboratory Team sorting charcoal"
          />
          <Carousel.Caption>
            <h5 className="carouseltext1">
              <b>Charcoal Sorting</b>
            </h5>
            <p className="carouseltext1">
              Laboratory Team sorting charcoal to be used in the cookstove
              testing process.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="imageC"
            src={Card6}
            alt="Laboratory Team warming up cookstoves"
          />
          <Carousel.Caption>
            <h5 className="carouseltext">
              <b>Cookstove Warming</b>
            </h5>
            <p className="carouseltext">
              Laboratory Team warming up cookstoves during the cookstove testing
              process.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="imageC"
            src={Card7}
            alt="Clean Cooking Energy Needs Assessment"
          />
          <Carousel.Caption>
            <h5 className="carouseltext">
              <b>Training of Field Research Assistants</b>
            </h5>
            <p className="carouseltext">
              Staff During the Training on activities that were to be carried
              out under the Ministry of Energy and Mineral Development/ World
              Bank consultancy: Conducting Clean Cooking Energy Needs Assessment
              in Public and Private Institutions in Uganda, August 2022.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="imageC"
            src={Card8}
            alt="Training of Field Research"
          />
          <Carousel.Caption>
            <h5 className="carouseltext">
              <b>Training of Field Research Assistants</b>
            </h5>
            <p className="carouseltext">
              During the training of Reseach Assistants for the Baseline Study
              for the Dutch Spark for Clean Cooking Solutions on Pre-Project
              Fuel Consumption Patterns In Different Regions of Uganda, October
              2022.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="imageC" src={Card9} alt="CIRCODU Team" />
          <Carousel.Caption>
            <h5 className="carouseltext">
              <b>CIRCODU TEAM</b>
            </h5>
            <p className="carouseltext">
              During the training of Reseach Assistants for the Baseline Study
              for the Dutch Spark for Clean Cooking Solutions on Pre-Project
              Fuel Consumption Patterns In Different Regions of Uganda, October
              2022.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="imageC" src={Card10} alt="CIRCODU Team" />
          <Carousel.Caption>
            <h5 className="carouseltext1">
              <b>CIRCODU TEAM</b>
            </h5>
            <p className="carouseltext1">
              CIRCODU Team that participated in the UpEnergy Baseline Study and
              Kitchen Performance Tests in Schools across Four Administrative
              Regions in Uganda, June 2022.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="imageC"
            src={Card11}
            alt="Spot check Verification Exercise"
          />
          <Carousel.Caption>
            <h5 className="carouseltext">
              <b>Biogas Solutions Uganda Limited Verification Exercise</b>
            </h5>
            <p className="carouseltext">
              With a Team of verifiers from Biogas Solutions Uganda Limited/
              African Biogas Carbon Programme during a spot check Verification
              Exercise.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="imageC" src={Card12} alt="IMEU EXPO CIRCODU TEAM" />
          <Carousel.Caption>
            <h5 className="carouseltext1">
              <b>IMEU EXPO CIRCODU TEAM</b>
            </h5>
            <p className="carouseltext1">
              CIRCODU TEAM that represented at thre SNV IMEU Regional Energy
              Efficiency Awareness Expo (22nd to 26th March 2023) in FortPortal.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default Carousels;
