import React, { useState, useEffect } from "react";
import Card2 from "../Images/Headers/publicity_2.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { FacebookEmbed } from "react-social-media-embed";
import { TwitterEmbed } from "react-social-media-embed";
import Card1 from "../Images/Gallery/pELUM2.jpg";
import Card3 from "../Images/Gallery/pELUM1.jpg";

function TrainingBriquetteCookstove() {
  const [url, setUrl] = useState(
    "https://twitter.com/CIRCODU1/status/1820395288663646547"
  );

  useEffect(() => {
    setInterval(() => {
      // Update the url prop here
      setUrl("https://twitter.com/CIRCODU1/status/1820395288663646547");
    }, 15000); // Update every 15 seconds
  }, []);

  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">News & Events</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">Publicity</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">News & Events</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Training Services on Briquette and Cookstove Production
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row mt-2">
            <div className="col-md-8 iconb">
              <div className="row">
                <div className="col-md-12 mt-2">
                  <div style={{ padding: "10px", textAlign: "left" }}>
                    <small className="posted">Posted on: July 31, 2024</small>
                  </div>
                  <h style={{ color: "#008250", fontSize: "16px" }}>
                    <b>
                      Training Services on Briquette and Cookstove Production
                    </b>
                  </h>
                  <p className="texty"></p>
                  <p className="texty">
                  In late 2023, the Centre for Integrated Research and Community Development 
                  Uganda (CIRCODU) was contracted by PELUM Uganda to conduct a Training of
                  Trainers (TOT) program for a group of 30 affiliated smallholder farmers and 
                  aspiring entrepreneurs, comprising women, youth, and men, on the production of
                  briquettes and energy-efficient cookstoves (ECS).
                  </p>
                  <p className="texty">
                  This hands-on training, held in Mityana District, aimed to equip these farmers and
                  entrepreneurs with the necessary skills and knowledge to produce carbonized
                  briquettes and energy-efficient cookstoves, thereby promoting sustainable
                  energy use and environmental conservation within their communities nationwide.
                  </p>
                  <img
                    src={Card3}
                    alt=""
                    height="500"
                    width="100%"
                    style={{ borderRadius: "1%" }}
                  />
                  <p className="texty"></p>
                  <p className="texty">
                  The training program provided in-depth instruction on the production of
                  briquettes and the construction of domestic and institutional cookstoves using
                  wood and/or briquettes. Following a successful TOT, CIRCODU was re-contracted and 
                  tasked with extending technical backstopping support to trainees across
                  eleven districts in Uganda (currently ongoing) namely Mbale City, Kibale, Kabalore, Buikwe, 
                  Kabale, Soroti, Lira, Lamwo, and West Nile. This support involves
                  identifying challenges and addressing them, supplying and distributing essential
                  equipment, including briquette machines, and materials for building energy-efficient 
                  stoves to each trained group.
                  </p>
                  <p className="texty">
                  By empowering and strengthening the capacity of these trainers in their local
                  communities with the necessary skills and resources, PELUM Uganda aims to
                  accelerate the adoption of clean cooking technologies and contribute to environmental 
                  protection in the fulfilment of their organisational mission.
                  </p>
                  <h style={{ color: "#008250", fontSize: "16px" }}>
                    <b>Briquette Production Training Coverage</b>
                  </h>
                  <p className="texty"></p>
                  <p className="texty">
                  The five-day training program focused on equipping participants with the skills
                  to produce briquettes. The first day was dedicated to theoretical learning, covering 
                  briquette fundamentals, production processes, raw material selection, equipment use, 
                  quality control, application technologies, business management, and marketing strategies.
                  </p>
                  <p className="texty">
                  The subsequent four days emphasized hands-on experience. Trainees learned
                  to create various briquette shapes and sizes through a comprehensive process.
                  This included raw material preparation, carbonization, char processing, binder
                  mixing, pressing, briquette moulding, drier construction, briquette drying, and
                  storage techniques. Additionally, they explored the use of charcoal dust in
                  briquette production.
                  </p>
                  <h style={{ color: "#008250", fontSize: "16px" }}>
                    <b>Cookstove Production Training Coverage</b>
                  </h>
                  <p className="texty"></p>
                  <p className="texty">
                  The training also encompassed cookstove production, combining theoretical and
                  practical components. Participants gained knowledge of stove design, fabrication techniques, 
                  and dimensions. Practical sessions allowed them to build both domestic and institutional cookstoves.
                  </p>
                  <img
                    src={Card1}
                    alt=""
                    height="500"
                    width="100%"
                    style={{ borderRadius: "1%" }}
                  />
                  <p className="texty"></p>
                  <p className="texty">
                  The domestic stove training involved cutting, shaping, and assembling stove parts. 
                  The institutional stove training focused on constructing a double-pot (20L and 50L) 
                  firewood cookstove using a variety of materials and tools. Throughout
                  the process, trainees had opportunities to ask questions and share ideas.
                  </p>
                  <h style={{ color: "#008250", fontSize: "16px" }}>
                    <b>Marketing Exploration</b>
                  </h>
                  <p className="texty">
                  Towards the end of the training, participants engaged in discussions about
                  potential marketing strategies for both briquettes and cookstoves. Social media,
                  exhibitions, market days, advertising, and word-of-mouth were among the marketing channels explored.
                  </p>
                    <p className="texty">
                    Stay tuned on our online platforms for more updates on our ongoing projects and initiatives! Contact us at{" "}
                      <a href="mailto: info@circodu.org.ug">
                        {" "}
                        info@circodu.org.ug{" "}
                      </a>{" "}
                      and/ or call us on{" "}
                      <a href="Tel:+256392004309">
                        {" "}
                        {""} +256 (0) 392 004 309/{" "}
                      </a>
                      <a href="Tel:+256782433627">
                        {" "}
                        {""} +256 (0) 782 433 627{" "}
                      </a>
                    </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 iconb">
              <div className="row text-center">
                <div className="col-md-12 pt-2">
                  <h3 className="bold2">Social Media Feeds</h3>
                </div>
                <div className="col-md-12 pt-2">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <FacebookEmbed
                      url="https://www.facebook.com/100057727537068/posts/pfbid021uBEQcrSD16LMuA4j7eWaDpiL4k3qyFm7N6NGJknAfLsXSjo8ANyMir28sM296g8l/?app=fbl"
                      width={325}
                    />
                  </div>
                </div>
                <div className="col-md-12 pt-2">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <TwitterEmbed
                      embedPlaceholder={
                        <div
                          style={{
                            backgroundColor: "lightsteelblue",
                            padding: "150px 0",
                            textAlign: "center",
                            width: 325,
                            height: 430,
                          }}
                        />
                      }
                      url={url}
                      width={325}
                      height={430}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TrainingBriquetteCookstove;
