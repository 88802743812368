import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import "../Pages/Contact.css";
import Card2 from "../Images/Headers/contAct-us_3.jpg";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { IconButton } from "@mui/material";
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import SendIcon from '@mui/icons-material/Send';

function Contact() {
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };

    return (
        <div>
            <div className="head-text">
                <div className="head-image">
                    <img src={Card2} alt="" height="200" width="100%" />
                </div>
                <div class='text-on-image'>
                    <h4 className="bold">Contact Us</h4>
                    <div class="text-center">
                        <Breadcrumb>
                            <Breadcrumb.Item className="text1" href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item className="text1">Contact Us</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
            <section className="section">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-3 iconb">
                            <div className="row text-center">
                                <div className="col-md-3">
                                    <a href="https://goo.gl/maps/ZmZM5rhrpHyFPhSu5" target="_blank" rel="noopener noreferrer">
                                        <IconButton sx={{ bgcolor: "#e6faee", color: "#44fc8b" }}>
                                            <LocationOnIcon sx={{ fontSize: "50px", color: "#008250" }} />
                                        </IconButton>
                                    </a>
                                </div>
                                <div className='col-md-9 texticon'>
                                    Plot 5416, Kireka - Bbira, Buj'ko
                                    <br></br>
                                    P.O. Box 16340, Wandegeya (U)
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 iconb">
                            <div className="row text-center">
                                <div className="col-md-3">
                                    <a href="Tel:+256392004309">
                                        <IconButton sx={{ bgcolor: "#e6faee", color: "#44fc8b" }}>
                                            <CallIcon sx={{ fontSize: "50px", color: "#008250" }} />
                                        </IconButton>
                                    </a>
                                </div>
                                <div className='col-md-9 texticon'>
                                    +256 392 004 309
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 iconb">
                            <div className="row text-center">
                                <div className="col-md-3">
                                    <a href="https://wa.me/256782433627" target="_blank" rel="noopener noreferrer">
                                        <IconButton sx={{ bgcolor: "#e6faee", color: "#44fc8b" }}>
                                            <WhatsAppIcon sx={{ fontSize: "50px", color: "#008250" }} />
                                        </IconButton>
                                    </a>
                                </div>
                                <div className='col-md-9 texticon'>
                                    +256 782 433 627
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 iconb">
                            <div className="row text-center">
                                <div className="col-md-3">
                                    <a href="mailto:info@circodu.org.ug" target="_blank" rel="noopener noreferrer">
                                        <IconButton sx={{ bgcolor: "#e6faee", color: "#44fc8b" }}>
                                            <EmailIcon sx={{ fontSize: "50px", color: "#008250" }} />
                                        </IconButton>
                                    </a>
                                </div>
                                <div className='col-md-9 texticon'>
                                    info@circodu.org.ug
                                </div>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <div >
                        <div >
                            <div className="row">
                                <div className="col-md-6 mt-2">
                                    <h4 className="header" style={{ color: "#008250" }}>Leave Your Message</h4>
                                    <hr />
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Label><b>First Name:</b></Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="First Name"
                                                />
                                                <Form.Control.Feedback type="invalid">Please enter your First Name.</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom02">
                                                <Form.Label><b>Last Name:</b></Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Last Name"
                                                />
                                                <Form.Control.Feedback type="invalid">Please enter your Last Name.</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                                                <Form.Label className="mt-2"><b>Email Address:</b></Form.Label>
                                                <InputGroup hasValidation>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Email Address"
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please enter your Email Address.
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                                                <Form.Label className="mt-2"><b>Phone Number:</b></Form.Label>
                                                <InputGroup hasValidation>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Phone Number"
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please enter your Phone Number.
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                <Form.Label className="mt-2"><b>Message:</b></Form.Label>
                                                <InputGroup hasValidation>
                                                    <Form.Control
                                                        as="textarea" rows={4}
                                                        placeholder="Message"
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please enter your Message.
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                        </Row>
                                        <Form.Group className="mb-3">
                                            <Form.Check
                                                required
                                                label="Agree to terms and conditions"
                                                feedback="By using this form you agree with the storage and handling of your data by our Website."
                                                feedbackType="invalid"
                                            />
                                        </Form.Group>
                                        <Button variant="outline-success" type="submit">Send Message <SendIcon /></Button>
                                    </Form>
                                </div>
                                <div className="col-md-6 border-start mt-2">
                                    <h4 className="header" style={{ color: "#008250" }}>CIRCODU's Home</h4>
                                    <hr />
                                    <div className="icon-box media mb-0 pb-0">
                                        <div >
                                            <iframe className="col-md-12 mapped" title="My Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63836.03896817108!2d32.50932833825638!3d0.32927168849757155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177dbb180ea221c9%3A0x23bf91a6d2f87ba6!2sCIRCODU!5e0!3m2!1sen!2sug!4v1672315089969!5m2!1sen!2sug" frameborder="2" style={{ border: 1 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Contact