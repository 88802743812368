import * as React from "react";
import Card45 from "../Images/Partners/unacc.jpg";
import "../Components/Owl.css";

export default function MultiActionAreaCard() {
  return (
    <div>
      <a
        href="https://www.unaccug.org/"
        target="_blank"
        rel="noopener noreferrer"
        className="bold2"
      >
        <img
          src={Card45}
          alt=""
          style={{
            borderRadius: "5%",
            boxShadow: "none",
            transition: "box-shadow 0.3s ease-in-out",
            height: "auto",
            maxWidth: "100%",
            transform: "scale(1) 0.5s ease-in-out",
            width: "100%",
          }}
          onMouseEnter={(event) => {
            event.target.style.boxShadow =
              "2px 2px 2px 2px rgba(138, 255, 138, 1)";
            event.target.style.transform = "scale(1.02)";
          }}
          onMouseLeave={(event) => {
            event.target.style.boxShadow = "none";
            event.target.style.transform = "scale(1)";
          }}
        />
      </a>
    </div>
  );
}
