import React from "react";
import Card2 from "../Images/Headers/who-we-are.jpg";
import Card1 from "../Images/Asset 2-100.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function WhoWeAre() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Who We Are</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">About Us</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">Who We Are</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-8 mt-2">
              <p className="texthome1">
                Centre for Integrated Research and Community Development Uganda
                (CIRCODU) is a non-profit organization dedicated to promoting
                health awareness, sustainable energy resource use, and
                environmental conservation. The organization was incorporated in
                2007.
              </p>
              <p className="texthome1">
                CIRCODU is known for its research-based approach and its
                leadership in monitoring and evaluating energy projects in
                Uganda, East African region, and beyond.
              </p>
              <p className="texthome1">
                The core business of CIRCODU involves research implementation
                and consultancy services in the fields of energy, environment,
                public health, water & sanitation, waste management, and
                agriculture.
              </p>
              <p className="texthome1">
                CIRCODU is committed to making a positive impact on society
                through research projects and consultancy services.
              </p>
            </div>
            <div className="col-md-4 mt-2">
              <div className="imagecard">
                <img
                  src={Card1}
                  alt=""
                  height="250"
                  width="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default WhoWeAre;
