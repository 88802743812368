import React from "react";
import Card2 from "../Images/Headers/team.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import "../Profiles/profileimg.css";
import Card5 from "../Images/Profiles/Profile/Joseph.jpg";

function JosephNdemereArineitwe() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Mr. Joseph Ndemere Arineitwe</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">The Team</Breadcrumb.Item>
              <Breadcrumb.Item
                className="text1"
                href="/the-team/top-management"
              >
                Top Management
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Mr. Joseph Ndemere Arineitwe
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Mr. Joseph Ndemere Arineitwe</b>
              </h>
              <br></br>
              <h>
                <b>Director General</b>
              </h>
              <br></br>
              <h className="texty">
                Renewable Energy and Carbon Finance Specialist
              </h>
            </div>
            <div className="col-md-3 mt-2">
              <div className="imagecarded">
                <img
                  src={Card5}
                  alt=""
                  height="auto" width="100%" 
                  style={{
                    borderRadius: "45%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                    event.target.style.transform = "scale(1.01)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </div>
            </div>
            <div className="col-md-9 mt-2">
              <p className="texty">
                Joseph possesses a Master's degree in Mechanical Engineering
                with a specialization in Sustainable Energy Engineering,
                obtained from the Royal Institute of Technology (KTH) in Sweden.
                His academic journey also includes a Bachelor's degree in
                Mechanical Engineering conferred upon him by Makerere
                University. Furthermore, he has earned a Certificate in Energy
                Systems and Energy Carriers in developing countries,
                encompassing solar, hydro, wind, biomass, and related subjects.
              </p>
              <p className="texty">
                With a remarkable tenure exceeding a decade, Joseph has played a
                pivotal role in steering the implementation of all endeavors
                undertaken by the Centre for Integrated Research and Community
                Development Uganda (CIRCODU). His extensive experience positions
                him as one of the foremost seasoned professionals in the realm
                of Biomass Energy Systems and Interventions, offering
                unparalleled services in Study Implementation, Assessment,
                Monitoring, and Evaluation within Uganda and the broader East
                African region.
              </p>
              <p className="texty">
                Joseph has skillfully demonstrated his aptitude in
                conceptualizing, designing, and executing a diverse array of
                Study Projects centered around Biomass Energy. His expertise has
                been applied across a spectrum of communities and settings, both
                locally in Uganda and beyond its borders. Among his notable
                achievements, he has orchestrated the planning and execution of
                various studies for distinct organizations within Uganda. These
                encompass a range of study types, including Feasibility Studies,
                Baseline Studies, Project Studies, Market Intelligence Studies,
                Willingness-To-Pay Studies, and Impact Studies. Notable
                organizations that have benefited from his expertise include
                Finnfund, SNV, Hivos Foundation, Biogas Solutions Uganda
                Limited, Habitat for Humanity Uganda, World Wide Fund for
                Nature, Impact Carbon, My climate, and Environment Conservation
                Trust Uganda, among others.
              </p>
              <p className="texty">
                Joseph's expertise extends to the realm of National Renewable
                Energy Studies, where he has undertaken significant initiatives
                such as the National Charcoal Survey for Uganda in 2015, the
                National Baseline Study on Clean Cooking in Uganda in 2019, and
                the National Baseline Survey on Existing, Planned, and Demanded
                National Standards and Quality Guidelines for the Renewable
                Energy Sector in 2018-2019. These initiatives have been
                conducted in collaboration with entities like the Ministry of
                Energy and Mineral Development (MEMD), Uganda National Alliance
                for Clean Cooking (UNACC), and Uganda National Renewable Energy
                and Energy Efficiency Association (UNREEEA).
              </p>
              <p className="texty">
                In addition to his comprehensive technical proficiency, Joseph
                possesses a unique ability to craft Technical Reports that are
                accessible and understandable to both technical and
                non-technical audiences. His extensive research activities have
                primarily revolved around the Development and Utilization of
                Biogas Technology, Biomass Gasification for Industrial Thermal
                Applications, Behavioral Change Interventions for the Adoption
                and Use of Cookstoves, and Marketing and Distribution Approaches
                for Cookstoves and Solar Lamps.
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-2">
            <div className="container textcenter2">
              <div className="col-md-1">
                <a
                  href="https://www.linkedin.com/in/joseph-ndemere-arineitwe-56474625/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <LinkedInIcon
                      sx={{ fontSize: "22px", color: "darkblue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="https://twitter.com/josephndemere"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <TwitterIcon sx={{ fontSize: "22px", color: "blue" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a href="https://www.facebook.com/josephndemere.arineitwe/" target="_blank" rel="noopener noreferrer">
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <FacebookOutlinedIcon
                      sx={{ fontSize: "22px", color: "blue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="https://wa.me/256782433627"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <WhatsAppIcon sx={{ fontSize: "22px", color: "teal" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="mailto:joseph.ndemere@circodu.org.ug"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <EmailIcon sx={{ fontSize: "22px", color: "red" }} />
                  </IconButton>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default JosephNdemereArineitwe;
