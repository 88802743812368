import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "../Components/Objectives.css";
import { Link } from "react-router-dom";

function Objectives() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="container objectiv">
      <div className="row">
        <div className="col-md-6">
          <div className="row extend">
            <div className="col-md-12 mb-4 text-center main-heading-para">
              <h3
                className="main-heading-obj py-2"
                style={{ color: "#008250" }}
              >
                Who We Are
              </h3>
              <div className="underline-card mx-auto"></div>
              <p className="texthome1">
                Centre for Integrated Research and Community Development Uganda
                (CIRCODU) is a non-profit organization dedicated to promoting
                health awareness, sustainable energy resource use, and
                environmental conservation. The organization was incorporated in
                2007.
              </p>
              <p className="texthome1">
                CIRCODU is known for its research-based approach and its
                leadership in monitoring and evaluating energy projects. We possess a
                wealth of experience in executing studies not only within Uganda
                but also in countries such as Kenya, Tanzania, Gambia, Mali, Sudan and beyond.
              </p>
              <p className="texthome1">
                The core business of CIRCODU involves research implementation
                and consultancy services in the fields of energy, environment,
                public health, water & sanitation, waste management, and
                agriculture and thus committed to making a positive impact on society
                through such.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-12 mb-4 text-center main-heading-obj">
              <h3
                className="main-heading-obj py-2"
                style={{ color: "#008250" }}
              >
                What We Do
              </h3>
              <div className="underline-card mx-auto"></div>
            </div>
          </div>
          <Accordion style={{ color: "#008250", bgcolor: "#008250" }}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <b style={{ color: "#008250", fontSize: "14px" }}>
                  Research Projects
                </b>
              </Accordion.Header>
              <Accordion.Body style={{ color: "#000000" }}>
                <p className="texthome">
                  CIRCODU, in collaboration with local and international
                  organizations, has initiated various projects to advance
                  sustainable development. These initiatives encompass a wide
                  range of activities, such as conducting assessments of clean
                  cooking energy requirements, establishing baseline and
                  end-line studies, ...
                </p>
                <div className="">
                  <Link
                    to="/our-services/research"
                    className="btn btn-outline-success"
                    onClick={scrollToTop}
                  >
                    Read More
                  </Link>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <b style={{ color: "#008250", fontSize: "14px" }}>
                  Consultancy
                </b>
              </Accordion.Header>
              <Accordion.Body style={{ color: "#000000" }}>
                <p className="texthome">
                  Apart from its research and monitoring endeavors, CIRCODU also
                  extends its expertise through consultancy offerings to both
                  local and global clients. These specialized services encompass
                  evaluations of market dynamics, feasibility considerations,
                  midterm assessments of renewable energy initiatives, ...
                </p>
                <div className="">
                  <Link
                    to="/our-services/consultancy"
                    className="btn btn-outline-success"
                    onClick={scrollToTop}
                  >
                    Read More
                  </Link>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <b style={{ color: "#008250", fontSize: "14px" }}>
                  Monitoring and Evaluation
                </b>
              </Accordion.Header>
              <Accordion.Body style={{ color: "#000000" }}>
                <p className="texthome">
                  Ever since its inception, CIRCODU has been actively engaged in
                  the surveillance of indoor air pollution and the advocacy for
                  eco-friendly technologies. Specifically, the organization has
                  been dedicated to enhancing the performance of wood and
                  charcoal cookstoves used in households and institutions, with
                  the ultimate ...
                </p>
                <div className="">
                  <Link
                    to="/our-services/monitoring-and-evaluation"
                    className="btn btn-outline-success"
                    onClick={scrollToTop}
                  >
                    Read More
                  </Link>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <b style={{ color: "#008250", fontSize: "14px" }}>
                  Cookstoves and Fuel Testing
                </b>
              </Accordion.Header>
              <Accordion.Body style={{ color: "#000000" }}>
                <p className="texthome">
                  Centre for Integrated Research and Community Development
                  Uganda (CIRCODU) Cookstoves and Fuel Testing Laboratory was
                  established in 2010, and has since become a Regional Knowledge
                  and Testing Center recognized and supported by the Clean
                  Cooking Alliance. The Laboratory is recognized by the ...
                </p>
                <div className="">
                  <Link
                    to="/our-services/cookstoves-and-fuel-testing"
                    className="btn btn-outline-success"
                    onClick={scrollToTop}
                  >
                    Read More
                  </Link>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <b style={{ color: "#008250", fontSize: "14px" }}>
                  Capacity Building
                </b>
              </Accordion.Header>
              <Accordion.Body style={{ color: "#000000" }}>
                <p className="texthome">
                  In conjunction with its various other undertakings, CIRCODU
                  has expanded its capabilities to offer training programs. The
                  organization has prioritized the establishment of
                  community-based training initiatives aimed at empowering
                  individuals and collectives to create alternative,
                  environmentally friendly ...
                </p>
                <div className="">
                  <Link
                    to="/our-services/capacity-building"
                    className="btn btn-outline-success"
                    onClick={scrollToTop}
                  >
                    Read More
                  </Link>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default Objectives;
