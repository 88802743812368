import React, { useState, useEffect } from "react";
import Card2 from "../Images/Headers/publicity_2.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { FacebookEmbed } from "react-social-media-embed";
import { TwitterEmbed } from "react-social-media-embed";
import Card1 from "../Images/Gallery/Lab1.jpg";
import Card3 from "../Images/Gallery/Lab2.jpg";

function TestingBioLiteCookstoves() {
  const [url, setUrl] = useState(
    "https://twitter.com/CIRCODU1/status/1820395288663646547"
  );

  useEffect(() => {
    setInterval(() => {
      // Update the url prop here
      setUrl("https://twitter.com/CIRCODU1/status/1820395288663646547");
    }, 15000); // Update every 15 seconds
  }, []);

  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">News & Events</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">Publicity</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">News & Events</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Testing of BioLite Cookstoves
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row mt-2">
            <div className="col-md-8 iconb">
              <div className="row">
                <div className="col-md-12 mt-2">
                  <div style={{ padding: "10px", textAlign: "left" }}>
                    <small className="posted">Posted on: July 31, 2024</small>
                  </div>
                  <h style={{ color: "#008250", fontSize: "16px" }}>
                    <b>
                      Testing of BioLite Cookstoves at the CIRCODU Cookstove
                      Laboratory
                    </b>
                  </h>
                  <p className="texty"></p>
                  <p className="texty">
                    CIRCODU Cookstove Testing Laboratory recently received 54
                    Cookstove samples from BioLite, Kenya. These stoves were
                    part of a Gold Standard Program of Activities aimed at
                    quantifying greenhouse gas emissions reduction achieved
                    through their use.
                  </p>
                  <img
                    src={Card1}
                    alt=""
                    height="500"
                    width="100%"
                    style={{ borderRadius: "1%" }}
                  />
                  <p className="texty"></p>
                  <p className="texty">
                    The laboratory conducted a comprehensive monitoring study
                    using Water Boiling Tests (WBTs) according to the Global
                    Alliance for Clean Cookstoves WBT Version 4.2.3 Protocol.
                    This study measured the stoves' thermal efficiency and fuel
                    consumption.
                  </p>
                  <p className="texty">
                    Utilizing calibrated equipment, the study provided robust
                    data on the performance of various BioLite stove models and
                    production years. The goal was to accurately determine the
                    stoves' ability to reduce greenhouse gas emissions and
                    improve fuel efficiency in households.
                  </p>
                  <p className="texty">
                    Are you interested in getting your Cookstoves Tested,
                    Monitored and Evaluated, CIRCODU has the expertise and
                    equipment to Test the Cookstoves Efficiency, Safety,
                    Durability, and Emissions. Stay tuned on our online platforms
                    for more updates on our ongoing projects and initiatives!
                    Contact us at{" "}
                    <a href="mailto: info@circodu.org.ug">
                      {" "}
                      info@circodu.org.ug{" "}
                    </a>{" "}
                    and/ or call us on{" "}
                    <a href="Tel:+256392004309"> {""} +256 (0) 392 004 309/ </a>
                    <a href="Tel:+256782433627"> {""} +256 (0) 782 433 627 </a>
                  </p>
                  <img
                    src={Card3}
                    alt=""
                    height="500"
                    width="100%"
                    style={{ borderRadius: "1%" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 iconb">
              <div className="row text-center">
                <div className="col-md-12 pt-2">
                  <h3 className="bold2">Social Media Feeds</h3>
                </div>
                <div className="col-md-12 pt-2">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <FacebookEmbed
                      url="https://www.facebook.com/100057727537068/posts/pfbid021uBEQcrSD16LMuA4j7eWaDpiL4k3qyFm7N6NGJknAfLsXSjo8ANyMir28sM296g8l/?app=fbl"
                      width={325}
                    />
                  </div>
                </div>
                <div className="col-md-12 pt-2">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <TwitterEmbed
                      embedPlaceholder={
                        <div
                          style={{
                            backgroundColor: "lightsteelblue",
                            padding: "150px 0",
                            textAlign: "center",
                            width: 325,
                            height: 430,
                          }}
                        />
                      }
                      url={url}
                      width={325}
                      height={430}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TestingBioLiteCookstoves;
