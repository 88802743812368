import React from "react";
import Card2 from "../Images/Headers/team.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import "../Profiles/profileimg.css";
import Card6 from "../Images/Profiles/Eunice.jpg";

function EuniceKyomuhendo() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Mrs. Eunice Kyomuhendo</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">The Team</Breadcrumb.Item>
              <Breadcrumb.Item
                className="text1"
                href="/the-team/the-staff-team"
              >
                The Staff Team
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Mrs. Eunice Kyomuhendo
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Mrs. Eunice Kyomuhendo</b>
              </h>
              <br></br>
              <h>
                <b>Cleaner</b>
              </h>
            </div>
            <div className="col-md-3 mt-2">
              <div className="imagecarded">
                <img
                  src={Card6}
                  alt=""
                  height="auto" width="100%"
                  style={{
                    borderRadius: "45%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                    event.target.style.transform = "scale(1.01)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </div>
            </div>
            <div className="col-md-9 mt-2">
              <p className="texty">
                Eunice Kyomuhendo has a primary leaving education certificate
                and has embarked on a varied career path. She has been employed
                as a cleaner for the past six years and continues in this role.
                In addition, Eunice has served as a welfare officer for various
                organizations in the past. Currently, she fulfills the position
                of Cleaner at CIRCODU.
              </p>
            </div>
            <div className="col-md-6 mt-2">
              <div className="container textcenter2">
                <div className="col-md-1">
                  <a href="https://ug.linkedin.com/company/centre-for-integrated-research-and-community-development-uganda-circodu" target="_blank" rel="noopener noreferrer">
                    <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                      <LinkedInIcon
                        sx={{ fontSize: "22px", color: "darkblue" }}
                      />
                    </IconButton>
                  </a>
                </div>
                <div className="col-md-1">
                  <a href="https://twitter.com/CIRCODU1" target="_blank" rel="noopener noreferrer">
                    <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                      <TwitterIcon sx={{ fontSize: "22px", color: "blue" }} />
                    </IconButton>
                  </a>
                </div>
                <div className="col-md-1">
                  <a href="https://www.facebook.com/Circodu/" target="_blank" rel="noopener noreferrer">
                    <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                      <FacebookOutlinedIcon
                        sx={{ fontSize: "22px", color: "blue" }}
                      />
                    </IconButton>
                  </a>
                </div>
                <div className="col-md-1">
                  <a href="https://wa.me/" target="_blank" rel="noopener noreferrer">
                    <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                      <WhatsAppIcon sx={{ fontSize: "22px", color: "teal" }} />
                    </IconButton>
                  </a>
                </div>
                <div className="col-md-1">
                  <a href="mailto:info@circodu.org.ug" target="_blank" rel="noopener noreferrer">
                    <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                      <EmailIcon sx={{ fontSize: "22px", color: "red" }} />
                    </IconButton>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default EuniceKyomuhendo;
