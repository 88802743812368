import React from "react";
import Card2 from "../Images/Headers/consultancy.jpg";
import Card1 from "../Images/Asset 2-100.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function Consultancy() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Consultancy</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">Our Services</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">Consultancy</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Consultancy</b>
              </h>
              <p className="texty">
                Apart from its research and monitoring endeavors, CIRCODU also
                extends its expertise through consultancy offerings to both
                local and global clients. These specialized services encompass
                evaluations of market dynamics, feasibility considerations,
                midterm assessments of renewable energy initiatives, testing of
                essential information documents for consumers, and other related
                provisions.
              </p>
            </div>
            <div className="col-md-4">
              <div className="imagecard">
                <img
                  src={Card1}
                  alt=""
                  height="250" 
                  width="100%"
                  style={{ borderRadius: "5%" }}
                />
              </div>
            </div>
            <div className="col-md-8 mt-2">
              <p className="texty">
                Moreover, CIRCODU has taken an active role in initiatives
                focused on the restoration of wetlands in Uganda. The
                organization has meticulously crafted intricate plans for
                compact water storage and retention facilities on a smaller
                scale, as well as a comprehensive strategy for managing silt
                traps, controlling floods, and optimizing the recovery of water
                resources. These endeavors are directed at augmenting the
                preservation of wetlands, integral ecosystems that wield pivotal
                influence in regulating water provision and providing natural
                habitats for diverse wildlife species.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Consultancy;
