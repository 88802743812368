import React from "react";
import Carousel from "../Components/Carousel";
import { Link } from "react-router-dom";
import "../Pages/Home.css";
import Owl from "../Components/Owl";
import "../Pages/OurHistory.css";
import CardOwl from "../Components/CardOwl";
import ShadowCard from "../Components/ShadowCard";
import Objectives from "../Components/Objectives";
import WelcomeSPin from "../Components/WelcomeSPin";

function Home() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <Carousel />
      <section className="section">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-9 text-center">
              <br></br>
              <h3 className="main-heading" style={{ color: "#008250" }}>
                Message From The Director General
              </h3>
              <div className="underline-card2 mx-auto"></div>
              <p className="texthome">
                It is my pleasure to welcome you to our website on behalf of the
                entire CIRCODU Family. We appreciate your interest in our
                organization and are excited to share information about our
                mission, values, and activities.
              </p>
              <p className="texthome">
                At CIRCODU, we strive to provide exceptional services and
                solutions that are tailored to meet the unique needs of our
                clients. We are committed to excellence and continuously seek to
                improve our services through innovation, collaboration, and
                feedback from our customers and partners.
              </p>
              <Link
                to="/the-team/top-management"
                className="btn btn-outline-success"
                onClick={scrollToTop}
              >
                Read More
              </Link>
            </div>
            <div className="col-md-3 text-center border-start mt-4">
              <br></br>
              <WelcomeSPin />
            </div>
          </div>
        </div>
      </section>
      <ShadowCard />
      <Objectives />
      <section className="section1 border-bottom">
        <div className="container-fluid">
          <div className="row">
            <div style={{ display: "fluid", justifyContent: "center" }}>
              <div className="row text-center">
                <div className="col-md-12 text-center">
                  <h3 className="main-heading" style={{ color: "#008250" }}>
                    Thematic Areas
                  </h3>
                  <div className="underline-card mx-auto"></div>
                  <CardOwl />
                </div>
                <div className="col-md-12 text-center">
                  <h3 className="main-heading" style={{ color: "#008250" }}>
                    Our Clients and Partners
                  </h3>
                  <div className="underline-card2 mx-auto"></div>
                  <br></br>
                  <Owl />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
