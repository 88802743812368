import React from "react";
import Card2 from "../Images/Headers/team.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import "../Profiles/profileimg.css";

function KennethArinaitwe() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Dr. Kenneth Arinaitwe</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">The Team</Breadcrumb.Item>
              <Breadcrumb.Item
                className="text1"
                href="/the-team/top-management"
              >
                Top Management
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Dr. Kenneth Arinaitwe
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Dr. Kenneth Arinaitwe</b>
              </h>
              <br></br>
              <h>
                <b>Chairman, Executive Council</b>
              </h>
              <br></br>
              <h className="texty">Public Health Expert</h>
            </div>
            <div className="col-md-3 mt-2">
              <div className="imagecarded">
                <img
                  src={Card2}
                  alt=""
                  height="auto" width="100%"
                  style={{
                    borderRadius: "45%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                    event.target.style.transform = "scale(1.01)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </div>
            </div>
            <div className="col-md-9 mt-2">
              <p className="texty">
                Kenneth is a holder of a Doctor of Philosophy degree conferred
                by Makerere University, specializing in Environmental Analytical
                Chemistry. His doctoral research was centered on the
                comprehensive examination of organic chemical contaminants
                present in both the atmospheric and aquatic ecosystems of Lake
                Victoria.
              </p>
              <p className="texty">
                Kenneth's academic journey also encompasses a Master's degree in
                Chemistry obtained from Makerere University, complemented by a
                Bachelor's degree in Science with Education, earned from Mbarara
                University of Science and Technology. His commitment to
                education extends to his role as a educator within the Department
                of Chemistry at Makerere University. Beyond academia, Kenneth's
                career path has included teaching Chemistry at the secondary
                school level, demonstrating his dedication to fostering
                scientific knowledge at different educational stages.
              </p>
              <p className="texty">
                His research pursuits are diverse and encompass the examination
                of the prevalence and fate of organic chemical contaminants in
                tropical environments, the intricate interplay between climate
                change and environmental chemical pollution, as well as the
                emergence of chemical contaminants in ecosystems and their
                impacts on both wildlife and human health.
              </p>
              <p className="texty">
                Kenneth's affiliations extend beyond Makerere University and
                CIRCODU. He has held the prestigious position of a Humboldtian –
                Georg Forster Fellow, conferred upon him by the Alexander von
                Humboldt Foundation. During his tenure in this role, he
                contributed to the Analytical Chemistry department at the
                Helmholtz Centre for Environmental Research – UFZ in Leipzig,
                Germany, from May 2017 to April 2020. His areas of interest
                expand further into environmental risk and impact assessment,
                agriculture, food safety, and water and sanitation.
              </p>
              <p className="texty">
                Kenneth has a distinguished record of service, having previously
                served as a Program Coordinator on the Africa Executive
                Committee for the World Academy (now Association) of Young
                Scientists (WAYS). He is also an active member of several
                professional organizations, including the Society of
                Environmental Toxicology and Chemistry (SETAC), the Royal
                Society of Chemistry (RSC), the American Chemical Society (ACS),
                and the Pan African Chemistry Network (PACN). His extensive
                expertise and multifaceted interests underscore his significant
                contributions to the field of Environmental Analytical Chemistry
                and its various intersections.
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-2">
            <div className="container textcenter2">
              <div className="col-md-1">
                <a
                  href="https://ug.linkedin.com/in/kennetharinaitwe"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <LinkedInIcon
                      sx={{ fontSize: "22px", color: "darkblue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a href="https://twitter.com/CIRCODU1" target="_blank" rel="noopener noreferrer">
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <TwitterIcon sx={{ fontSize: "22px", color: "blue" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a href="https://www.facebook.com/Circodu/" target="_blank" rel="noopener noreferrer">
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <FacebookOutlinedIcon
                      sx={{ fontSize: "22px", color: "blue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="https://wa.me/256772858394"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <WhatsAppIcon sx={{ fontSize: "22px", color: "teal" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="mailto:kenneth.arinaitwe@circodu.org.ug"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <EmailIcon sx={{ fontSize: "22px", color: "red" }} />
                  </IconButton>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default KennethArinaitwe;
