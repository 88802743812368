import React from "react";
import Card2 from "../Images/Headers/our-history.jpg";
import Card1 from "../Images/Logo.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function OurHistory() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Our History</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">About Us</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">Our History</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      {/* Company Section*/}
      <section className="section border-bottom">
        <div className="container mt-2 border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <h3 className="main-heading">CIRCODU Launched</h3>
              <div className="underline-card3 "></div>
              <p className="texty">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                molestie purus velit, at dictum ligula pulvinar sed. Nulla
                facilisi. Fusce blandit dui elit, nec tempus ante tempus a.
                Etiam egestas lorem non leo bibendum euismod. Lorem ipsum dolor
                sit amet, consectetur adipiscing elit. Sed condimentum, sapien
                ac scelerisque ullamcorper, ipsum diam pretium lorem, ut
                sagittis dui ex et augue. Proin interdum commodo feugiat. Nunc
                suscipit, lorem a euismod interdum, lacus erat suscipit metus,
                varius volutpat mi dui at justo. Quisque id orci gravida mi
                mattis congue ut a dui. Pellentesque habitant morbi tristique
                senectus et netus et malesuada fames ac turpis egestas. Orci
                varius natoque penatibus et magnis dis parturient montes,
                nascetur ridiculus mus. Donec at aliquam est, eget ullamcorper
                purus.
              </p>
            </div>
            <div className="col-md-8">
              <p className="texty">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                molestie purus velit, at dictum ligula pulvinar sed. Nulla
                facilisi. Fusce blandit dui elit, nec tempus ante tempus a.
                Etiam egestas lorem non leo bibendum euismod. Lorem ipsum dolor
                sit amet, consectetur adipiscing elit. Sed condimentum, sapien
                ac scelerisque ullamcorper, ipsum diam pretium lorem, ut
                sagittis dui ex et augue. Proin interdum commodo feugiat. Nunc
                suscipit, lorem a euismod interdum, lacus erat suscipit metus,
                varius volutpat mi dui at justo. Quisque id orci gravida mi
                mattis congue ut a dui. Pellentesque habitant morbi tristique
                senectus et netus et malesuada fames ac turpis egestas. Orci
                varius natoque penatibus et magnis dis parturient montes,
                nascetur ridiculus mus. Donec at aliquam est, eget ullamcorper
                purus.
              </p>
              <p className="texty">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                molestie purus velit, at dictum ligula pulvinar sed. Nulla
                facilisi. Fusce blandit dui elit, nec tempus ante tempus a.
                Etiam egestas lorem non leo bibendum euismod. Lorem ipsum dolor
                sit amet, consectetur adipiscing elit. Sed condimentum, sapien
                ac scelerisque ullamcorper, ipsum diam pretium lorem, ut
                sagittis dui ex et augue. Proin interdum commodo feugiat. Nunc
                suscipit, lorem a euismod interdum, lacus erat suscipit metus,
                varius volutpat mi dui at justo. Quisque id orci gravida mi
                mattis congue ut a dui. Pellentesque habitant morbi tristique
                senectus et netus et malesuada fames ac turpis egestas. Orci
                varius natoque penatibus et magnis dis parturient montes,
                nascetur ridiculus mus. Donec at aliquam est, eget ullamcorper
                purus.
              </p>
            </div>
            <div className="col-md-4">
              <div className="imagecard">
                <img
                  src={Card1}
                  alt=""
                  height="215"
                  width="100%"
                  style={{ borderRadius: "5%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default OurHistory;
