import React, { useState } from "react";
import "../Pages/Gallery.css";
import Card1 from "../Images/Gallery/IMG20220926103721.jpg";
import Card2 from "../Images/Gallery/IMG20220926110207.jpg";
import Card3 from "../Images/Gallery/IMG20221004143851.jpg";
import Card4 from "../Images/Gallery/IMG20221018145908.jpg";
import Card5 from "../Images/Gallery/IMG20230106105434.jpg";
import Card6 from "../Images/Gallery/IMG20230106105548.jpg";
import Card7 from "../Images/Gallery/IMG20230215102825.jpg";
import Card8 from "../Images/Gallery/IMG20230313165330.jpg";
import Card9 from "../Images/Gallery/IMG20230313165451.jpg";
import Card10 from "../Images/Gallery/IMG20230509105134.jpg";
import Card11 from "../Images/Gallery/IMG20220805164430.jpg";
import Card12 from "../Images/Gallery/IMG20220801094148.jpg";
import Card13 from "../Images/Gallery/IMG20220630133627.jpg";
import Card14 from "../Images/Gallery/IMG20220720111040.jpg";
import Card15 from "../Images/Gallery/IMG20220801094227.jpg";
import Card16 from "../Images/Gallery/IMG20220730094829.jpg";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Card17 from "../Images/Gallery/IMG20220630104140.jpg";
import Card18 from "../Images/Gallery/IMG20220630104211.jpg";
import Card19 from "../Images/Gallery/IMG20220630104947.jpg";
import Card20 from "../Images/Gallery/IMG20230509112112.jpg";
import Card21 from "../Images/Gallery/IMG20230518122333.jpg";
import Card22 from "../Images/Gallery/IMG20230606125924.jpg";
import Card42 from "../Images/Gallery/IMG20230606125947.jpg";
import Card23 from "../Images/Gallery/IMG20230607093001.jpg";
import Card24 from "../Images/Gallery/IMG20230607093235.jpg";
import Card25 from "../Images/Gallery/IMG20230621103813.jpg";
import Card26 from "../Images/Gallery/IMG20230621103836.jpg";
import Card27 from "../Images/Gallery/IMG20230621114704.jpg";
import Card28 from "../Images/Gallery/IMG20230621130649.jpg";
import Card29 from "../Images/Gallery/IMG20230621131127.jpg";
import Card30 from "../Images/Gallery/IMG20230621131136.jpg";
import Card31 from "../Images/Gallery/IMG20230705090235.jpg";
import Card32 from "../Images/Gallery/IMG20230705090431.jpg";
import Card33 from "../Images/Gallery/IMG20230705095227.jpg";
import Card34 from "../Images/Gallery/IMG20230705114602.jpg";
import Card35 from "../Images/Gallery/IMG20230705120731.jpg";
import Card36 from "../Images/Gallery/IMG20230705141314.jpg";
import Card37 from "../Images/Gallery/IMG20230705154445.jpg";
import Card38 from "../Images/Gallery/IMG20230724160307.jpg";
import Card39 from "../Images/Gallery/IMG20240306120928.jpg";
import Card40 from "../Images/Gallery/IMG20240306105342.jpg";
import Card41 from "../Images/Gallery/IMG20240306120936.jpg";
import Card43 from "../Images/Gallery/IMG20240309101414.jpg";
import Card44 from "../Images/Gallery/IMG20240309101419.jpg";
import Card45 from "../Images/Gallery/IMG20240309112637.jpg";
import Card46 from "../Images/Gallery/IMG20240309112646.jpg";
import Card47 from "../Images/Gallery/IMG20240309112647.jpg";
import Card48 from "../Images/Gallery/IMG20240309115059.jpg";
import Card49 from "../Images/Gallery/IMG20240309132153.jpg";
import Card51 from "../Images/Gallery/IMG20240325153442.jpg";
import Card50 from "../Images/Gallery/IMG20240325153204.jpg";
import Card52 from "../Images/Gallery/IMG20240220113849.jpg";

const Gallery = () => {
  let data = [
    {
      id: 39,
      imgSrc: Card39,
      caption:
        "A Team from Stockholm Environment Institute (SEI) in collaboration with the CIRCODU Team conducting a Focus Group Discussion with different players in the Uganda Cooking Sector, at Ministry of Energy and Mineral Development, March 2024. Discussions would feed into the Uganda Clean Cooking Strategy being developed by SEI.",
    },
    {
      id: 40,
      imgSrc: Card40,
      caption:
        "A Team from Stockholm Environment Institute (SEI) in collaboration with the CIRCODU Team conducting a Focus Group Discussion with different players in the Uganda Cooking Sector, at Ministry of Energy and Mineral Development, March 2024. Discussions would feed into the Uganda Clean Cooking Strategy being developed by SEI.",
    },
    {
      id: 41,
      imgSrc: Card41,
      caption:
        "A Team from Stockholm Environment Institute (SEI) in collaboration with the CIRCODU Team conducting a Focus Group Discussion with different players in the Uganda Cooking Sector, at Ministry of Energy and Mineral Development, March 2024. Discussions would feed into the Uganda Clean Cooking Strategy being developed by SEI.",
    },
    {
      id: 43,
      imgSrc: Card43,
      caption:
        "A Team from Stockholm Environment Institute (SEI) in collaboration with the CIRCODU Team conducting a Focus Group Discussion with endusers of cooking devices, at Kawempe Ttula Town Hall, March 2024. Discussions would feed into the Uganda Clean Cooking Strategy being developed by SEI.",
    },
    {
      id: 44,
      imgSrc: Card44,
      caption:
        "A Team from Stockholm Environment Institute (SEI) in collaboration with the CIRCODU Team conducting a Focus Group Discussion with endusers of cooking devices, at Kawempe Ttula Town Hall, March 2024. Discussions would feed into the Uganda Clean Cooking Strategy being developed by SEI.",
    },
    {
      id: 45,
      imgSrc: Card45,
      caption:
        "Some of the Cooking Technologies that were displayed during a Focus Group Discussion with endusers of cooking devices, at Kawempe Ttula Town Hall, March 2024.",
    },
    {
      id: 46,
      imgSrc: Card46,
      caption:
        "A Team from Stockholm Environment Institute (SEI) in collaboration with the CIRCODU Team conducting a Focus Group Discussion with endusers of cooking devices, at Kawempe Ttula Town Hall, March 2024. Discussions would feed into the Uganda Clean Cooking Strategy being developed by SEI.",
    },
    {
      id: 47,
      imgSrc: Card47,
      caption:
        "A Team from Stockholm Environment Institute (SEI) in collaboration with the CIRCODU Team conducting a Focus Group Discussion with endusers of cooking devices, at Kawempe Ttula Town Hall, March 2024. Discussions would feed into the Uganda Clean Cooking Strategy being developed by SEI.",
    },
    {
      id: 48,
      imgSrc: Card48,
      caption:
        "A Team from Stockholm Environment Institute (SEI) in collaboration with the CIRCODU Team conducting a Focus Group Discussion with endusers of cooking devices, at Kawempe Ttula Town Hall, March 2024. Discussions would feed into the Uganda Clean Cooking Strategy being developed by SEI.",
    },
    {
      id: 49,
      imgSrc: Card49,
      caption:
        "Stockholm Environment Institute (SEI)'s Clement, CIRCODU's Carol, SEI's Denna, CIRCODU's Director Alexander, CIRCODU's Rashid, MEMD's Justine during a Focus Group Discussion at Kawempe Ttula Town Hall, March 2024.",
    },
    {
      id: 50,
      imgSrc: Card50,
      caption:
        "Representatives from CLASP on a visit to our Cookstove Laboratory to discuss possibilities for technical and financial support, March 2024.",
    },
    {
      id: 51,
      imgSrc: Card51,
      caption:
        "Representatives from CLASP on a visit to our Cookstove Laboratory to discuss possibilities for technical and financial support, March 2024.",
    },
    {
      id: 52,
      imgSrc: Card52,
      caption:
        "CIRCODU Agriculture Thematic Team with the improvements effected on the Office Premises replacing most decorative plants with edible crops, Feb 2024.",
    },
    {
      id: 11,
      imgSrc: Card11,
      caption:
        "Staff During the Training on activities that were to be carried out under the Ministry of Energy and Mineral Development/ World Bank consultancy: Conducting Clean Cooking Energy Needs Assessment in Public and Private Institutions in Uganda, August 2022.",
    },
    {
      id: 23,
      imgSrc: Card23,
      caption:
        "CIRCODU Team with Ministry of Energy and Mineral Development/ Modern Energy Cooking Services (MECS) Representatives during a meeting on the progress of the eCooking Baseline Study to Assess the Status Quo for eCooking in Uganda and determine the contribution that eCooking could make towards the National Clean Cooking Strategy, at MEMD, June 2023.",
    },
    {
      id: 24,
      imgSrc: Card24,
      caption:
        "CIRCODU Team with Ministry of Energy and Mineral Development/ Modern Energy Cooking Services (MECS) Representatives during a meeting on the progress of the eCooking Baseline Study to Assess the Status Quo for eCooking in Uganda and determine the contribution that eCooking could make towards the National Clean Cooking Strategy, at MEMD, June 2023.",
    },
    {
      id: 12,
      imgSrc: Card12,
      caption:
        "A Team of verifiers from Biogas Solutions Uganda Limited/ African Biogas Carbon Programme during a spot check Verification Exercise at our Offices in 2022.",
    },
    {
      id: 13,
      imgSrc: Card13,
      caption:
        "CIRCODU Team with Stakeholders after the presentation of the findings in the Baseline and Market Assessment report on Energy Efficiency Interventions in Agriculture and Built Environment in Uganda (IMEU) at Silver Springs, Bugoloobi to SNV Netherland, August 2022.",
    },
    {
      id: 14,
      imgSrc: Card14,
      caption:
        "CIRCODU Team after the training on activities to be carried out on a CO2balance consultancy: Baseline Surveys and Kitchen Performance Tests in Eastern and Western Uganda, July 2022.",
    },
    {
      id: 15,
      imgSrc: Card15,
      caption:
        "A Team of verifiers from Biogas Solutions Uganda Limited/ African Biogas Carbon Programme during a spot check Verification Exercise at our Offices in 2022.",
    },
    {
      id: 16,
      imgSrc: Card16,
      caption:
        "During the training of Reseach Assistants for the Baseline Study for the Dutch Spark for Clean Cooking Solutions on Pre-Project Fuel Consumption Patterns In Different Regions of Uganda, October 2022.",
    },
    {
      id: 1,
      imgSrc: Card1,
      caption:
        "Staff getting a refresher training on how to use a Fire Extinguisher for their safety incase of an emergency at the office premises, January 2023.",
    },
    {
      id: 2,
      imgSrc: Card2,
      caption:
        "Staff getting a refresher training on how to use a Fire Extinguisher for their safety incase of an emergency at the office premises, January 2023.",
    },
    {
      id: 8,
      imgSrc: Card8,
      caption:
        "Laboratory Team during a controlled water boiling test, March 2023.",
    },
    {
      id: 9,
      imgSrc: Card9,
      caption:
        "Laboratory Team during a controlled water boiling test, March 2023.",
    },
    {
      id: 3,
      imgSrc: Card3,
      caption:
        "Laboratory Team warming up cookstoves during the cookstove testing process, February 2023",
    },
    {
      id: 4,
      imgSrc: Card4,
      caption:
        "Cookstove Laboratory Team during a verification and renewal process with the UNBS Team at the Office Premises, October, 2022.",
    },
    {
      id: 5,
      imgSrc: Card5,
      caption:
        "Laboratory Team sorting charcoal to be used in the cookstove testing process, February 2023",
    },
    {
      id: 6,
      imgSrc: Card6,
      caption:
        "Laboratory Team sorting charcoal to be used in the cookstove testing process, February 2023",
    },
    {
      id: 7,
      imgSrc: Card7,
      caption:
        "During the training of Reseach Assistants for the Baseline Study for the Dutch Spark for Clean Cooking Solutions on Pre-Project Fuel Consumption Patterns In Different Regions of Uganda, October 2022.",
    },
    {
      id: 10,
      imgSrc: Card10,
      caption:
        "Training of Research Assistants for the eCooking Baseline Study to Assess the Status Quo for eCooking in Uganda and determine the contribution that eCooking could make towards the National Clean Cooking Strategy, May 2023.",
    },
    {
      id: 17,
      imgSrc: Card17,
      caption:
        "Prof. Samuel Baker Kucel during the Stakeholders presentation of the findings in the Baseline and Market Assessment report on Energy Efficiency Interventions in Agriculture and Built Environment in Uganda (IMEU) at Silver Springs, Bugoloobi to SNV Netherland, August 2022.",
    },
    {
      id: 18,
      imgSrc: Card18,
      caption:
        "Stakeholders during the presentation of the findings in the Baseline and Market Assessment report on Energy Efficiency Interventions in Agriculture and Built Environment in Uganda (IMEU) at Silver Springs, Bugoloobi to SNV Netherland, August 2022.",
    },
    {
      id: 19,
      imgSrc: Card19,
      caption:
        "CIRCODU Director General, Mr. Joseph Ndemere Arineitwe during the Stakeholders presentation of the findings in the Baseline and Market Assessment report on Energy Efficiency Interventions in Agriculture and Built Environment in Uganda (IMEU) at Silver Springs, Bugoloobi to SNV Netherland, August 2022.",
    },
    {
      id: 20,
      imgSrc: Card20,
      caption:
        "Training of Research Assistants for the eCooking Baseline Study to Assess the Status Quo for eCooking in Uganda and determine the contribution that eCooking could make towards the National Clean Cooking Strategy, May 2023.",
    },
    {
      id: 21,
      imgSrc: Card21,
      caption:
        "Data Cleaning Exercise at the Office Premises with Teams that partipated in the eCooking Baseline Study to Assess the Status Quo for eCooking in Uganda and determine the contribution that eCooking could make towards the National Clean Cooking Strategy, May 2023.",
    },
    {
      id: 22,
      imgSrc: Card22,
      caption:
        "During a review of the report findings with the Ministry of Energy and Mineral Development/ World Bank Team on the consultancy: Conducting Clean Cooking Energy Needs Assessment in Public and Private Institutions in Uganda, June 2023.",
    },
    {
      id: 42,
      imgSrc: Card42,
      caption:
        "During a review of the report findings with the Ministry of Energy and Mineral Development/ World Bank Team on the consultancy: Conducting Clean Cooking Energy Needs Assessment in Public and Private Institutions in Uganda, June 2023.",
    },
    {
      id: 25,
      imgSrc: Card25,
      caption:
        "UpEnergy Verification Team with the Cookstove Laboratory Team undergoing a routine check on the way tests are carried out, June 2023.",
    },
    {
      id: 26,
      imgSrc: Card26,
      caption:
        "UpEnergy Verification Team with the Cookstove Laboratory Team undergoing a routine check on the way tests are carried out, June 2023.",
    },
    {
      id: 27,
      imgSrc: Card27,
      caption:
        "UpEnergy Verification Team with the Cookstove Laboratory Team undergoing a routine check on the way tests are carried out, June 2023.",
    },
    {
      id: 28,
      imgSrc: Card28,
      caption:
        "UpEnergy Verification Team with the Cookstove Laboratory Team undergoing a routine check on the way tests are carried out, June 2023.",
    },
    {
      id: 29,
      imgSrc: Card29,
      caption:
        "UpEnergy Verification Team with the Cookstove Laboratory Team undergoing a routine check on the way tests are carried out, June 2023.",
    },
    {
      id: 30,
      imgSrc: Card30,
      caption:
        "UpEnergy Verification Team with the Cookstove Laboratory Team undergoing a routine check on the way tests are carried out, June 2023.",
    },
    {
      id: 31,
      imgSrc: Card31,
      caption:
        "CIRCODU Team with Ministry of Energy and Mineral Development/ Modern Energy Cooking Services (MECS) Team and stakeholders during a meeting on the progress of the National eCooking Strategy, at Esella Country Hotel, July 2023.",
    },
    {
      id: 32,
      imgSrc: Card32,
      caption:
        "CIRCODU Team with Ministry of Energy and Mineral Development/ Modern Energy Cooking Services (MECS) Team and stakeholders during a meeting on the progress of the National eCooking Strategy, at Esella Country Hotel, July 2023.",
    },
    {
      id: 33,
      imgSrc: Card33,
      caption:
        "CIRCODU Team with Ministry of Energy and Mineral Development/ Modern Energy Cooking Services (MECS) Team and stakeholders during a meeting on the progress of the National eCooking Strategy, at Esella Country Hotel, July 2023.",
    },
    {
      id: 34,
      imgSrc: Card34,
      caption:
        "CIRCODU Team with Ministry of Energy and Mineral Development/ Modern Energy Cooking Services (MECS) Team and stakeholders during a meeting on the progress of the National eCooking Strategy, at Esella Country Hotel, July 2023.",
    },
    {
      id: 35,
      imgSrc: Card35,
      caption:
        "CIRCODU Team with Ministry of Energy and Mineral Development/ Modern Energy Cooking Services (MECS) Team and stakeholders during a meeting on the progress of the National eCooking Strategy, at Esella Country Hotel, July 2023.",
    },
    {
      id: 36,
      imgSrc: Card36,
      caption:
        "CIRCODU Team with Ministry of Energy and Mineral Development/ Modern Energy Cooking Services (MECS) Team and stakeholders during a meeting on the progress of the National eCooking Strategy, at Esella Country Hotel, July 2023.",
    },
    {
      id: 37,
      imgSrc: Card37,
      caption:
        "CIRCODU Team with Ministry of Energy and Mineral Development/ Modern Energy Cooking Services (MECS) Team and stakeholders during a meeting on the progress of the National eCooking Strategy, at Esella Country Hotel, July 2023.",
    },
    {
      id: 38,
      imgSrc: Card38,
      caption:
        "UpEnergy Verification Team with the Cookstove Laboratory Team undergoing a routine check on the way tests are carried out, June 2023.",
    },
  ];

  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  const getImg = (imgSrc, index) => {
    setTempImgSrc(imgSrc);
    setCurrentIndex(index);
    setModel(true);
  };

  const goToNext = () => {
    if (currentIndex + 1 < data.length) {
      setCurrentIndex(currentIndex + 1);
      setTempImgSrc(data[currentIndex + 1].imgSrc);
    }
  };

  const goToPrev = () => {
    if (currentIndex - 1 >= 0) {
      setCurrentIndex(currentIndex - 1);
      setTempImgSrc(data[currentIndex - 1].imgSrc);
    }
  };

  return (
    <>
      <div className={model ? "model open" : "model"}>
        <img src={tempimgSrc} alt="" />
        <div className="caption">{data[currentIndex].caption}</div>
        <div className="closeicon">
          <CloseIcon onClick={() => setModel(false)} />
        </div>
        <div className="previcon">
          <ArrowBackIosIcon onClick={goToPrev} />
        </div>
        <div className="nexticon">
          <ArrowForwardIosIcon onClick={goToNext} />
        </div>
      </div>
      <div className="gallery">
        {data.map((item, index) => {
          return (
            <div
              className="pics"
              key={index}
              onClick={() => getImg(item.imgSrc, index)}
            >
              <img src={item.imgSrc} style={{ width: "100%" }} alt="" />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Gallery;
