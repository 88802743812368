import React from "react";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
//import TwitterIcon from "@mui/icons-material/Twitter";
import XIcon from '@mui/icons-material/X';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import DirectionsIcon from "@mui/icons-material/Directions";

function Footer() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="footer">
      <section className="section footer bg-dark text-white">
        <div className="container">
          <div className="row">
            <div className="fotr col-md-4 py-1 text-justify">
              <h6 className="mt-1">Useful Links</h6>
              <hr />
              <div className="footera">
                <a
                  href="https://cleancooking.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Clean Cooking Alliance
                </a>
              </div>
              <div className="footera">
                <a
                  href="https://ursb.go.ug/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Uganda Registration Services Bureau
                </a>
              </div>
              <div className="footera">
                <a
                  href="https://unbs.go.ug/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Uganda National Bureau of Standards
                </a>
              </div>
              <div className="footera">
                <a
                  href="https://www.psfuganda.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Private Sector Foundation Uganda (PSFU)
                </a>
              </div>
              <div className="footera">
                <a
                  href="https://memd.go.ug/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ministry of Energy and Mineral Development (MEMD)
                </a>
              </div>
            </div>
            <div className="fotr col-md-3 py-1">
              <h6 className="mt-1">Quick Links</h6>
              <hr />
              <div className="footera">
                <Link to="/" onClick={scrollToTop}>
                  Home
                </Link>
              </div>
              <div className="footera">
                <Link to="/about-us/what-we-do" onClick={scrollToTop}>
                  What We Do
                </Link>
              </div>
              <div className="footera">
                <Link to="/the-team/the-staff-team" onClick={scrollToTop}>
                  The Staff Team
                </Link>
              </div>
              <div className="footera">
                <Link to="/publicity/news-and-events" onClick={scrollToTop}>
                  News & Events
                </Link>
              </div>
              <div className="footera">
                <Link
                  to="/our-services/cookstoves-and-fuel-testing"
                  onClick={scrollToTop}
                >
                  Cookstoves and Fuel Testing
                </Link>
              </div>
            </div>
            <div className="fotr col-md-2 py-1">
              <h6 className="mt-1">Social Media</h6>
              <hr />
              <div className="footera">
                <a
                  href="https://twitter.com/CIRCODU1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <XIcon /> {""} Twitter
                </a>
              </div>
              <div className="footera">
                <a
                  href="https://ug.linkedin.com/company/centre-for-integrated-research-and-community-development-uganda-circodu"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedInIcon /> {""} LinkedIn
                </a>
              </div>
              <div className="footera">
                <a
                  href="https://youtube.com/@CIRCODU"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <YouTubeIcon /> {""} Youtube
                </a>
              </div>
              <div className="footera">
                <a
                  href="https://www.facebook.com/Circodu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookOutlinedIcon /> {""} Facebook
                </a>
              </div>
              <div className="footera">
                <a
                  href="https://wa.me/256782433627"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <WhatsAppIcon /> {""} WhatsApp
                </a>
              </div>
            </div>
            <div className="fotr col-md-3 py-1">
              <h6 className="mt-1">Contact Us</h6>
              <hr />
              <div className="footera">
                <a href="Tel:+256392004309">
                  <CallIcon /> {""} +256 392 004 309
                </a>
              </div>
              <div className="footera">
                <a href="Tel:+256782433627">
                  <AddIcCallIcon /> {""} +256 782 433 627
                </a>
              </div>
              <div className="footera">
                <a
                  href="mailto:info@circodu.org.ug"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <EmailIcon /> {""} info@circodu.org.ug
                </a>
              </div>
              <div className="footera">
                <a
                  href="https://goo.gl/maps/ZmZM5rhrpHyFPhSu5"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <DirectionsIcon /> {""} Directions: Google Maps
                </a>
              </div>
              <div className="footera">
                <HomeIcon /> {""} P.O. Box 16340, Wandegeya, Kampala, U
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-footer">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-4">
              <a className="textfoot" href="/" onClick={scrollToTop}>
                Centre for Integrated Research & Community Developm't Ug
              </a>
            </div>
            <div className="col-md-4">
              <small className="textfoot">
                <b>All Rights Reserved. CIRCODU © 2007 – 2024</b>
              </small>
            </div>
            <div className="col-md-4">
              <a
                className="textfoot"
                href="https://wa.me/256785430302"
                target="_blank"
                rel="noopener noreferrer"
              >
                Designed By: e-Solutions Inc
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
