import React, { useState, useEffect } from "react";
import Card2 from "../Images/Headers/publicity_2.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { FacebookEmbed } from "react-social-media-embed";
import { TwitterEmbed } from "react-social-media-embed";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { Link } from "react-router-dom";
import { CardActions } from "@mui/material";

function NewsandEvents() {
  const [url, setUrl] = useState(
    "https://twitter.com/CIRCODU1/status/1820395288663646547"
  );

  useEffect(() => {
    setInterval(() => {
      // Update the url prop here
      setUrl("https://twitter.com/CIRCODU1/status/1820395288663646547");
    }, 15000); // Update every 15 seconds
  }, []);

  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">News & Events</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">Publicity</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">News & Events</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row mt-2">
            <div className="col-md-8 iconb">
              <div className="row">
                <div className="col-md-6 pt-2">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Card style={{ width: "28rem" }}>
                      <Card.Body>
                        <Card.Title className="boldx">
                          <a
                            href="/CIRCODU-laboratory-a-leader-in-testing-cookstoves"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="boldx"
                          >
                            CIRCODU Laboratory a Leader in Testing Cookstoves
                            for Efficiency, Safety, Durability, and Emissions
                          </a>
                        </Card.Title>
                      </Card.Body>
                      <ListGroup className="list-group-flush"></ListGroup>
                      <CardActions className="butts">
                        <Link
                          to="/CIRCODU-laboratory-a-leader-in-testing-cookstoves"
                          className="btn btn-outline-success"
                          size="medium"
                          color="primary"
                        >
                          Read More
                        </Link>
                      </CardActions>
                      <ListGroup className="list-group-flush"></ListGroup>
                      <ListGroup className="list-group-flush"></ListGroup>
                      <div style={{ padding: "10px", textAlign: "center" }}>
                        <small className="posts">Posted on: May 31, 2024</small>
                      </div>
                    </Card>
                  </div>
                </div>
                <div className="col-md-6 pt-2">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Card style={{ width: "28rem" }}>
                      <Card.Body>
                        <Card.Title className="boldx">
                          <a
                            href="/Training-Services-on-Briquette-and-Cookstove-Production"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="boldx"
                          >
                            Training Services on Briquette and Cookstove Production to PELUM Uganda affiliated smallholder farmers.
                          </a>
                        </Card.Title>
                      </Card.Body>
                      <ListGroup className="list-group-flush"></ListGroup>
                      <CardActions className="butts">
                        <Link
                          to="/Training-Services-on-Briquette-and-Cookstove-Production"
                          className="btn btn-outline-success"
                          size="medium"
                          color="primary"
                        >
                          Read More
                        </Link>
                      </CardActions>
                      <ListGroup className="list-group-flush"></ListGroup>

                      <div style={{ padding: "10px", textAlign: "center" }}>
                        <small className="posts">Posted on: July 31, 2024</small>
                      </div>
                    </Card>
                  </div>
                </div>
                <div className="col-md-6 pt-2">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Card style={{ width: "28rem" }}>
                      <Card.Body>
                        <Card.Title className="boldx">
                          <a
                            href="/Testing-of-BioLite-Cookstoves"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="boldx"
                          >
                            Testing of BioLite Cookstoves at the CIRCODU Cookstove
                            Laboratory
                          </a>
                        </Card.Title>
                      </Card.Body>
                      <ListGroup className="list-group-flush"></ListGroup>
                      <CardActions className="butts">
                        <Link
                          to="/Testing-of-BioLite-Cookstoves"
                          className="btn btn-outline-success"
                          size="medium"
                          color="primary"
                        >
                          Read More
                        </Link>
                      </CardActions>
                      <ListGroup className="list-group-flush"></ListGroup>
                      <div style={{ padding: "10px", textAlign: "center" }}>
                        <small className="posts">Posted on: July 31, 2024</small>
                      </div>
                    </Card>
                  </div>
                </div>
                <div className="col-md-6 pt-2">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Card style={{ width: "28rem" }}>
                      <Card.Body>
                        <Card.Title className="boldx">
                          <a
                            href="/# "
                            target="_blank"
                            rel="noopener noreferrer"
                            className="boldx"
                          >
                            Coming Soon
                          </a>
                        </Card.Title>
                      </Card.Body>
                      <ListGroup className="list-group-flush"></ListGroup>
                      <CardActions className="butts">
                        <Link
                          to=""
                          className="btn btn-outline-success"
                          size="medium"
                          color="primary"
                        >
                          Read More
                        </Link>
                      </CardActions>
                      <ListGroup className="list-group-flush"></ListGroup>
                      <div style={{ padding: "10px", textAlign: "center" }}>
                        <small className="posts">Posted on: May 31, 2024</small>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 iconb">
              <div className="row text-center">
                <div className="col-md-12 pt-2">
                  <h3 className="bold2">Social Media Feeds</h3>
                </div>
                <div className="col-md-12 pt-2">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <FacebookEmbed
                      url="https://www.facebook.com/100057727537068/posts/pfbid021uBEQcrSD16LMuA4j7eWaDpiL4k3qyFm7N6NGJknAfLsXSjo8ANyMir28sM296g8l/?app=fbl"
                      width={325}
                    />
                  </div>
                </div>
                <div className="col-md-12 pt-2">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <TwitterEmbed
                      embedPlaceholder={
                        <div
                          style={{
                            backgroundColor: "lightsteelblue",
                            padding: "150px 0",
                            textAlign: "center",
                            width: 325,
                            height: 430,
                          }}
                        />
                      }
                      url={url}
                      width={325}
                      height={430}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default NewsandEvents;
