import React from "react";
import Card2 from "../Images/Headers/clients.jpg";
import Card1 from "../Images/Partners/berkeley.jpg";
import Card3 from "../Images/Partners/berkeley-air-logo-2.jpg";
import Card4 from "../Images/Partners/KPMG_logo.jpg";
import Card5 from "../Images/Partners/globalalliance.jpg";
import Card6 from "../Images/Partners/Technology-Consults-Limited-Logo.jpg";
import Card7 from "../Images/Partners/stanford.jpg";
import Card8 from "../Images/Partners/Serc.jpg";
import Card9 from "../Images/Partners/rebel.jpg";
import Card10 from "../Images/Partners/giz.jpg";
import Card11 from "../Images/Partners/worldbank.jpg";
import Card12 from "../Images/Partners/wwf.jpg";
import Card13 from "../Images/Partners/Envirofit International Ltd.jpg";
import Card14 from "../Images/Partners/psfu.jpg";
import Card15 from "../Images/Partners/Bank-of-Uganda.jpg";
import Card16 from "../Images/Partners/bsul.jpg";
import Card17 from "../Images/Partners/carbonclear.jpg";
import Card18 from "../Images/Partners/CCA logo.jpg";
import Card19 from "../Images/Partners/climate secure.jpg";
import Card20 from "../Images/Partners/Co2.jpg";
import Card21 from "../Images/Partners/dutchspark.jpg";
import Card22 from "../Images/Partners/ecotrust.jpg";
import Card23 from "../Images/Partners/Enabel.jpg";
import Card24 from "../Images/Partners/finnfund.jpg";
import Card25 from "../Images/Partners/ground-truth-solutions.jpg";
import Card26 from "../Images/Partners/GVEP.jpg";
import Card27 from "../Images/Partners/habitatforhumanity.jpg";
import Card28 from "../Images/Partners/HIVOS.jpg";
import Card29 from "../Images/Partners/idin.jpg";
import Card30 from "../Images/Partners/UKaid.jpg";
import Card31 from "../Images/Partners/impact-carbon.jpg";
import Card32 from "../Images/Partners/ImpactWater.jpg";
import Card33 from "../Images/Partners/kima foods.jpg";
import Card34 from "../Images/Partners/living earth uganda.jpg";
import Card35 from "../Images/Partners/loughborough-university-logo.jpg";
import Card36 from "../Images/Partners/masupa_logo.jpg";
import Card37 from "../Images/Partners/MECS.jpg";
import Card38 from "../Images/Partners/memdlogofnl.jpg";
import Card39 from "../Images/Partners/Myclimate.jpg";
import Card40 from "../Images/Partners/ren21_logo.jpg";
import Card41 from "../Images/Partners/snv.jpg";
import Card42 from "../Images/Partners/spgs.jpg";
import Card43 from "../Images/Partners/uganda-carbon-bureau.jpg";
import Card44 from "../Images/Partners/undp.jpg";
import Card45 from "../Images/Partners/unacc.jpg";
import Card46 from "../Images/Partners/usaid.jpg";
import Card47 from "../Images/Partners/UNREEEA.jpg";
import Card48 from "../Images/Partners/upe.jpg";
import Card49 from "../Images/Partners/SEI.jpg";
import Card50 from "../Images/Partners/PELUM.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../Pages/ClientsPartners.css";

function ClientsPartners() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Clients and Partners</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">About Us</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Clients and Partners
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container mt-2 border-top">
          <h style={{ color: "#008250", fontSize: "18px" }}>
            <b>Clients and Partners</b>
          </h>
          <div className="row text-center">
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://www.berkeley.edu/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card1}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://berkeleyair.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card3}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://kpmg.com/xx/en/home/about/offices/kampala-1.html"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card4}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://cleancooking.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card5}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="http://www.teco.co.ug/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card6}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://www.stanford.edu/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card7}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://schatzcenter.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card8}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://rebelgroup.com/en/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card9}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://www.giz.de/en/worldwide/310.html"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card10}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://www.worldbank.org/en/home"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card11}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://www.worldwildlife.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card12}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://envirofit.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card13}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="container mt-2 border-top">
          <h style={{ color: "#008250", fontSize: "18px" }}></h>
          <div className="row text-center">
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://www.psfuganda.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card14}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://www.bou.or.ug/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card15}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://www.biogassolutions.co.ug/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card16}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://www.carbonclear.earth/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card17}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://cleancooking.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card18}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://www.climate-secure.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card19}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://www.co2balance.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card20}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://dutch-spark.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card21}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://ecotrust.or.ug/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card22}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://www.enabel.be/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card23}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://www.finnfund.fi/en/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card24}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://www.groundtruthsolutions.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card25}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="container mt-2 border-top">
          <h style={{ color: "#008250", fontSize: "18px" }}></h>
          <div className="row text-center">
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://energy4impact.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card26}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://habitat.ug/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card27}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://hivos.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card28}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://www.idin.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card29}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://www.ukaiddirect.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card30}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://impactcarbon.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card31}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="http://www.impactwater.co/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card32}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://kiimafoods.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card33}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://livingearthuganda.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card34}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://www.lboro.ac.uk/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card35}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://seed.uno/enterprise-profiles/masupa-enterprises"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card36}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://mecs.org.uk/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card37}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="container mt-2 border-top">
          <h style={{ color: "#008250", fontSize: "18px" }}></h>
          <div className="row text-center">
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://memd.go.ug/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card38}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://www.myclimate.org/en/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card39}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://www.ren21.net/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card40}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://www.snv.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card41}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://spgs.mwe.go.ug/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card42}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="http://www.ugandacarbon.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card43}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://www.unaccug.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card45}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://www.undp.org/uganda"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card44}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://unreeea.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card47}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://www.upenergygroup.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card48}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://www.usaid.gov/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card46}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 py-2 imagecarded">
              <a
                href="https://www.sei.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold2"
              >
                <img
                  src={Card49}
                  alt=""
                  height="auto"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                    event.target.style.transform = "scale(1.02)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </a>
            </div>
          </div>
          <div className="container mt-2 border-top">
            <h style={{ color: "#008250", fontSize: "18px" }}></h>
            <div className="row text-center">
              <div className="col-md-3 py-2 imagecarded">
                <a
                  href="https://pelumuganda.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bold2"
                >
                  <img
                    src={Card50}
                    alt=""
                    height="auto"
                    width="100%"
                    maxWidth="100%"
                    style={{
                      borderRadius: "5%",
                      boxShadow: "none",
                      transition: "box-shadow 0.3s ease-in-out",
                      transform: "scale(1) 0.5s ease-in-out",
                    }}
                    onMouseEnter={(event) => {
                      event.target.style.boxShadow =
                        "2px 2px 2px 2px rgba(138, 255, 138, 1)";
                      event.target.style.transform = "scale(1.02)";
                    }}
                    onMouseLeave={(event) => {
                      event.target.style.boxShadow = "none";
                      event.target.style.transform = "scale(1)";
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ClientsPartners;
