import React from 'react'
import Card1 from "../Images/Mission.png";
import Card2 from "../Images/Vision.png";
import Card3 from "../Images/mandate.png";
import "../Components/Shadowcard.css"

const ShadowCard = ({ image, text }) => {
    const [shadow1, setShadow1] = React.useState(false);
    const [shadow2, setShadow2] = React.useState(false);
    const [shadow3, setShadow3] = React.useState(false);

    return (
        <div className="container py-4">
            <div className="row">
                <div className="col-md-4 borders">
                    <div
                        onMouseEnter={() => setShadow1(true)}
                        onMouseLeave={() => setShadow1(false)}
                        style={{
                            boxShadow: shadow1 ? '0px 5px 10px 5px rgba(0, 255, 157, 0.4)' : 'none',
                            transition: 'box-shadow 0.3s ease-in-out',
                        }}
                    >
                        <div className="row">
                            <div className="col-md-3 cardcont">
                                <img src={Card1} alt="Card" height={50} />
                            </div>
                            <div className="col-md-6 cardcontext">
                                <h className="mainheading" style={{ color: "#008250" }}>Our Mission</h>
                            </div>
                        </div>
                        <p className='shadowpara'>
                            To Promote Health Awareness,
                            Sustainable Energy Resource Use and
                            Environment Conservation.
                        </p>
                    </div>
                </div>
                <div className="col-md-4 borders1">
                    <div
                        onMouseEnter={() => setShadow2(true)}
                        onMouseLeave={() => setShadow2(false)}
                        style={{
                            boxShadow: shadow2 ? '0px 5px 10px 5px rgba(0, 0, 0, 0.2)' : 'none',
                            transition: 'box-shadow 0.3s ease-in-out',
                        }}
                    >
                        <div className="row">
                            <div className="col-md-3 cardcont">
                                <img src={Card2} alt="Card" height={50} />
                            </div>
                            <div className="col-md-6 cardcontext">
                                <h className="mainheading" style={{ color: "#008250" }}>Our Vision</h>
                            </div>
                        </div>
                        <p className='shadowpara'>
                            To Attain a Healthy Environment and
                            Strong Research Capacity for Sustainable Community Development.
                        </p>
                    </div>
                </div>
                <div className="col-md-4 borders">
                    <div
                        onMouseEnter={() => setShadow3(true)}
                        onMouseLeave={() => setShadow3(false)}
                        style={{
                            boxShadow: shadow3 ? '0px 5px 10px 5px rgba(0, 255, 157, 0.4)' : 'none',
                            transition: 'box-shadow 0.3s ease-in-out',
                        }}
                    >
                        <div className="row">
                            <div className="col-md-3 cardcont">
                                <img src={Card3} alt="Card" height={50} />
                            </div>
                            <div className="col-md-6 cardcontext">
                                <h className="mainheading" style={{ color: "#008250" }}>Our Core Values</h>
                            </div>
                        </div>
                        <p className='shadowpara'>
                            <h style={{ color: 'black' }}>Integrity</h>, <h style={{ color: 'red' }}>Professionalism</h>, <h style={{ color: 'purple' }}>Consistency</h>, <h style={{ color: 'blue' }}>Accountability</h>, <h style={{ color: 'black' }}>Honesty</h>, and <h style={{ color: 'teal' }}>Transparency</h>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShadowCard