import React from "react";
import Card2 from "../Images/Headers/fuel-testing.jpg";
import Card4 from "../Images/Asset 2-100.jpg";
import Card3 from "../Images/iso-17025-logo.png";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function CookstoveTesting() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Cookstoves and Fuel Testing</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">Our Services</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Cookstoves and Fuel Testing
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Cookstoves and Fuel Testing</b>
              </h>
              <p className="texty">
                Centre for Integrated Research and Community Development Uganda
                (CIRCODU) Cookstoves and Fuel Testing Laboratory was established
                in 2010, and has since become a Regional Knowledge and Testing
                Center recognized and supported by the Clean Cooking Alliance.
              </p>
              <p className="texty">
                The Laboratory is recognized by the Uganda National Bureau of
                Standards (UNBS) with Certificate No. <b>UNBS/LRS/0023</b> and
                operates under the guidelines of <b>ISO/IEC 17025:2017</b>.
              </p>
            </div>
            <div className="col-md-4 mt-2">
              <div className="imagecard">
                <img
                  src={Card4}
                  alt=""
                  height="250" 
                  width="100%"
                  style={{ borderRadius: "5%" }}
                />
              </div>
            </div>
            <div className="col-md-6 mt-2">
              <p className="texty">
                The Laboratory is also fully equipped with the following
                resources:
                <ul className="mt-2">
                  <li>Advanced emission testing equipment</li>
                  <li>Fuel quality analysis equipment</li>
                  <li>Equipment for thermo-efficiency, durability & safety</li>
                </ul>
              </p>
              <p className="texty">
                These are used to carryout testing and analysis of cookstoves,
                with an aim of determining their performance in relation to
                thermo-efficiency, emissions, durability & safety.
              </p>
              <p className="texty">
                The Cookstoves and Fuel Testing Laboratory is staffed by
                experienced personnel including:
                <ol className="mt-2">
                  <li>Laboratory Director</li>
                  <li>Quality Manager</li>
                  <li>Technical Manager</li>
                  <li>Senior Laboratory Technician</li>
                  <li>Laboratory Technician</li>
                </ol>
              </p>
            </div>
            <div className="col-md-2">
              <div className="imagecard">
                <img
                  src={Card3}
                  alt=""
                  height="auto" 
                  width="100%"
                  style={{ borderRadius: "5%" }}
                />
              </div>
            </div>
            <div className="col-md-12 mt-2">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Cookstoves and Fuel Testing Laboaratory Activities</b>
              </h>
              <p className="texty">
                <ul className="mt-2">
                  <li>
                    <b>Durability Tests:</b> Aims at evaluating stove life span.
                  </li>
                  <li>
                    <b>Fuels Tests:</b> Aims to determine the characteristics of
                    a given fuel.
                  </li>
                  <li>
                    <b>Water Boiling Tests:</b> Aims at determing the efficiency
                    of the stove.
                  </li>
                  <li>
                    <b>Safety Tests:</b> Aims at assessing the dangers
                    associated with using the stove.
                  </li>
                  <li>
                    <b>Controlled Cooking Tests:</b> Aims at measuring the
                    amount of fuel used to prepare a given meal.
                  </li>
                  <li>
                    <b>Emissions Testing:</b> Aims at measuring the amount of
                    carbondioxide, carbonmonoxide and particulate matter emitted
                    during stove use.
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CookstoveTesting;
