import React from "react";
import Card2 from "../Images/Headers/team.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Card from "react-bootstrap/Card";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
//import TwitterIcon from "@mui/icons-material/Twitter";
import XIcon from '@mui/icons-material/X';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import ListGroup from "react-bootstrap/ListGroup";
import Card1 from "../Images/Profiles/Isaac Ahimbisiibwe_Profile Pic.jpg";
import Card3 from "../Images/Profiles/Allan.jpg";
import Card4 from "../Images/Profiles/Aran.jpg";
import Card5 from "../Images/Profiles/Davis.jpg";
import Card6 from "../Images/Profiles/Eunice.jpg";
import Card7 from "../Images/Profiles/Hasifa.jpg";
import Card8 from "../Images/Profiles/Jonah.jpg";
import Card9 from "../Images/Profiles/Livingstone.jpg";
import Card10 from "../Images/Profiles/Patricia.jpg";
import Card11 from "../Images/Profiles/Patrick (2).jpg";
import Card12 from "../Images/Profiles/Carol.jpg";
import Card13 from "../Images/Profiles/Rashid.jpg";
import Card14 from "../Images/Profiles/Bernard.jpg";

function OurStaff() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">The Staff Team</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">The Team</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                The Staff Team
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="py-4 border-bottom">
        <div className="container-fluid ">
          <div className="row border-top text-center">
            <div className="container py-2">
              <div className="row text-center">
                <div className="col-md-3 mt-2">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Card style={{ width: "18rem" }}>
                      <a
                        href="/isaac-ahimbisiibwe"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bold2"
                      >
                        <Card.Img
                          variant="top"
                          src={Card1}
                          alt="rounded image with shadow on hover"
                          style={{
                            height: "300px",
                            borderRadius: "50%",
                            boxShadow: "none",
                            transition: "box-shadow 0.3s ease-in-out",
                            transform: "scale(1) 0.5s ease-in-out",
                            width: "95%",
                            position: "relative",
                            overflow: "hidden",
                            marginTop: "8px",
                          }}
                          onMouseEnter={(event) => {
                            event.target.style.boxShadow =
                              "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                            event.target.style.transform = "scale(1.02)";
                          }}
                          onMouseLeave={(event) => {
                            event.target.style.boxShadow = "none";
                            event.target.style.transform = "scale(1)";
                          }}
                        />
                      </a>
                      <Card.Body>
                        <ListGroup className="list-group-flush">
                          <ListGroup.Item>
                            <a
                              href="/isaac-ahimbisiibwe"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bold2"
                            >
                              Mr. Isaac Ahimbisiibwe
                            </a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <h6 className="bold4">Research Associate</h6>
                          </ListGroup.Item>
                          <ListGroup.Item></ListGroup.Item>
                        </ListGroup>
                        <Card.Text className="bold3">
                          <h>
                            Isaac's academic achievements and professional
                            journey are worthy noting. He earned a Master's
                            Degree in Gender Studies from Makerere University
                            ...{" "}
                            <a
                              href="/isaac-ahimbisiibwe"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bold6"
                            >
                              <b>(Read More)</b>
                            </a>
                          </h>
                        </Card.Text>
                      </Card.Body>
                      <div className="container textcenter1">
                        <div className="col-md-2">
                          <a
                            href="https://www.linkedin.com/in/isaac-ahimbisiibwe-883919162/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <LinkedInIcon
                                sx={{ fontSize: "22px", color: "darkblue" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2 ">
                          <a
                            href="https://twitter.com/IsaacAhimbisii1"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <XIcon
                                  sx={{ fontSize: "22px", color: "black" }}
                                />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="https://m.facebook.com/people/Isaac-Ahimbisiibwe/100010181948317/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <FacebookOutlinedIcon
                                sx={{ fontSize: "22px", color: "blue" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="https://wa.me/256752814376"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <WhatsAppIcon
                                sx={{ fontSize: "22px", color: "teal" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="mailto:isaac.ahimbisiibwe@circodu.org.ug"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <EmailIcon
                                sx={{ fontSize: "22px", color: "red" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
                <div className="col-md-3 mt-2">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Card style={{ width: "18rem" }}>
                      <a
                        href="/allan-joseph-gakiza"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bold2"
                      >
                        <Card.Img
                          variant="top"
                          src={Card3}
                          alt="rounded image with shadow on hover"
                          style={{
                            height: "300px",
                            borderRadius: "50%",
                            boxShadow: "none",
                            transition: "box-shadow 0.3s ease-in-out",
                            transform: "scale(1) 0.5s ease-in-out",
                            width: "95%",
                            position: "relative",
                            overflow: "hidden",
                            marginTop: "8px",
                          }}
                          onMouseEnter={(event) => {
                            event.target.style.boxShadow =
                              "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                            event.target.style.transform = "scale(1.02)";
                          }}
                          onMouseLeave={(event) => {
                            event.target.style.boxShadow = "none";
                            event.target.style.transform = "scale(1)";
                          }}
                        />
                      </a>
                      <Card.Body>
                        <ListGroup className="list-group-flush">
                          <ListGroup.Item>
                            <a
                              href="/allan-joseph-gakiza"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bold2"
                            >
                              Mr. Allan Joseph Gakiza
                            </a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <h6 className="bold4">Accountant</h6>
                          </ListGroup.Item>
                          <ListGroup.Item></ListGroup.Item>
                        </ListGroup>
                        <Card.Text className="bold3">
                          <h>
                            Gakiza Allan Joseph brings a wealth of expertise as
                            a seasoned accountant, boasting a distinguished
                            track record of over 10 years within the
                            professional ...{" "}
                            <a
                              href="/allan-joseph-gakiza"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bold6"
                            >
                              <b>(Read More)</b>
                            </a>
                          </h>
                        </Card.Text>
                      </Card.Body>
                      <div className="container textcenter1">
                        <div className="col-md-2">
                          <a
                            href="https://ug.linkedin.com/in/gakiza-allan-joseph-89b3a8104"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <LinkedInIcon
                                sx={{ fontSize: "22px", color: "darkblue" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2 ">
                          <a
                            href="https://twitter.com/CIRCODU1"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <XIcon
                                  sx={{ fontSize: "22px", color: "black" }}
                                />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="https://www.facebook.com/Circodu/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <FacebookOutlinedIcon
                                sx={{ fontSize: "22px", color: "blue" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="https://wa.me/256704674985"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <WhatsAppIcon
                                sx={{ fontSize: "22px", color: "teal" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="mailto:allan.gakiza@circodu.org.ug"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <EmailIcon
                                sx={{ fontSize: "22px", color: "red" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
                <div className="col-md-3 mt-2">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Card style={{ width: "18rem" }}>
                      <a
                        href="/patrick-waweyo"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bold2"
                      >
                        <Card.Img
                          variant="top"
                          src={Card11}
                          alt="rounded image with shadow on hover"
                          style={{
                            height: "300px",
                            borderRadius: "50%",
                            boxShadow: "none",
                            transition: "box-shadow 0.3s ease-in-out",
                            transform: "scale(1) 0.5s ease-in-out",
                            width: "95%",
                            position: "relative",
                            overflow: "hidden",
                            marginTop: "8px",
                          }}
                          onMouseEnter={(event) => {
                            event.target.style.boxShadow =
                              "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                            event.target.style.transform = "scale(1.02)";
                          }}
                          onMouseLeave={(event) => {
                            event.target.style.boxShadow = "none";
                            event.target.style.transform = "scale(1)";
                          }}
                        />
                      </a>
                      <Card.Body>
                        <ListGroup className="list-group-flush">
                          <ListGroup.Item>
                            <a
                              href="/patrick-waweyo"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bold2"
                            >
                              Mr. Patrick Waweyo
                            </a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <h6 className="bold4">IT Officer/ Data Manager</h6>
                          </ListGroup.Item>
                          <ListGroup.Item></ListGroup.Item>
                        </ListGroup>
                        <Card.Text className="bold3">
                          <h>
                            Patrick Waweyo is a computer engineer whose career
                            journey has spanned education, technology, and
                            environmental advocacy. He holds a Bachelor's degree
                            ...{" "}
                            <a
                              href="/patrick-waweyo"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bold6"
                            >
                              <b>(Read More)</b>
                            </a>
                          </h>
                        </Card.Text>
                      </Card.Body>
                      <div className="container textcenter1">
                        <div className="col-md-2">
                          <a
                            href="https://www.linkedin.com/in/patrickwaweyo/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <LinkedInIcon
                                sx={{ fontSize: "22px", color: "darkblue" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2 ">
                          <a
                            href="https://twitter.com/NtabuPaddy"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <XIcon
                                  sx={{ fontSize: "22px", color: "black" }}
                                />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="https://www.facebook.com/Circodu/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <FacebookOutlinedIcon
                                sx={{ fontSize: "22px", color: "blue" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="https://wa.me/256705807536"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <WhatsAppIcon
                                sx={{ fontSize: "22px", color: "teal" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="mailto:patrick.waweyo@circodu.org.ug"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <EmailIcon
                                sx={{ fontSize: "22px", color: "red" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
                <div className="col-md-3 mt-2">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Card style={{ width: "18rem" }}>
                      <a
                        href="/carol-gabeya"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bold2"
                      >
                        <Card.Img
                          variant="top"
                          src={Card12}
                          alt="rounded image with shadow on hover"
                          style={{
                            height: "300px",
                            borderRadius: "50%",
                            boxShadow: "none",
                            transition: "box-shadow 0.3s ease-in-out",
                            transform: "scale(1) 0.5s ease-in-out",
                            width: "95%",
                            position: "relative",
                            overflow: "hidden",
                            marginTop: "8px",
                          }}
                          onMouseEnter={(event) => {
                            event.target.style.boxShadow =
                              "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                            event.target.style.transform = "scale(1.02)";
                          }}
                          onMouseLeave={(event) => {
                            event.target.style.boxShadow = "none";
                            event.target.style.transform = "scale(1)";
                          }}
                        />
                      </a>
                      <Card.Body>
                        <ListGroup className="list-group-flush">
                          <ListGroup.Item>
                            <a
                              href="/carol-gabeya"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bold2"
                            >
                              Ms. Caroline Gabeya
                            </a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <h6 className="bold4">Office Administrator</h6>
                          </ListGroup.Item>
                          <ListGroup.Item></ListGroup.Item>
                        </ListGroup>
                        <Card.Text className="bold3">
                          <h>
                            Ms. Gabeya Caroline, a dedicated community worker,
                            holds a Bachelor's degree in Adult and Community
                            Education from Makerere University and possesses a
                            certificate ...{" "}
                            <a
                              href="/carol-gabeya"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bold6"
                            >
                              <b>(Read More)</b>
                            </a>
                          </h>
                        </Card.Text>
                      </Card.Body>
                      <div className="container textcenter1">
                        <div className="col-md-2">
                          <a
                            href="https://ug.linkedin.com/company/centre-for-integrated-research-and-community-development-uganda-circodu"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <LinkedInIcon
                                sx={{ fontSize: "22px", color: "darkblue" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2 ">
                          <a
                            href="https://twitter.com/CIRCODU1/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <XIcon
                                  sx={{ fontSize: "22px", color: "black" }}
                                />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="https://www.facebook.com/Gabbi.carolyne?mibextid=ZbWKwL/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <FacebookOutlinedIcon
                                sx={{ fontSize: "22px", color: "blue" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="https://wa.me/256703436196"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <WhatsAppIcon
                                sx={{ fontSize: "22px", color: "teal" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="mailto:carol.gabeya@circodu.org.ug"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <EmailIcon
                                sx={{ fontSize: "22px", color: "red" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
                <div className="col-md-3 mt-2">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Card style={{ width: "18rem" }}>
                      <a
                        href="/patricia-nyansheegu"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bold2"
                      >
                        <Card.Img
                          variant="top"
                          src={Card10}
                          alt="rounded image with shadow on hover"
                          style={{
                            height: "300px",
                            borderRadius: "50%",
                            boxShadow: "none",
                            transition: "box-shadow 0.3s ease-in-out",
                            transform: "scale(1) 0.5s ease-in-out",
                            width: "95%",
                            position: "relative",
                            overflow: "hidden",
                            marginTop: "8px",
                          }}
                          onMouseEnter={(event) => {
                            event.target.style.boxShadow =
                              "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                            event.target.style.transform = "scale(1.02)";
                          }}
                          onMouseLeave={(event) => {
                            event.target.style.boxShadow = "none";
                            event.target.style.transform = "scale(1)";
                          }}
                        />
                      </a>
                      <Card.Body>
                        <ListGroup className="list-group-flush">
                          <ListGroup.Item>
                            <a
                              href="/patricia-nyansheegu"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bold2"
                            >
                              Ms. Patricia Nyansheegu
                            </a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <h6 className="bold4">
                              Laboratory Quality Manager
                            </h6>
                          </ListGroup.Item>
                          <ListGroup.Item></ListGroup.Item>
                        </ListGroup>
                        <Card.Text className="bold3">
                          <h>
                            Patricia Nyansheegu is an accomplished professional
                            who holds a Bachelor's Degree in Conservation
                            Forestry and Products Technology from Makerere ...{" "}
                            <a
                              href="/patricia-nyansheegu"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bold6"
                            >
                              <b>(Read More)</b>
                            </a>
                          </h>
                        </Card.Text>
                      </Card.Body>
                      <div className="container textcenter1">
                        <div className="col-md-2">
                          <a
                            href="https://www.linkedin.com/in/patricia-nyansheegu-62473b286/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <LinkedInIcon
                                sx={{ fontSize: "22px", color: "darkblue" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2 ">
                          <a
                            href="https://twitter.com/Patricia0781161"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <XIcon
                                  sx={{ fontSize: "22px", color: "black" }}
                                />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="https://www.facebook.com/patricia.nyansheegu"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <FacebookOutlinedIcon
                                sx={{ fontSize: "22px", color: "blue" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="https://wa.me/256781165201"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <WhatsAppIcon
                                sx={{ fontSize: "22px", color: "teal" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="mailto:patricia.nyansheegu@circodu.org.ug"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <EmailIcon
                                sx={{ fontSize: "22px", color: "red" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
                <div className="col-md-3 mt-2">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Card style={{ width: "18rem" }}>
                      <a
                        href="/rashid-seggujja"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bold2"
                      >
                        <Card.Img
                          variant="top"
                          src={Card13}
                          alt="rounded image with shadow on hover"
                          style={{
                            height: "300px",
                            borderRadius: "50%",
                            boxShadow: "none",
                            transition: "box-shadow 0.3s ease-in-out",
                            transform: "scale(1) 0.5s ease-in-out",
                            width: "95%",
                            position: "relative",
                            overflow: "hidden",
                            marginTop: "8px",
                          }}
                          onMouseEnter={(event) => {
                            event.target.style.boxShadow =
                              "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                            event.target.style.transform = "scale(1.02)";
                          }}
                          onMouseLeave={(event) => {
                            event.target.style.boxShadow = "none";
                            event.target.style.transform = "scale(1)";
                          }}
                        />
                      </a>
                      <Card.Body>
                        <ListGroup className="list-group-flush">
                          <ListGroup.Item>
                            <a
                              href="/rashid-seggujja"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bold2"
                            >
                              Mr. Rashid Seggujja
                            </a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <h6 className="bold4">
                              Laboratory Technical Manager
                            </h6>
                          </ListGroup.Item>
                          <ListGroup.Item></ListGroup.Item>
                        </ListGroup>
                        <Card.Text className="bold3">
                          <h>
                            Rashid holds a Bachelor's Degree in Industrial and
                            Organizational Psychology, a credential he earned
                            from Makerere University. His educational journey
                            ...{" "}
                            <a
                              href="/rashid-seggujja"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bold6"
                            >
                              <b>(Read More)</b>
                            </a>
                          </h>
                        </Card.Text>
                      </Card.Body>
                      <div className="container textcenter1">
                        <div className="col-md-2">
                          <a
                            href="https://ug.linkedin.com/company/centre-for-integrated-research-and-community-development-uganda-circodu"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <LinkedInIcon
                                sx={{ fontSize: "22px", color: "darkblue" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2 ">
                          <a
                            href="https://twitter.com/CIRCODU1"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <XIcon
                                  sx={{ fontSize: "22px", color: "black" }}
                                />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="https://www.facebook.com/rasheed.seggujja"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <FacebookOutlinedIcon
                                sx={{ fontSize: "22px", color: "blue" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="https://wa.me/256701447940"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <WhatsAppIcon
                                sx={{ fontSize: "22px", color: "teal" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="mailto:rashid.seggujja@circodu.org.ug"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <EmailIcon
                                sx={{ fontSize: "22px", color: "red" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
                <div className="col-md-3 mt-2">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Card style={{ width: "18rem" }}>
                      <a
                        href="/davis-kintu"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bold2"
                      >
                        <Card.Img
                          variant="top"
                          src={Card5}
                          alt="rounded image with shadow on hover"
                          style={{
                            height: "300px",
                            borderRadius: "50%",
                            boxShadow: "none",
                            transition: "box-shadow 0.3s ease-in-out",
                            transform: "scale(1) 0.5s ease-in-out",
                            width: "95%",
                            position: "relative",
                            overflow: "hidden",
                            marginTop: "8px",
                          }}
                          onMouseEnter={(event) => {
                            event.target.style.boxShadow =
                              "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                            event.target.style.transform = "scale(1.02)";
                          }}
                          onMouseLeave={(event) => {
                            event.target.style.boxShadow = "none";
                            event.target.style.transform = "scale(1)";
                          }}
                        />
                      </a>
                      <Card.Body>
                        <ListGroup className="list-group-flush">
                          <ListGroup.Item>
                            <a
                              href="/davis-kintu"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bold2"
                            >
                              Mr. Davis Kintu
                            </a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <h6 className="bold4">
                              Senior Laboratory Technician
                            </h6>
                          </ListGroup.Item>
                          <ListGroup.Item></ListGroup.Item>
                        </ListGroup>
                        <Card.Text className="bold3">
                          <h>
                            Davis Kintu graduated from Makerere University with
                            a Bachelor's degree in Environmental Management. His
                            educational foundation is fortified by a series of
                            professi ...{" "}
                            <a
                              href="/davis-kintu"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bold6"
                            >
                              <b>(Read More)</b>
                            </a>
                          </h>
                        </Card.Text>
                      </Card.Body>
                      <div className="container textcenter1">
                        <div className="col-md-2">
                          <a
                            href="https://ug.linkedin.com/in/kintu-davis-1744286b/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <LinkedInIcon
                                sx={{ fontSize: "22px", color: "darkblue" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2 ">
                          <a
                            href="https://twitter.com/CIRCODU1"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <XIcon
                                  sx={{ fontSize: "22px", color: "black" }}
                                />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="https://www.facebook.com/muwanguzi.davis.12/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <FacebookOutlinedIcon
                                sx={{ fontSize: "22px", color: "blue" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="https://wa.me/256700127623"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <WhatsAppIcon
                                sx={{ fontSize: "22px", color: "teal" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="mailto:davis.kintu@circodu.org.ug	"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <EmailIcon
                                sx={{ fontSize: "22px", color: "red" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
                <div className="col-md-3 mt-2">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Card style={{ width: "18rem" }}>
                      <a
                        href="/livingstone-kilwa"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bold2"
                      >
                        <Card.Img
                          variant="top"
                          src={Card9}
                          alt="rounded image with shadow on hover"
                          style={{
                            height: "300px",
                            borderRadius: "50%",
                            boxShadow: "none",
                            transition: "box-shadow 0.3s ease-in-out",
                            transform: "scale(1) 0.5s ease-in-out",
                            width: "95%",
                            position: "relative",
                            overflow: "hidden",
                            marginTop: "8px",
                          }}
                          onMouseEnter={(event) => {
                            event.target.style.boxShadow =
                              "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                            event.target.style.transform = "scale(1.02)";
                          }}
                          onMouseLeave={(event) => {
                            event.target.style.boxShadow = "none";
                            event.target.style.transform = "scale(1)";
                          }}
                        />
                      </a>
                      <Card.Body>
                        <ListGroup className="list-group-flush">
                          <ListGroup.Item>
                            <a
                              href="/livingstone-kilwa"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bold2"
                            >
                              Mr. Livingstone Kilwa
                            </a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <h6 className="bold4">Laboratory Technician</h6>
                          </ListGroup.Item>
                          <ListGroup.Item></ListGroup.Item>
                        </ListGroup>
                        <Card.Text className="bold3">
                          <h>
                            Kilwa Livingstone holds a Bachelor's Degree in
                            Development Studies from Makerere University in
                            Uganda. His educational foundation is fortified by
                            an array of valuable ....{" "}
                            <a
                              href="/livingstone-kilwa"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bold6"
                            >
                              <b>(Read More)</b>
                            </a>
                          </h>
                        </Card.Text>
                      </Card.Body>
                      <div className="container textcenter1">
                        <div className="col-md-2">
                          <a
                            href="https://www.linkedin.com/in/kilwa-livingstone-659436180/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <LinkedInIcon
                                sx={{ fontSize: "22px", color: "darkblue" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2 ">
                          <a
                            href="https://twitter.com/Stone8924"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <XIcon
                                  sx={{ fontSize: "22px", color: "black" }}
                                />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="https://www.facebook.com/kilwa.livingstone/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <FacebookOutlinedIcon
                                sx={{ fontSize: "22px", color: "blue" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="https://wa.me/256706901771"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <WhatsAppIcon
                                sx={{ fontSize: "22px", color: "teal" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="mailto:kilwa.livingstone@circodu.org.ug"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <EmailIcon
                                sx={{ fontSize: "22px", color: "red" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
                <div className="col-md-3 mt-2">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Card style={{ width: "18rem" }}>
                      <a
                        href="/goodchild-isingoma-yonah"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bold2"
                      >
                        <Card.Img
                          variant="top"
                          src={Card8}
                          alt="rounded image with shadow on hover"
                          style={{
                            height: "300px",
                            borderRadius: "50%",
                            boxShadow: "none",
                            transition: "box-shadow 0.3s ease-in-out",
                            transform: "scale(1) 0.5s ease-in-out",
                            width: "95%",
                            position: "relative",
                            overflow: "hidden",
                            marginTop: "8px",
                          }}
                          onMouseEnter={(event) => {
                            event.target.style.boxShadow =
                              "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                            event.target.style.transform = "scale(1.02)";
                          }}
                          onMouseLeave={(event) => {
                            event.target.style.boxShadow = "none";
                            event.target.style.transform = "scale(1)";
                          }}
                        />
                      </a>
                      <Card.Body>
                        <ListGroup className="list-group-flush">
                          <ListGroup.Item>
                            <a
                              href="/goodchild-isingoma-yonah"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bold2"
                            >
                              Mr. Goodchild Isingoma Yonah
                            </a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <h6 className="bold4">Transport Officer</h6>
                          </ListGroup.Item>
                          <ListGroup.Item></ListGroup.Item>
                        </ListGroup>
                        <Card.Text className="bold3">
                          <h>
                            Isingoma Yonah possesses a secondary education
                            certificate and holds a CM & B driving permit. His
                            qualifications encompass certificates in competent driving, ...{" "}
                            <a
                              href="/goodchild-isingoma-yonah"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bold6"
                            >
                              <b>(Read More)</b>
                            </a>
                          </h>
                        </Card.Text>
                      </Card.Body>
                      <div className="container textcenter1">
                        <div className="col-md-2">
                          <a
                            href="https://ug.linkedin.com/company/centre-for-integrated-research-and-community-development-uganda-circodu"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <LinkedInIcon
                                sx={{ fontSize: "22px", color: "darkblue" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2 ">
                          <a
                            href="https://twitter.com/CIRCODU1"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <XIcon
                                  sx={{ fontSize: "22px", color: "black" }}
                                />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="https://www.facebook.com/Circodu/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <FacebookOutlinedIcon
                                sx={{ fontSize: "22px", color: "blue" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="https://wa.me/256751178448"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <WhatsAppIcon
                                sx={{ fontSize: "22px", color: "teal" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="mailto:isingoma.jonah@circodu.org.ug"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <EmailIcon
                                sx={{ fontSize: "22px", color: "red" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
                <div className="col-md-3 mt-2">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Card style={{ width: "18rem" }}>
                      <a
                        href="/hasifa-namugabo"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bold2"
                      >
                        <Card.Img
                          variant="top"
                          src={Card7}
                          alt="rounded image with shadow on hover"
                          style={{
                            height: "300px",
                            borderRadius: "50%",
                            boxShadow: "none",
                            transition: "box-shadow 0.3s ease-in-out",
                            transform: "scale(1) 0.5s ease-in-out",
                            width: "95%",
                            position: "relative",
                            overflow: "hidden",
                            marginTop: "8px",
                          }}
                          onMouseEnter={(event) => {
                            event.target.style.boxShadow =
                              "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                            event.target.style.transform = "scale(1.02)";
                          }}
                          onMouseLeave={(event) => {
                            event.target.style.boxShadow = "none";
                            event.target.style.transform = "scale(1)";
                          }}
                        />
                      </a>
                      <Card.Body>
                        <ListGroup className="list-group-flush">
                          <ListGroup.Item>
                            <a
                              href="/hasifa-namugabo"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bold2"
                            >
                              Ms. Hasifa Namugabo
                            </a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <h6 className="bold4">Welfare Officer</h6>
                          </ListGroup.Item>
                          <ListGroup.Item></ListGroup.Item>
                        </ListGroup>
                        <Card.Text className="bold3">
                          <h>
                            Hasifa holds a secondary education certificate and
                            has obtained a cosmetology certificate from Tina
                            School of Beauty. She brings a wealth of experience
                            in ...{" "}
                            <a
                              href="/hasifa-namugabo"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bold6"
                            >
                              <b>(Read More)</b>
                            </a>
                          </h>
                        </Card.Text>
                      </Card.Body>
                      <div className="container textcenter1">
                        <div className="col-md-2">
                          <a
                            href="https://ug.linkedin.com/company/centre-for-integrated-research-and-community-development-uganda-circodu"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <LinkedInIcon
                                sx={{ fontSize: "22px", color: "darkblue" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2 ">
                          <a
                            href="https://twitter.com/CIRCODU1"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <XIcon
                                  sx={{ fontSize: "22px", color: "black" }}
                                />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="https://www.facebook.com/namugabo.hasifa/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <FacebookOutlinedIcon
                                sx={{ fontSize: "22px", color: "blue" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="https://wa.me/256703384157"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <WhatsAppIcon
                                sx={{ fontSize: "22px", color: "teal" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="mailto:mercyhasifah@gmail.com"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <EmailIcon
                                sx={{ fontSize: "22px", color: "red" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
                <div className="col-md-3 mt-2">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Card style={{ width: "18rem" }}>
                      <a
                        href="/eunice-kyomuhendo"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bold2"
                      >
                        <Card.Img
                          variant="top"
                          src={Card6}
                          alt="rounded image with shadow on hover"
                          style={{
                            height: "300px",
                            borderRadius: "50%",
                            boxShadow: "none",
                            transition: "box-shadow 0.3s ease-in-out",
                            transform: "scale(1) 0.5s ease-in-out",
                            width: "95%",
                            position: "relative",
                            overflow: "hidden",
                            marginTop: "8px",
                          }}
                          onMouseEnter={(event) => {
                            event.target.style.boxShadow =
                              "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                            event.target.style.transform = "scale(1.02)";
                          }}
                          onMouseLeave={(event) => {
                            event.target.style.boxShadow = "none";
                            event.target.style.transform = "scale(1)";
                          }}
                        />
                      </a>
                      <Card.Body>
                        <ListGroup className="list-group-flush">
                          <ListGroup.Item>
                            <a
                              href="/eunice-kyomuhendo"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bold2"
                            >
                              Mrs. Eunice Kyomuhendo
                            </a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <h6 className="bold4">Cleaner</h6>
                          </ListGroup.Item>
                          <ListGroup.Item></ListGroup.Item>
                        </ListGroup>
                        <Card.Text className="bold3">
                          <h>
                            Eunice Kyomuhendo has a primary leaving education
                            certificate and has embarked on a varied career
                            path. She has been employed as a cleaner for the
                            past six years ...{" "}
                            <a
                              href="/eunice-kyomuhendo"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bold6"
                            >
                              <b>(Read More)</b>
                            </a>
                          </h>
                        </Card.Text>
                      </Card.Body>
                      <div className="container textcenter1">
                        <div className="col-md-2">
                          <a
                            href="https://ug.linkedin.com/company/centre-for-integrated-research-and-community-development-uganda-circodu"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <LinkedInIcon
                                sx={{ fontSize: "22px", color: "darkblue" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2 ">
                          <a
                            href="https://twitter.com/CIRCODU1"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <XIcon
                                  sx={{ fontSize: "22px", color: "black" }}
                                />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="https://www.facebook.com/Circodu/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <FacebookOutlinedIcon
                                sx={{ fontSize: "22px", color: "blue" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="https://wa.me/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <WhatsAppIcon
                                sx={{ fontSize: "22px", color: "teal" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="mailto:info@circodu.org.ug"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <EmailIcon
                                sx={{ fontSize: "22px", color: "red" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
                <div className="col-md-3 mt-2">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Card style={{ width: "18rem" }}>
                      <a
                        href="/bernard-byamukama"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bold2"
                      >
                        <Card.Img
                          variant="top"
                          src={Card14}
                          alt="rounded image with shadow on hover"
                          style={{
                            height: "300px",
                            borderRadius: "50%",
                            boxShadow: "none",
                            transition: "box-shadow 0.3s ease-in-out",
                            transform: "scale(1) 0.5s ease-in-out",
                            width: "95%",
                            position: "relative",
                            overflow: "hidden",
                            marginTop: "8px",
                          }}
                          onMouseEnter={(event) => {
                            event.target.style.boxShadow =
                              "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                            event.target.style.transform = "scale(1.02)";
                          }}
                          onMouseLeave={(event) => {
                            event.target.style.boxShadow = "none";
                            event.target.style.transform = "scale(1)";
                          }}
                        />
                      </a>
                      <Card.Body>
                        <ListGroup className="list-group-flush">
                          <ListGroup.Item>
                            <a
                              href="/bernard-byamukama"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bold2"
                            >
                              Mr. Bernard Byamukama
                            </a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <h6 className="bold4">Security Officer</h6>
                          </ListGroup.Item>
                          <ListGroup.Item></ListGroup.Item>
                        </ListGroup>
                        <Card.Text className="bold3">
                          <h>
                            Bernard Byamukama completed his secondary education
                            at the ordinary level. He holds a National
                            certificate in Automotive Mechanics and Driving,
                            which he earned ...{" "}
                            <a
                              href="/bernard-byamukama"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bold6"
                            >
                              <b>(Read More)</b>
                            </a>
                          </h>
                        </Card.Text>
                      </Card.Body>
                      <div className="container textcenter1">
                        <div className="col-md-2">
                          <a
                            href="https://ug.linkedin.com/company/centre-for-integrated-research-and-community-development-uganda-circodu"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <LinkedInIcon
                                sx={{ fontSize: "22px", color: "darkblue" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2 ">
                          <a
                            href="https://twitter.com/CIRCODU1"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <XIcon
                                  sx={{ fontSize: "22px", color: "black" }}
                                />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="https://www.facebook.com/profile.php?id=100078693347998"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <FacebookOutlinedIcon
                                sx={{ fontSize: "22px", color: "blue" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="https://wa.me/256781893491"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <WhatsAppIcon
                                sx={{ fontSize: "22px", color: "teal" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="mailto:byamukamaben2020@gmail.com"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <EmailIcon
                                sx={{ fontSize: "22px", color: "red" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
                <div className="col-md-3 mt-2">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Card style={{ width: "18rem" }}>
                      <a
                        href="/aran-ahereza"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bold2"
                      >
                        <Card.Img
                          variant="top"
                          src={Card4}
                          alt="rounded image with shadow on hover"
                          style={{
                            height: "300px",
                            borderRadius: "50%",
                            boxShadow: "none",
                            transition: "box-shadow 0.3s ease-in-out",
                            transform: "scale(1) 0.5s ease-in-out",
                            width: "95%",
                            position: "relative",
                            overflow: "hidden",
                            marginTop: "8px",
                          }}
                          onMouseEnter={(event) => {
                            event.target.style.boxShadow =
                              "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                            event.target.style.transform = "scale(1.02)";
                          }}
                          onMouseLeave={(event) => {
                            event.target.style.boxShadow = "none";
                            event.target.style.transform = "scale(1)";
                          }}
                        />
                      </a>
                      <Card.Body>
                        <ListGroup className="list-group-flush">
                          <ListGroup.Item>
                            <a
                              href="/aran-ahereza"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bold2"
                            >
                              Mr. Aran Ahereza
                            </a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <h6 className="bold4">Security Officer</h6>
                          </ListGroup.Item>
                          <ListGroup.Item></ListGroup.Item>
                        </ListGroup>
                        <Card.Text className="bold3">
                          <h>
                            Aran Ahereza holds a secondary education certificate
                            at the ordinary level. Additionally, he holds a
                            National certificate in Automotive Mechanics, which
                            he acquired ...{" "}
                            <a
                              href="/aran-ahereza"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bold6"
                            >
                              <b>(Read More)</b>
                            </a>
                          </h>
                        </Card.Text>
                      </Card.Body>
                      <div className="container textcenter1">
                        <div className="col-md-2">
                          <a
                            href="https://ug.linkedin.com/company/centre-for-integrated-research-and-community-development-uganda-circodu"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <LinkedInIcon
                                sx={{ fontSize: "22px", color: "darkblue" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2 ">
                          <a
                            href="https://twitter.com/CIRCODU1/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <XIcon
                                  sx={{ fontSize: "22px", color: "black" }}
                                />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="https://m.facebook.com/people/Allan-Kuz-Ahereza/100088951399209/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <FacebookOutlinedIcon
                                sx={{ fontSize: "22px", color: "blue" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="https://wa.me/256761383838"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <WhatsAppIcon
                                sx={{ fontSize: "22px", color: "teal" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="mailto:aherezaallanovic486@gmail.com"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              sx={{ bgcolor: "#d7ebf3", color: "red" }}
                            >
                              <EmailIcon
                                sx={{ fontSize: "22px", color: "red" }}
                              />
                            </IconButton>
                          </a>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default OurStaff;
