import React from "react";
import Card2 from "../Images/Headers/team.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import "../Profiles/profileimg.css";
import Card3 from "../Images/Profiles/Fred.jpg";

function FredIsabirye() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Mr. Fred Isabirye</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">The Team</Breadcrumb.Item>
              <Breadcrumb.Item
                className="text1"
                href="/the-team/top-management"
              >
                Top Management
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Mr. Fred Isabirye
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Mr. Fred Isabirye</b>
              </h>
              <br></br>
              <h>
                <b>Field Manager</b>
              </h>
              <br></br>
              <h className="texty">MEC Design Expert</h>
            </div>
            <div className="col-md-3 mt-2">
              <div className="imagecarded">
                <img
                  src={Card3}
                  alt=""
                  height="auto" width="100%"
                  style={{
                    borderRadius: "45%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                    event.target.style.transform = "scale(1.01)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </div>
            </div>
            <div className="col-md-9 mt-2">
              <p className="texty">
                Fred holds a Master’s Degree in Intellectual Property (MIP) from
                Africa University and a Bachelor of Science Degree in Mechanical
                Engineering (BSc MEC) from Makerere University.
              </p>
              <p className="texty">
                Fred has developed significant expertise in the realm of change
                management tools, which empowers him to not only foresee but
                also proactively navigate changes, especially in the swiftly
                evolving technical sector. Additionally, he serves as a
                part-time lecturer in the Department of Electrical and
                Electronic Engineering at Kyambogo University. In this capacity,
                he is involved in teaching various course units, including
                Mechanical Engineering Science, Engineering Mathematics,
                Mechanical Drawing, Mechanical Workshop Practice, Industrial
                Management and Organization, Project Planning, Project Design,
                Instrumentation and Control, and Principles of Maintenance
                Engineering. Fred also contributes to curriculum enhancements
                within the department.
              </p>
              <p className="texty">
                Fred's professional journey includes a valuable stint as a Shift
                Engineer at Kakira Sugar Works Ltd. He is a dynamic and
                passionate mechanical engineer with a diverse administrative
                background, marked by robust organizational and communication
                skills. Fred thrives as a collaborative team player and
                showcases a penchant for taking personal initiative to achieve
                project objectives. His adaptability enables him to swiftly
                acquire new skills and competencies.
              </p>
              <p className="texty">
                Moreover, Fred holds the role of Chief Reconnoiter at the Uganda
                Business and Technical Examinations Board (UBTEB). In this
                capacity, he diligently compiles performance data of students
                across various course units and provides advisory support to the
                board.
              </p>
              <p className="texty">
                Within the Centre for Integrated Research and Community
                Development Uganda (CIRCODU), Fred serves as the Field Manager.
                In this role, he actively participates in field activities that
                encompass conducting training sessions related to Briquette
                Making, performing kitchen surveys and tests, delivering
                training to ENVOCON staff in Tanzania, and contributing to
                research write-ups.
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-2">
            <div className="container textcenter2">
              <div className="col-md-1">
                <a
                  href="https://www.linkedin.com/in/fred-isabirye-b0b59791/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <LinkedInIcon
                      sx={{ fontSize: "22px", color: "darkblue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="https://twitter.com/CIRCODU1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <TwitterIcon sx={{ fontSize: "22px", color: "blue" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="https://www.facebook.com/Circodu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <FacebookOutlinedIcon
                      sx={{ fontSize: "22px", color: "blue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="https://wa.me/256752876969"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <WhatsAppIcon sx={{ fontSize: "22px", color: "teal" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="mailto:fred.isabirye@circodu.org.ug"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <EmailIcon sx={{ fontSize: "22px", color: "red" }} />
                  </IconButton>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default FredIsabirye;
