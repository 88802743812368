import React from "react";
import Card2 from "../Images/Headers/header-3.jpg";
import Card1 from "../Images/CIRCODU-BANNERS-4.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function WasteManagement() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Waste Management</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Our Thematic Areas
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Waste Management
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Waste Management</b>
              </h>
              <p className="texty">
                Proper waste management is crucial for environmental
                sustainability and public health. CIRCODU recognizes the need to
                promote responsible waste management practices in Uganda and is
                committed to working towards achieving this goal. The
                organization aims to partner with other stakeholders to promote
                waste reduction, reuse, and recycling to minimize waste
                generation and promote resource efficiency.
              </p>
              <p className="texty">
                CIRCODU also seeks to promote safe and environmentally sound
                waste disposal practices, such as waste segregation, landfill
                management, and hazardous waste disposal, to protect public
                health and prevent environmental pollution. Through these
                efforts, CIRCODU aims to contribute to the achievement of
                Sustainable Development Goal 12, which seeks to ensure
                sustainable consumption and production patterns.
              </p>
            </div>
            <div className="col-md-4">
              <div className="imagecard">
                <img
                  src={Card1}
                  alt=""
                  height="100%"
                  width="100%"
                  style={{ borderRadius: "5%" }}
                />
              </div>
            </div>
            <div className="col-md-8">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Objectives of the Waste Management Thematic Area</b>
              </h>
              <p className="texty"></p>
              <p className="texty"></p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default WasteManagement;
