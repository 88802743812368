import React from "react";
import Card2 from "../Images/Headers/team.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import "../Profiles/profileimg.css";
import Card10 from "../Images/Profiles/Profile/Patricia.jpg";

function PatriciaNyansheegu() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Ms. Patricia Nyansheegu</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">The Team</Breadcrumb.Item>
              <Breadcrumb.Item className="text1" href="/the-staff-team">
                The Staff Team
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Ms. Patricia Nyansheegu
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Ms. Patricia Nyansheegu</b>
              </h>
              <br></br>
              <h>
                <b>Laboratory Quality Manager</b>
              </h>
            </div>
            <div className="col-md-3 mt-2">
              <div className="imagecarded">
                <img
                  src={Card10}
                  alt=""
                  height="auto" width="100%"
                  style={{
                    borderRadius: "45%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                    event.target.style.transform = "scale(1.01)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </div>
            </div>
            <div className="col-md-9 mt-2">
              <p className="texty">
                Patricia Nyansheegu is an accomplished professional who holds a
                Bachelor's Degree in Conservation Forestry and Products
                Technology from Makerere University. Her dedication to knowledge
                enhancement is underscored by a series of prestigious
                professional certificates that showcase her commitment to
                excellence and proficiency.
              </p>
              <p className="texty">
                Her credentials encompass conducting internal audits based on
                ISO:9001 and ISO/IEC 17025, a skill that underscores her
                dedication to ensuring organizational quality standards. She
                also holds a Certificate in Quality Management System based on
                ISO/IEC 17025:2017, a credential that attests to her competence
                in maintaining and optimizing quality protocols. Furthermore,
                Patricia has earned a Certificate in Water Boiling Tests (WBT),
                which encompasses diverse protocols for evaluating cookstove
                emissions and efficiency in controlled laboratory environments.
                Her capabilities extend to the Controlled Cooking Test (C.C.T),
                as indicated by her certification in Version 2.0 of this
                methodology. Patricia is also proficient in Emissions Testing
                methods and protocols, which further solidifies her expertise in
                the field.
              </p>
              <p className="texty">
                Throughout her professional journey, Patricia's impact has been
                substantial. Serving as a Laboratory Quality Manager at CIRCODU
                since 2017, her role has been pivotal in ensuring the highest
                standards of laboratory operations. Her prior roles include
                serving as an Agroforestry Trainer with the Presidential
                Initiative on Banana Industrial Development, showcasing her
                commitment to sustainable agricultural practices. Additionally,
                she excelled as a Quality Control Manager at Fine Spinners
                Uganda Limited, a testament to her ability to uphold product
                excellence. Patricia's experiences extend to proficiency in
                quality control systems, Agroforestry Management Systems, and
                adept use of data entry tools such as SPSS, MS Office,
                PowerPoint, and Excel.
              </p>
              <p className="texty">
                With over five years of professional experience, Patricia
                Nyansheegu's career journey has been one of continuous learning,
                practical application, and impactful contributions. Her
                proficiency in quality management systems, paired with her
                analytical skills, make her an asset to any endeavor she
                undertakes.
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-2">
            <div className="container textcenter2">
              <div className="col-md-1">
                <a
                  href="https://www.linkedin.com/in/patricia-nyansheegu-62473b286/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <LinkedInIcon
                      sx={{ fontSize: "22px", color: "darkblue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="https://twitter.com/Patricia0781161"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <TwitterIcon sx={{ fontSize: "22px", color: "blue" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a href="https://www.facebook.com/patricia.nyansheegu" target="_blank" rel="noopener noreferrer">
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <FacebookOutlinedIcon
                      sx={{ fontSize: "22px", color: "blue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="https://wa.me/256781165201"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <WhatsAppIcon sx={{ fontSize: "22px", color: "teal" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="mailto:patricia.nyansheegu@circodu.org.ug"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <EmailIcon sx={{ fontSize: "22px", color: "red" }} />
                  </IconButton>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PatriciaNyansheegu;
