import React from 'react'
import Card2 from "../Images/Headers/team.jpg";
import "../Pages/OurHistory.css"
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Table from 'react-bootstrap/Table';
import "../Pages/ResearchAssistants.css"
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { IconButton } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';

function ResearchAssistants() {
    return (
        <div>
            <div className="head-text">
                <div className="head-image">
                    <img src={Card2} alt="" height="200" width="100%" />
                </div>
                <div class='text-on-image'>
                    <h4 className="bold">The Research Assistants</h4>
                    <div class="text-center">
                        <Breadcrumb>
                            <Breadcrumb.Item className="text1" href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item className="text1">The Team</Breadcrumb.Item>
                            <Breadcrumb.Item className="text1">The Research Assistants</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>

            <section className="section border-bottom">
                <div className="container mt-2 border-top">
                    <div className="row">
                        <div className="pt-2">
                            <p className="textBOD">
                            Centre for Integrated Rural and Community Development Uganda (CIRCODU)
                            is previledged to have a Team of Professional, HardWorking and Dedicated Personnel
                            in its Research Assistants that span a variety of fields of study which gives CIRCODU an edge over
                            anyone while carrying out its mandate.
                            </p>
                            <p className="textBOD">
                            Below is a list of the CIRCODU Research Assistants.
                            </p>
                        </div>
                        <Table striped>
                            <thead>
                                <tr className='Numberg'>
                                    <th>
                                        <div className="col-md-1">
                                            <div className="container">
                                                #
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="col-md-3">
                                            <div className="container">
                                                Name
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="col-md-3">
                                            <div className="container">
                                                Profession
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="col-md-3">
                                            <div className="container">
                                                Contacts
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='Numberg'>
                                    <td>
                                        <div className="col-md-1">
                                            <div className="container">
                                                1.
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container">
                                                Patrick Waweyo
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container">
                                                BSc. Computer Engineering
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container textcenter2">
                                                <div className="col-md-2">
                                                    <a href="/" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <LinkedInIcon sx={{ fontSize: "22px", color: "darkblue", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                                <div className="col-md-2">
                                                    <a href="/" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <WhatsAppIcon sx={{ fontSize: "22px", color: "teal", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                                <div className="col-md-2">
                                                    <a href="mailto:pntabu@gmail.com" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <EmailIcon sx={{ fontSize: "22px", color: "red", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='Numberg'>
                                    <td>
                                        <div className="col-md-1">
                                            <div className="container">
                                                2.
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container">
                                                Patrick Waweyo
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container">
                                                BSc. Computer Engineering
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container textcenter2">
                                                <div className="col-md-2">
                                                    <a href="/" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <LinkedInIcon sx={{ fontSize: "22px", color: "darkblue", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                                <div className="col-md-2">
                                                    <a href="/" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <WhatsAppIcon sx={{ fontSize: "22px", color: "teal", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                                <div className="col-md-2">
                                                    <a href="mailto:pntabu@gmail.com" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <EmailIcon sx={{ fontSize: "22px", color: "red", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='Numberg'>
                                    <td>
                                        <div className="col-md-1">
                                            <div className="container">
                                                3.
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container">
                                                Patrick Waweyo
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container">
                                                BSc. Computer Engineering
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container textcenter2">
                                                <div className="col-md-2">
                                                    <a href="/" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <LinkedInIcon sx={{ fontSize: "22px", color: "darkblue", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                                <div className="col-md-2">
                                                    <a href="/" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <WhatsAppIcon sx={{ fontSize: "22px", color: "teal", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                                <div className="col-md-2">
                                                    <a href="mailto:pntabu@gmail.com" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <EmailIcon sx={{ fontSize: "22px", color: "red", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='Numberg'>
                                    <td>
                                        <div className="col-md-1">
                                            <div className="container">
                                                4.
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container">
                                                Patrick Waweyo
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container">
                                                BSc. Computer Engineering
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container textcenter2">
                                                <div className="col-md-2">
                                                    <a href="/" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <LinkedInIcon sx={{ fontSize: "22px", color: "darkblue", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                                <div className="col-md-2">
                                                    <a href="/" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <WhatsAppIcon sx={{ fontSize: "22px", color: "teal", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                                <div className="col-md-2">
                                                    <a href="mailto:pntabu@gmail.com" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <EmailIcon sx={{ fontSize: "22px", color: "red", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='Numberg'>
                                    <td>
                                        <div className="col-md-1">
                                            <div className="container">
                                                5.
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container">
                                                Patrick Waweyo
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container">
                                                BSc. Computer Engineering
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container textcenter2">
                                                <div className="col-md-2">
                                                    <a href="/" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <LinkedInIcon sx={{ fontSize: "22px", color: "darkblue", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                                <div className="col-md-2">
                                                    <a href="/" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <WhatsAppIcon sx={{ fontSize: "22px", color: "teal", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                                <div className="col-md-2">
                                                    <a href="mailto:pntabu@gmail.com" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <EmailIcon sx={{ fontSize: "22px", color: "red", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='Numberg'>
                                    <td>
                                        <div className="col-md-1">
                                            <div className="container">
                                                6.
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container">
                                                Patrick Waweyo
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container">
                                                BSc. Computer Engineering
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container textcenter2">
                                                <div className="col-md-2">
                                                    <a href="/" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <LinkedInIcon sx={{ fontSize: "22px", color: "darkblue", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                                <div className="col-md-2">
                                                    <a href="/" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <WhatsAppIcon sx={{ fontSize: "22px", color: "teal", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                                <div className="col-md-2">
                                                    <a href="mailto:pntabu@gmail.com" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <EmailIcon sx={{ fontSize: "22px", color: "red", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='Numberg'>
                                    <td>
                                        <div className="col-md-1">
                                            <div className="container">
                                                7.
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container">
                                                Patrick Waweyo
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container">
                                                BSc. Computer Engineering
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container textcenter2">
                                                <div className="col-md-2">
                                                    <a href="/" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <LinkedInIcon sx={{ fontSize: "22px", color: "darkblue", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                                <div className="col-md-2">
                                                    <a href="/" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <WhatsAppIcon sx={{ fontSize: "22px", color: "teal", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                                <div className="col-md-2">
                                                    <a href="mailto:pntabu@gmail.com" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <EmailIcon sx={{ fontSize: "22px", color: "red", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='Numberg'>
                                    <td>
                                        <div className="col-md-1">
                                            <div className="container">
                                                8.
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container">
                                                Patrick Waweyo
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container">
                                                BSc. Computer Engineering
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container textcenter2">
                                                <div className="col-md-2">
                                                    <a href="/" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <LinkedInIcon sx={{ fontSize: "22px", color: "darkblue", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                                <div className="col-md-2">
                                                    <a href="/" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <WhatsAppIcon sx={{ fontSize: "22px", color: "teal", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                                <div className="col-md-2">
                                                    <a href="mailto:pntabu@gmail.com" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <EmailIcon sx={{ fontSize: "22px", color: "red", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='Numberg'>
                                    <td>
                                        <div className="col-md-1">
                                            <div className="container">
                                                9.
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container">
                                                Patrick Waweyo
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container">
                                                BSc. Computer Engineering
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container textcenter2">
                                                <div className="col-md-2">
                                                    <a href="/" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <LinkedInIcon sx={{ fontSize: "22px", color: "darkblue", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                                <div className="col-md-2">
                                                    <a href="/" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <WhatsAppIcon sx={{ fontSize: "22px", color: "teal", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                                <div className="col-md-2">
                                                    <a href="mailto:pntabu@gmail.com" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <EmailIcon sx={{ fontSize: "22px", color: "red", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='Numberg'>
                                    <td>
                                        <div className="col-md-1">
                                            <div className="container">
                                                10.
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container">
                                                Patrick Waweyo
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container">
                                                BSc. Computer Engineering
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-12">
                                            <div className="container textcenter2">
                                                <div className="col-md-2">
                                                    <a href="/" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <LinkedInIcon sx={{ fontSize: "22px", color: "darkblue", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                                <div className="col-md-2">
                                                    <a href="/" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <WhatsAppIcon sx={{ fontSize: "22px", color: "teal", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                                <div className="col-md-2">
                                                    <a href="mailto:pntabu@gmail.com" target="_blank" rel="noopener noreferrer">
                                                        <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                                                            <EmailIcon sx={{ fontSize: "22px", color: "red", }} />
                                                        </IconButton>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ResearchAssistants