import React from "react";
import Card2 from "../Images/Headers/what-we-do.jpg";
import Card1 from "../Images/Asset 2-100.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function WhatWeDo() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%"  />
        </div>
        <div class="text-on-image">
          <h4 className="bold">What We Do</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">About Us</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">What We Do</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <p className="texty">
                CIRCODU extends three fundamental services to its clientele and
                partners:
              </p>
            </div>

            <div className="col-md-8">
              <p className="texty">
                <ol className="mt-2">
                  <li>
                    <b>Consultancy Services:</b> CIRCODU boasts a wealth of
                    experience and robust capabilities to deliver consultancy
                    services, encompassing tasks such as baseline assessments,
                    project feasibility evaluations, market intelligence
                    studies, and project appraisals, among other offerings.
                  </li>
                  <p></p>
                  <li>
                    <b>Independent Monitoring and Evaluation Services:</b>{" "}
                    CIRCODU is engaged to undertake third-party monitoring and
                    evaluation tasks for domestic, commercial, and institutional
                    energy projects within Uganda and on a broader scale.
                  </li>
                  <p></p>
                  <li>
                    <b>Research Initiatives:</b> In collaboration with
                    distinguished institutions such as the University of
                    California at Berkeley, Humboldt State University, Makerere
                    University, and Stanford University, among others, CIRCODU
                    has successfully executed multiple research projects
                    concerning household energy technologies, public health, and
                    environmental domains.
                  </li>
                  <p></p>
                  <li>
                    <b>Training Programs:</b> CIRCODU possesses significant
                    expertise and a strong capacity to provide training and
                    skills development programs within communities, catering to
                    both the individuals undergoing training and the instructors
                    responsible for imparting knowledge.
                  </li>
                </ol>
              </p>
            </div>
            <div className="col-md-4">
              <div className="imagecard">
                <img
                  src={Card1}
                  alt=""
                  height="250"
                  width="100%"
                  style={{ borderRadius: "5%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default WhatWeDo;
