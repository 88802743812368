import React from "react";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import "../Components/NavigatBar.css";
import Logo from "../Images/Asset 2-100.jpg";
import { useState } from "react";

function NavigatBar() {
  const [showAboutUs, setShowAboutUs] = useState(false);
  const [showStaff, setShowStaff] = useState(false);
  const [showProjects, setShowProjects] = useState(false);
  const [showPublicity, setShowPublicity] = useState(false);
  const [showServices, setShowServices] = useState(false);

  const showAboutUsDropdown = (e) => {
    setShowAboutUs(!showAboutUs);
  };
  const hideAboutUsDropdown = (e) => {
    setShowAboutUs(false);
  };

  const showStaffDropdown = (e) => {
    setShowStaff(!showStaff);
  };
  const hideStaffDropdown = (e) => {
    setShowStaff(false);
  };

  const showProjectsDropdown = (e) => {
    setShowProjects(!showProjects);
  };
  const hideProjectsDropdown = (e) => {
    setShowProjects(false);
  };

  const showPublicityDropdown = (e) => {
    setShowPublicity(!showPublicity);
  };
  const hidePublicityDropdown = (e) => {
    setShowPublicity(false);
  };

  const showServicesDropdown = (e) => {
    setShowServices(!showServices);
  };
  const hideServicesDropdown = (e) => {
    setShowServices(false);
  };

  return (
    <section>
      <div className="container">
        <div className="row">
          <Navbar className="navba" collapseOnSelect expand="lg" fixed="top">
            <Container>
              <Navbar.Brand href="/">
                <img
                  src={Logo}
                  alt="Description of the shape"
                  style={{
                    borderTop: "0",
                    height: "auto",
                    width: "100px",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    borderRadius: "5%",
                  }}
                  onMouseEnter={(event) =>
                    (event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(0, 255, 157, 0.4)")
                  }
                  onMouseLeave={(event) =>
                    (event.target.style.boxShadow = "none")
                  }
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="m-auto">
                  <Nav.Link href="/" className="home active">
                    Home
                  </Nav.Link>
                  <NavDropdown
                    title="About Us"
                    id="collasible-nav-dropdown"
                    show={showAboutUs}
                    onMouseEnter={showAboutUsDropdown}
                    onMouseLeave={hideAboutUsDropdown}
                    className="dropdown home"
                  >
                    <NavDropdown.Item
                      href="/about-us/who-we-are"
                      className="home"
                    >
                      Who We Are
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="/about-us/what-we-do"
                      className="home"
                    >
                      What We Do
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item
                      href="/about-us/our-clients-partners"
                      className="home"
                    >
                      Clients and Partners
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    title="Our Services"
                    id="collasible-nav-dropdown"
                    show={showServices}
                    onMouseEnter={showServicesDropdown}
                    onMouseLeave={hideServicesDropdown}
                    className="dropdown home"
                  >
                    <NavDropdown.Item
                      href="/our-services/research"
                      className="home"
                    >
                      Research Projects
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="/our-services/consultancy"
                      className="home"
                    >
                      Consultancy
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="/our-services/monitoring-and-evaluation"
                      className="home"
                    >
                      Monitoring & Evaluation
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="/our-services/cookstoves-and-fuel-testing"
                      className="home"
                    >
                      Cookstoves & Fuel Testing
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item
                      href="/our-services/capacity-building"
                      className="home"
                    >
                      Capacity Building
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    title="Our Thematic Areas"
                    id="collasible-nav-dropdown"
                    show={showProjects}
                    onMouseEnter={showProjectsDropdown}
                    onMouseLeave={hideProjectsDropdown}
                    className="dropdown home"
                  >
                    <NavDropdown.Item
                      href="/our-thematic-areas/energy"
                      className="home"
                    >
                      Energy
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="/our-thematic-areas/water-and-sanitation"
                      className="home"
                    >
                      Water & Sanitation
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="/our-thematic-areas/waste-management"
                      className="home"
                    >
                      Waste Management
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="/our-thematic-areas/agricultural-management"
                      className="home"
                    >
                      Agricultural Management
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    title="The Team"
                    id="collasible-nav-dropdown"
                    show={showStaff}
                    onMouseEnter={showStaffDropdown}
                    onMouseLeave={hideStaffDropdown}
                    className="dropdown home"
                  >
                    <NavDropdown.Item
                      href="/the-team/top-management"
                      className="home"
                    >
                      Top Management
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="/the-team/the-staff-team"
                      className="home"
                    >
                      The Staff Team
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
                <Nav>
                  <NavDropdown
                    title="Publicity"
                    id="collasible-nav-dropdown"
                    show={showPublicity}
                    onMouseEnter={showPublicityDropdown}
                    onMouseLeave={hidePublicityDropdown}
                    className="dropdown home"
                  >
                    <NavDropdown.Item
                      href="/publicity/gallery"
                      className="home"
                    >
                      Photo Gallery
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="https://www.youtube.com/@CIRCODU"
                      className="home"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Video Gallery
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="/publicity/news-and-events"
                      className="home"
                    >
                      News & Events
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="/contact-us" className="home">
                    Contact Us
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </div>
    </section>
  );
}

export default NavigatBar;
