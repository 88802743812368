import './App.css';
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import NavigatBar from "./Components/NavigatBar";
import Gallery from "./Pages/Gallery";
import NewsandEvents from "./Pages/NewsandEvents";
import ClientsPartners from "./Pages/ClientsPartners";
import WhoWeAre from "./Pages/WhoWeAre";
import WhatWeDo from "./Pages/WhatWeDo";
import AgriculturalManagement from "./Pages/AgriculturalManagement";
import OurStaff from "./Pages/OurStaff";
import BoardofDirectors from "./Pages/BoardofDirectors";
import Footer from './Components/Footer';
import ResearchAssistants from "./Pages/ResearchAssistants";
import CookstoveTesting from "./Pages/CookstoveTesting";
import OurHistory from './Pages/OurHistory';
import PublicHealth from "./Pages/PublicHealth";
import SustainableEnergy from "./Pages/SustainableEnergy";
import WaterSanitation from "./Pages/WaterSanitation";
import WasteManagement from "./Pages/WasteManagement";
import CapacityBuilding from "./Pages/CapacityBuilding";
import Consultancy from "./Pages/Consultancy";
import MonitoringEvaluation from "./Pages/MonitoringEvaluation";
import Research from "./Pages/Research";
import AlexanderAtuyamba from "./Profiles/AlexanderAtuyamba";
import AllanJosephGakiza from "./Profiles/AllanJosephGakiza";
import AranAhereza from "./Profiles/AranAhereza";
import BernardByamukama from "./Profiles/BernardByamukama";
import DavisKintu from "./Profiles/DavisKintu";
import DenisMuhangi from "./Profiles/DenisMuhangi";
import EuniceKyomuhendo from "./Profiles/EuniceKyomuhendo";
import FredIsabirye from "./Profiles/FredIsabirye";
import GodfreyMuhwezi from "./Profiles/GodfreyMuhwezi";
import GoodchildIsingomaYonah from "./Profiles/GoodchildIsingomaYonah";
import HasifaNamugabo from "./Profiles/HasifaNamugabo";
import IsaacAhimbisiibwe from "./Profiles/IsaacAhimbisiibwe";
import JosephNdemereArineitwe from "./Profiles/JosephNdemereArineitwe";
import JulietKyayesimira from "./Profiles/JulietKyayesimira";
import KennethArinaitwe from "./Profiles/KennethArinaitwe";
import LivingstoneKilwa from "./Profiles/LivingstoneKilwa";
import PatriciaNyansheegu from "./Profiles/PatriciaNyansheegu";
import PatrickWaweyo from "./Profiles/PatrickWaweyo";
import CarolGabeya from "./Profiles/CarolGabeya";
import RashidSeggujja from "./Profiles/RashidSeggujja";
import LabNewsFeed1 from "./Pages/LabNewsFeed1";
import TrainingBriquetteCookstove from './Pages/TrainingBriquetteCookstove';
import TestingBioLiteCookstoves from './Pages/TestingBioLiteCookstoves';

function App() {
  return (
    <Router>
      <div>
        <NavigatBar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about-us/our-history" component={OurHistory} />
          <Route path="/contact-us" component={Contact} />
          <Route path="/publicity/gallery" component={Gallery} />
          <Route path="/publicity/news-and-events" component={NewsandEvents} />
          <Route path="/about-us/our-clients-partners" component={ClientsPartners} />
          <Route path="/about-us/who-we-are" component={WhoWeAre} />
          <Route path="/our-thematic-areas/public-health" component={PublicHealth} />
          <Route path="/our-thematic-areas/energy" component={SustainableEnergy} />
          <Route path="/our-thematic-areas/water-and-sanitation" component={WaterSanitation} />
          <Route path="/our-thematic-areas/waste-management" component={WasteManagement} />
          <Route path="/our-thematic-areas/agricultural-management" component={AgriculturalManagement} />
          <Route path="/the-team/the-staff-team" component={OurStaff} />
          <Route path="/the-team/top-management" component={BoardofDirectors} />
          <Route path="/the-team/the-research-assistants" component={ResearchAssistants} />
          <Route path="/our-services/cookstoves-and-fuel-testing" component={CookstoveTesting} />
          <Route path="/about-us/what-we-do" component={WhatWeDo} />
          <Route path="/our-services/capacity-building" component={CapacityBuilding} />
          <Route path="/our-services/consultancy" component={Consultancy} />
          <Route path="/our-services/monitoring-and-evaluation" component={MonitoringEvaluation} />
          <Route path="/our-services/research" component={Research} />
          <Route path="/alexander-atuyamba" component={AlexanderAtuyamba} />
          <Route path="/allan-joseph-gakiza" component={AllanJosephGakiza} />
          <Route path="/aran-ahereza" component={AranAhereza} />
          <Route path="/bernard-byamukama" component={BernardByamukama} />
          <Route path="/davis-kintu" component={DavisKintu} />
          <Route path="/denis-muhangi" component={DenisMuhangi} />
          <Route path="/eunice-kyomuhendo" component={EuniceKyomuhendo} />
          <Route path="/fred-isabirye" component={FredIsabirye} />
          <Route path="/godfrey-muhwezi" component={GodfreyMuhwezi} />
          <Route path="/goodchild-isingoma-yonah" component={GoodchildIsingomaYonah} />
          <Route path="/hasifa-namugabo" component={HasifaNamugabo} />
          <Route path="/isaac-ahimbisiibwe" component={IsaacAhimbisiibwe} />
          <Route path="/joseph-ndemere-arineitwe" component={JosephNdemereArineitwe} />
          <Route path="/juliet-kyayesimira" component={JulietKyayesimira} />
          <Route path="/kenneth-arinaitwe" component={KennethArinaitwe} />
          <Route path="/livingstone-kilwa" component={LivingstoneKilwa} />
          <Route path="/patricia-nyansheegu" component={PatriciaNyansheegu} />
          <Route path="/patrick-waweyo" component={PatrickWaweyo} />
          <Route path="/carol-gabeya" component={CarolGabeya} />
          <Route path="/rashid-seggujja" component={RashidSeggujja} />
          <Route path="/CIRCODU-laboratory-a-leader-in-testing-cookstoves" component={LabNewsFeed1} />
          <Route path="/Training-Services-on-Briquette-and-Cookstove-Production" component={TrainingBriquetteCookstove} />
          <Route path="/Testing-of-BioLite-Cookstoves" component={TestingBioLiteCookstoves} />

        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;