import React, { useState, useEffect } from 'react';
import Card2 from "../Images/Asset 2-100.jpg";

function WelcomeSPin({ imageSrc }) {
    const [rotation, setRotation] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRotation(prevRotation => prevRotation + 1);
    }, 60);
    return () => clearInterval(intervalId);
  }, []);

  
    return (
      <img
        src={Card2}
        alt="spinning"
        style={{transform: `rotate(${rotation}deg)`, height: 120, width: 180, borderRadius: 8 }}
      />
    );
  }

export default WelcomeSPin
