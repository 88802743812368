import React, { useState } from 'react';
import "../Components/FlipCard.scss";
import cn from "classnames";

function FlipCard({ card }) {
  const [showBack, setShowBack] = useState(false);

  function handleClick() {
    if (card.variant === "click") {
      setShowBack(!showBack);
    }
  }

  return (
    <div className="container-flex row" style={{
      display: 'flex', justifyContent: 'center', flexDirection: "row",
      flexFlow: "wrap"
    }}>
      <div
        tabIndex={card.id}
        className={cn("flip-card-outer", {
          "focus-trigger": card.variant === "focus"
        })}
        onClick={handleClick}
      >
        <div
          className={cn("flip-card-inner", {
            showBack,
            "hover-trigger": card.variant === "hover"
          })}
        >
          <div className="card front">
            <div className="card-body d-flex justify-content-center align-items-center">
              <p className="card-text fs-1 fw-bold">{card.front}</p>
            </div>
          </div>
          <div className="card back">
            <div className="card-body d-flex justify-content-center align-items-center">
              <p className="card-text fs-1 fw-bold">{card.back}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlipCard;