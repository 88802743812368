import React from "react";
import Card2 from "../Images/Headers/water.jpg";
import Card1 from "../Images/CIRCODU-BANNERS-2.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function WaterSanitation() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Water and Sanitation</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Our Thematic Areas
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Water and Sanitation
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Water and Sanitation</b>
              </h>
              <p className="texty">
                Access to clean and safe water is a basic human right
                (Sustainable Development Goal 6), and CIRCODU is committed to
                working towards achieving this goal in Uganda. The organization
                recognizes that many communities in Uganda still lack sufficient
                access to clean water and sanitation, which can lead to health
                problems and hinder economic development.
              </p>
              <p className="texty">
                To address this issue, CIRCODU is ready to work with other
                partners to engage in sustainable management of water resources
                and provide clean water and sanitation services to communities
                in need. This may include activities such as constructing wells
                and boreholes, implementing water treatment and purification
                systems, and promoting hygiene and sanitation practices. Through
                these efforts, CIRCODU aims to improve livelihoods and offer
                basic services for community development.
              </p>
            </div>
            <div className="col-md-8">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Objectives of the Water and Sanitation Thematic Area</b>
              </h>
              <p className="texty"></p>
              <p className="texty">
                <b>Overall Objective:</b> Empower communities facing
                insufficient access to clean water and adequate sanitation by
                ensuring access to safe drinking water and improved hygiene
                practices for a better health and well-being.
              </p>
              <p className="texty">
                <b>Specific Objectives:</b>
                <ol className="mt-2">
                  <li>
                    To provide safe drinking water that meets the World Health
                    Organization (WHO) guidelines from an improved water source
                    which is located on the premises, available when needed, and
                    free from faecal and priority chemical contamination.
                  </li>
                  <li className="mt-2">
                    To increase access to clean water and sanitation in
                    communities, thereby reducing the incidence of diarrhoeal
                    diseases, soil-transmitted helminthiases, and other
                    neglected tropical diseases (NTDs) that dramatically impact
                    child development, school attendance, and overall
                    productivity. This will lead to reduced healthcare costs and
                    improved economic opportunities for individuals and
                    communities.
                  </li>
                  <li className="mt-2">
                    To safeguard water quality for human health, maintain
                    healthy ecosystems, and promote sustainable economic
                    development through responsible water use and resource
                    management.
                  </li>
                  <li className="mt-2">
                    To promote hygiene behavior change through education and
                    awareness campaigns, empowering communities to adopt healthy
                    practices that complement improved water and sanitation
                    infrastructure.
                  </li>
                </ol>
              </p>
            </div>
            <div className="col-md-4">
              <div className="imagecard">
                <img
                  src={Card1}
                  alt=""
                  height="100%"
                  width="100%"
                  style={{ borderRadius: "5%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default WaterSanitation;
