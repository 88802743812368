import React from "react";
import Card2 from "../Images/Headers/team.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import "../Profiles/profileimg.css";
import Card7 from "../Images/Profiles/Denis.jpg";

function DenisMuhangi() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Dr. Denis Muhangi</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">The Team</Breadcrumb.Item>
              <Breadcrumb.Item
                className="text1"
                href="/the-team/top-management"
              >
                Top Management
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Dr. Denis Muhangi
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Dr. Denis Muhangi</b>
              </h>
              <br></br>
              <h>
                <b>Vice Chairman, Executive Council</b>
              </h>
              <br></br>
              <h className="texty">Veterinarian/ Wildlife Pathologist</h>
            </div>
            <div className="col-md-3 mt-2">
              <div className="imagecarded">
                <img
                  src={Card7}
                  alt=""
                  height="auto" width="100%"
                  style={{
                    borderRadius: "45%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                    event.target.style.transform = "scale(1.01)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </div>
            </div>
            <div className="col-md-9 mt-2">
              <p className="texty">
                Denis earned a Doctor of Philosophy Degree from Makerere
                University, where his research delved into the Epidemiology of
                African Swine Fever in Uganda, with a focus on understanding the
                factors contributing to its persistence.
              </p>
              <p className="texty">
                Between 2016 and 2018, Denis assumed the role of a post-doctoral
                fellow in the context of the SIDA-Makerere University research
                collaboration, which spanned from 2015 to 2020. During this
                period, he built upon the foundations laid by his doctoral
                research. Denis's academic journey also includes the attainment
                of a Master of Science Degree in Veterinary Pathology and a
                Bachelor’s Degree in Veterinary Medicine, both of which were
                conferred upon him by Makerere University.
              </p>
              <p className="texty">
                In addition to his academic pursuits, Denis holds a Lecturing
                position at the College of Veterinary Medicine, Animal
                Resources, and Biosecurity (COVAB) at Makerere University. He is
                an active member of both the Uganda Veterinary Association and
                the American Association of Zoo Veterinarians (AAZV). Denis
                takes pride in being an alumnus of Envirovet, an international
                ecosystem health program tailored for young biologists,
                veterinarians, and conservationists.
              </p>
              <p className="texty">
                Beyond his affiliations, Denis's research interests span a wide
                spectrum, including Wildlife Pathology and the study of Wildlife
                Diseases, encompassing Parasitology, infectious, and
                noninfectious conditions. In the realm of wildlife pathology, he
                has participated in and remains engaged in researching the
                impacts of Oil and Gas activities on fauna in the Albertine
                Graben, as well as conducting studies on mountain gorillas in
                collaboration with the Gorilla Doctors program.
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-2">
            <div className="container textcenter2">
              <div className="col-md-1">
                <a href="https://www.linkedin.com/in/denis-muhangi-69564077/" target="_blank" rel="noopener noreferrer">
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <LinkedInIcon
                      sx={{ fontSize: "22px", color: "darkblue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a href="https://twitter.com/mdenis_1234" target="_blank" rel="noopener noreferrer">
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <TwitterIcon sx={{ fontSize: "22px", color: "blue" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a href="https://www.facebook.com/muhangi.denis.3" target="_blank" rel="noopener noreferrer">
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <FacebookOutlinedIcon
                      sx={{ fontSize: "22px", color: "blue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a href="https://wa.me/256785284996" target="_blank" rel="noopener noreferrer">
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <WhatsAppIcon sx={{ fontSize: "22px", color: "teal" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="mailto:denis.muhangi@circodu.org.ug"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <EmailIcon sx={{ fontSize: "22px", color: "red" }} />
                  </IconButton>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DenisMuhangi;
