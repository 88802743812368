import React from "react";
import Card2 from "../Images/Headers/team.jpg";
import Card1 from "../Images/Profiles/Profile/Isaac Ahimbisiibwe_Profile Pic.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import "../Profiles/profileimg.css";

function IsaacAhimbisiibwe() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Mr. Isaac Ahimbisiibwe</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">The Team</Breadcrumb.Item>
              <Breadcrumb.Item
                className="text1"
                href="/the-team/the-staff-team"
              >
                The Staff Team
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Mr. Isaac Ahimbisiibwe
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Mr. Isaac Ahimbisiibwe</b>
              </h>
              <br></br>
              <h>
                <b>Research Associate</b>
              </h>
            </div>
            <div className="col-md-3 mt-2">
              <div className="imagecarded">
                <img
                  src={Card1}
                  alt=""
                  height="auto" width="100%"
                  style={{
                    borderRadius: "45%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                    event.target.style.transform = "scale(1.01)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </div>
            </div>
            <div className="col-md-9 mt-2">
              <p className="texty">
                Isaac Ahimbisiibwe's academic achievements and professional
                journey are worthy noting. He earned a Master's Degree in Gender
                Studies from Makerere University in Uganda. He further expanded
                his knowledge by completing a Post Graduate Course on "Beyond
                Income: Social Dimensions of Poverty and Participatory
                Methodologies of Investigation" at Oslo and Akershus University
                College of Applied Sciences (HiOA) in Norway. Isaac's
                foundational education includes a Bachelor of Arts in Social
                Sciences from Makerere University, with a focus on Gender
                Studies as his major and Sociology as his minor.
              </p>
              <p className="texty">
                With a career spanning over ten years, Isaac has amassed
                extensive expertise in the realms of Social Work and
                Institutional Development. His proficiencies encompass various
                domains such as Research, Report Writing, Strategic Planning,
                and Community Mobilization.
              </p>
              <p className="texty">
                Isaac's skill set extends to the digital sphere as well. He
                possesses adept Computer Skills, displaying proficiency in
                applications including Ms Excel, Ms Word, Ms PowerPoint, Ms
                Access, and Web Surfing.
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-2">
            <div className="container textcenter2">
              <div className="col-md-1">
                <a
                  href="https://www.linkedin.com/in/isaac-ahimbisiibwe-883919162/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <LinkedInIcon
                      sx={{ fontSize: "22px", color: "darkblue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="https://twitter.com/IsaacAhimbisii1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <TwitterIcon sx={{ fontSize: "22px", color: "blue" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="https://m.facebook.com/people/Isaac-Ahimbisiibwe/100010181948317/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <FacebookOutlinedIcon
                      sx={{ fontSize: "22px", color: "blue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="https://wa.me/256752814376"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <WhatsAppIcon sx={{ fontSize: "22px", color: "teal" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="mailto:isaac.ahimbisiibwe@circodu.org.ug"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <EmailIcon sx={{ fontSize: "22px", color: "red" }} />
                  </IconButton>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default IsaacAhimbisiibwe;
