import React from "react";
import Card2 from "../Images/Headers/team.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import "../Profiles/profileimg.css";
import Card1 from "../Images/Profiles/Profile/Alex.jpg";

function AlexanderAtuyamba() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Mr. Alexander Atuyamba</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">The Team</Breadcrumb.Item>
              <Breadcrumb.Item
                className="text1"
                href="/the-team/top-management"
              >
                Top Management
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Mr. Alexander Atuyamba
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Mr. Alexander Atuyamba</b>
              </h>
              <br></br>
              <h>
                <b>Director</b>
              </h>
              <br></br>
              <h className="texty">
                Agro Ecological Farming Systems Specialist
              </h>
            </div>
            <div className="col-md-3 mt-2">
              <div className="imagecarded">
                <img
                  src={Card1}
                  alt=""
                  height="auto" width="100%" 
                  style={{
                    borderRadius: "45%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                    event.target.style.transform = "scale(1.01)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </div>
            </div>
            <div className="col-md-9 mt-2">
              <p className="texty">
                Alexander is a qualified professional with a Master's Degree in
                Agroecology and a Bachelor's degree in Organic Agriculture, both
                earned from Uganda Martyrs University. Additionally, he holds a
                Diploma in Forestry from Nyabyeya Forestry College Masindi,
                Uganda.
              </p>
              <p className="texty">
                Presently, Alexander serves as a Director and Research
                Consultant at the Center for Integrated Research and Community
                Development Uganda (CIRCODU), having been in this role for
                approximately 7 years. His primary focus areas encompass
                Forestry, Energy, and Agriculture.
              </p>
              <p className="texty">
                In his capacity, Alexander efficiently coordinates
                organizational activities in the realm of research and
                consultancies on a daily basis. He leads and supervises a team
                of 40 technical staff members, comprising both full-time and
                part-time personnel.
              </p>
              <p className="texty">
                Moreover, Alexander boasts a rich professional background
                spanning over 8 years (2005-2012) during which he collaborated
                with the Ministry of Water and Environment as a Forest
                Plantations Officer. This engagement was under the purview of a
                Commercial Forestry Project (SPGS) funded by the European Union
                and Norwegian entities. His role within this project exposed him
                to working intricately with Government Projects and the Private
                sector, involving tasks such as devising policy guidelines and
                crafting technical and financial proposals and reports. The
                consequential outcome of this project was the cultivation of a
                robust culture of tree growing in Uganda, with over 50,000
                hectares planted during that period by around 300 private tree
                growers. An early career milestone for Alexander was
                co-authoring Uganda's inaugural tree planting guidelines in
                2004.
              </p>
              <p className="texty">
                Preceding this, from 2003 to 2005, Alexander embarked on his
                initial agricultural venture, engaging with farmers in Kabale
                district Local Government. In this capacity, he served as a
                Service Provider with the National Agricultural Advisory
                Services (NAADS) for a span of 2 years. During this formative
                period, Alexander gained substantial experience collaborating on
                local Government Projects and interacting directly with
                community-based farmers. His primary responsibilities
                encompassed offering guidance and disseminating knowledge and
                skills pertaining to enhanced farming techniques and
                agricultural development. Furthermore, he was tasked with
                reporting to the Sub-County Farmers Forum Executive Committee,
                delivering insights on agricultural performance in general, as
                well as the progress of farmers' groups within his operational
                areas.
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-2">
            <div className="container textcenter2">
              <div className="col-md-1">
                <a
                  href="https://www.linkedin.com/in/atuyamba-alex-43b18931/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <LinkedInIcon
                      sx={{ fontSize: "22px", color: "darkblue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="https://twitter.com/AtuyambaAlex"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <TwitterIcon sx={{ fontSize: "22px", color: "blue" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a href="/" target="_blank" rel="noopener noreferrer">
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <FacebookOutlinedIcon
                      sx={{ fontSize: "22px", color: "blue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="https://wa.me/256783430017"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <WhatsAppIcon sx={{ fontSize: "22px", color: "teal" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="mailto:alex.atuyamba@circodu.org.ug"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <EmailIcon sx={{ fontSize: "22px", color: "red" }} />
                  </IconButton>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AlexanderAtuyamba;
