import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../Components/CardOwl.css";
import CardOwlComp1 from "../Components/CardOwlComp1";
// import CardOwlComp2 from "../Components/CardOwlComp2";
import CardOwlComp3 from "../Components/CardOwlComp3";
import CardOwlComp4 from "../Components/CardOwlComp4";
import CardOwlComp5 from "../Components/CardOwlComp5";

export class CardOwl extends Component {
  render() {
    return (
      <div class="container-fluid">
        <OwlCarousel
          items={3}
          className="owl-theme owler"
          loop
          autoplay="true"
          autoplayTimeout="18000"
        >
          <div>
            <CardOwlComp1 />
          </div>
          <div>
            <CardOwlComp3 />
          </div>
          <div>
            <CardOwlComp4 />
          </div>
          <div>
            <CardOwlComp5 />
          </div>
        </OwlCarousel>
      </div>
    );
  }
}

export default CardOwl;
