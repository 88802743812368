import React from "react";
import Card2 from "../Images/Headers/team.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import "../Profiles/profileimg.css";
import Card3 from "../Images/Profiles/Profile/Allan.jpg";

function AllanJosephGakiza() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Mr. Allan Joseph Gakiza</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">The Team</Breadcrumb.Item>
              <Breadcrumb.Item
                className="text1"
                href="/the-team/the-staff-team"
              >
                The Staff Team
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Mr. Allan Joseph Gakiza
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Mr. Allan Joseph Gakiza</b>
              </h>
              <br></br>
              <h>
                <b>Accountant</b>
              </h>
            </div>
            <div className="col-md-3 mt-2">
              <div className="imagecarded">
                <img
                  src={Card3}
                  alt=""
                  height="auto" width="100%" 
                  style={{
                    borderRadius: "45%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                    event.target.style.transform = "scale(1.01)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </div>
            </div>
            <div className="col-md-9 mt-2">
              <p className="texty">
                Gakiza Allan Joseph brings a wealth of expertise as a seasoned
                accountant, boasting a distinguished track record of over 10
                years within the professional landscape. Armed with a Bachelor
                of Commerce degree from Makerere University, Allan has honed
                specialized skills across diverse realms within the field of
                accounting. These encompass pivotal areas like tax preparation,
                meticulous bookkeeping, insightful financial analysis, and
                strategic budgeting, among others.
              </p>
              <p className="texty">
                Demonstrating an unwavering commitment to excellence, Gakiza
                Allan Joseph collaborates closely with the CIRCODU team. By
                immersing themselves in the organizational dynamics, they gain
                an in-depth understanding of the distinct financial requisites
                of the institution. This knowledge forms the foundation for
                delivering tailored solutions and expert guidance, all aimed at
                driving CIRCODU's goals and objectives to successful
                realization.
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-2">
            <div className="container textcenter2">
              <div className="col-md-1">
                <a
                  href="https://ug.linkedin.com/in/gakiza-allan-joseph-89b3a8104"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <LinkedInIcon
                      sx={{ fontSize: "22px", color: "darkblue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a href="https://twitter.com/CIRCODU1" target="_blank" rel="noopener noreferrer">
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <TwitterIcon sx={{ fontSize: "22px", color: "blue" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a href="https://www.facebook.com/Circodu/" target="_blank" rel="noopener noreferrer">
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <FacebookOutlinedIcon
                      sx={{ fontSize: "22px", color: "blue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="https://wa.me/256704674985"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <WhatsAppIcon sx={{ fontSize: "22px", color: "teal" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="mailto:allan.gakiza@circodu.org.ug"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <EmailIcon sx={{ fontSize: "22px", color: "red" }} />
                  </IconButton>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AllanJosephGakiza;
