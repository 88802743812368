import React from "react";
import Card2 from "../Images/Headers/trainings.jpg";
import Card1 from "../Images/Asset 2-100.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function CapacityBuilding() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Capacity Building</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">Our Services</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Capacity Building
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Capacity Building</b>
              </h>
              <p className="texty">
                In conjunction with its various other undertakings, CIRCODU has
                expanded its capabilities to offer training programs. The
                organization has prioritized the establishment of
                community-based training initiatives aimed at empowering
                individuals and collectives to create alternative,
                environmentally friendly energy sources for both cooking and
                generating income, notably exemplified by the production of
                bio-briquettes.
              </p>
            </div>
            <div className="col-md-4">
              <div className="imagecard">
                <img
                  src={Card1}
                  alt=""
                  height="250" 
                  width="100%"
                  style={{ borderRadius: "5%" }}
                />
              </div>
            </div>
            <div className="col-md-8 mt-2">
              <p className="texty"></p>
              <p className="texty"></p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CapacityBuilding;
