import React from "react";
import Card2 from "../Images/Headers/agriculture.jpg";
import Card1 from "../Images/CIRCODU-BANNERS-5.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function AgriculturalManagement() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Agricultural Management</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Our Thematic Areas
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Agricultural Management
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Agricultural Management</b>
              </h>
              <p className="texty">
                Agriculture is a critical sector for economic growth, food
                security, and poverty reduction in Uganda. CIRCODU recognizes
                the need to promote sustainable agricultural management
                practices to improve agricultural productivity, enhance food
                security, and promote environmental sustainability. The
                organization aims to partner with other stakeholders to promote
                the adoption of sustainable agricultural practices, such as
                conservation agriculture, agroforestry, and integrated pest
                management, to increase agricultural productivity while
                minimizing the environmental impact of agriculture.
              </p>
              <p className="texty">
                CIRCODU also seeks to promote sustainable land management
                practices, such as land-use planning, soil conservation, and
                watershed management, to protect natural resources and promote
                environmental sustainability. Through these efforts, CIRCODU
                aims to contribute to the achievement of Sustainable Development
                Goal 2, which seeks to end hunger, achieve food security and
                improved nutrition, and promote sustainable agriculture.
              </p>
            </div>
            <div className="col-md-8">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Objectives of the Agricultural Management Thematic Area</b>
              </h>
              <p className="texty"></p>
              <p className="texty"></p>
            </div>
            <div className="col-md-4">
              <div className="imagecard">
                <img
                  src={Card1}
                  alt=""
                  height="100%"
                  width="100%"
                  style={{ borderRadius: "5%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AgriculturalManagement;
