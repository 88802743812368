import * as React from "react";
import "../Components/CardOwl.css";
import Box from "@mui/material/Box";
import "../Components/Card.css";
import { Link } from "react-router-dom";
import FlipCard from "../Components/FlipCard";
import Card1 from "../Images/CIRCODU-BANNERS.jpg";

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const cards = [
  {
    id: "1",
    variant: "hover",
    front: (
      <div>
        <img
          src={Card1}
          alt=""
          style={{
            borderRadius: "5%",
            boxShadow: "none",
            transition: "box-shadow 0.3s ease-in-out",
          }}
        />
      </div>
    ),
    back: (
      <div className="cardp">
        <h5 className="cardh text-center" style={{ color: "#008250" }}>
          Energy
        </h5>
        <div className="underline-card mx-auto"></div>
        <p className="cardp">
          Access to sustainable energy is critical for economic growth,
          environmental sustainability, and poverty reduction. CIRCODU
          recognizes the need to promote sustainable energy resource use in
          Uganda and is committed to working towards achieving this goal. The
          organization aims to partner with other stakeholders to promote the
          adoption of renewable energy technologies, such as solar, wind,
          biomass and hydropower, to replace traditional energy ...
        </p>
        <div className="text-center">
          <Link
            to="/our-thematic-areas/sustainable-energy"
            className="btn btn-outline-success"
            onClick={scrollToTop}
          >
            Read More
          </Link>
        </div>
      </div>
    ),
  },
];

export default function MultiActionAreaCard() {
  return (
    <section className="section">
      <div className="container-fluid">
        <Box sx={{ width: "100%", bgcolor: "white" }} centered>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 d-flex flex-column flex-md-row justify-content-around align-items-center">
                {cards.map((card) => (
                  <FlipCard key={card.id} card={card} />
                ))}
              </div>
            </div>
          </div>
        </Box>
      </div>
    </section>
  );
}
