import * as React from "react";
import "../Components/CardOwl.css";
import Box from "@mui/material/Box";
import "../Components/Card.css";
import { Link } from "react-router-dom";
import FlipCard from "../Components/FlipCard";
import Card3 from "../Images/CIRCODU-BANNERS-2.jpg";

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const cards = [
  {
    id: "3",
    variant: "hover",
    front: (
      <div>
        <img
          src={Card3}
          alt=""
          style={{
            borderRadius: "5%",
            boxShadow: "none",
            transition: "box-shadow 0.3s ease-in-out",
          }}
        />
      </div>
    ),
    back: (
      <div className="cardp">
        <h5 className="cardh text-center" style={{ color: "#008250" }}>
          Water and Sanitation
        </h5>
        <div className="underline-card mx-auto"></div>
        <p className="cardp">
          Access to clean and safe water is a basic human right (Sustainable
          Development Goal 6), and CIRCODU is committed to working towards
          achieving this goal in Uganda. The organization recognizes that many
          communities in Uganda still lack sufficient access to clean water and
          sanitation, which can lead to health problems and hinder economic
          development. To address this issue, CIRCODU is ready to work with
          other partners to engage in sustainable manage ...
        </p>
        <div className="text-center">
          <Link
            to="/our-thematic-areas/water-and-sanitation"
            className="btn btn-outline-success"
            onClick={scrollToTop}
          >
            Read More
          </Link>
        </div>
      </div>
    ),
  },
];

export default function MultiActionAreaCard() {
  return (
    <section className="section">
      <div className="container-fluid">
        <Box sx={{ width: "100%", bgcolor: "white" }} centered>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 d-flex flex-column flex-md-row justify-content-around align-items-center">
                {cards.map((card) => (
                  <FlipCard key={card.id} card={card} />
                ))}
              </div>
            </div>
          </div>
        </Box>
      </div>
    </section>
  );
}
