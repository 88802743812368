import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../Components/Owl.css';
import OwlComp1 from "../Components/OwlComp1";
import OwlComp2 from "../Components/OwlComp2";
import OwlComp3 from "../Components/OwlComp3";
import OwlComp4 from "../Components/OwlComp4";
import OwlComp5 from "../Components/OwlComp5";
import OwlComp6 from "../Components/OwlComp6";
import OwlComp7 from "../Components/OwlComp7";
import OwlComp8 from "../Components/OwlComp8";
import OwlComp9 from "../Components/OwlComp9";
import OwlComp10 from "../Components/OwlComp10";
import OwlComp11 from "../Components/OwlComp11";
import OwlComp12 from "../Components/OwlComp12";
import OwlComp13 from "../Components/OwlComp13";
import OwlComp14 from "../Components/OwlComp14";
import OwlComp15 from "../Components/OwlComp15";
import OwlComp16 from "../Components/OwlComp16";
import OwlComp17 from "../Components/OwlComp17";
import OwlComp18 from "../Components/OwlComp18";
import OwlComp19 from "../Components/OwlComp19";
import OwlComp20 from "../Components/OwlComp20";
import OwlComp21 from "../Components/OwlComp21";
import OwlComp22 from "../Components/OwlComp22";
import OwlComp23 from "../Components/OwlComp23";
import OwlComp24 from "../Components/OwlComp24";
import OwlComp25 from "../Components/OwlComp25";
import OwlComp26 from "../Components/OwlComp26";
import OwlComp27 from "../Components/OwlComp27";
import OwlComp28 from "../Components/OwlComp28";
import OwlComp29 from "../Components/OwlComp29";
import OwlComp30 from "../Components/OwlComp30";
import OwlComp31 from "../Components/OwlComp31";
import OwlComp32 from "../Components/OwlComp32";
import OwlComp33 from "../Components/OwlComp33";
import OwlComp34 from "../Components/OwlComp34";
import OwlComp35 from "../Components/OwlComp35";
import OwlComp36 from "../Components/OwlComp36";
import OwlComp37 from "../Components/OwlComp37";
import OwlComp38 from "../Components/OwlComp38";
import OwlComp39 from "../Components/OwlComp39";
import OwlComp40 from "../Components/OwlComp40";
import OwlComp41 from "../Components/OwlComp41";
import OwlComp42 from "../Components/OwlComp42";
import OwlComp43 from "../Components/OwlComp43";
import OwlComp44 from "../Components/OwlComp44";
import OwlComp45 from "../Components/OwlComp45";
import OwlComp46 from "../Components/OwlComp46";
import OwlComp47 from "../Components/OwlComp47";
import OwlComp48 from "../Components/OwlComp48";
import OwlComp49 from "../Components/OwlComp49";

export class Owl extends Component {
  render() {
    return (
      <div class='container' >
        <OwlCarousel items={6}
          className="owl-theme"
          loop
          autoplay="true"
          autoplayTimeout="7500"
          margin={18} >
          <div ><OwlComp1 /></div>
          <div ><OwlComp2 /></div>
          <div ><OwlComp3 /></div>
          <div ><OwlComp4 /></div>
          <div ><OwlComp5 /></div>
          <div ><OwlComp6 /></div>
          <div ><OwlComp7 /></div>
          <div ><OwlComp8 /></div>
          <div ><OwlComp9 /></div>
          <div ><OwlComp10 /></div>
          <div ><OwlComp11 /></div>
          <div ><OwlComp12 /></div>
          <div ><OwlComp13 /></div>
          <div ><OwlComp14 /></div>
          <div ><OwlComp15 /></div>
          <div ><OwlComp16 /></div>
          <div ><OwlComp17 /></div>
          <div ><OwlComp18 /></div>
          <div ><OwlComp19 /></div>
          <div ><OwlComp20 /></div>
          <div ><OwlComp21 /></div>
          <div ><OwlComp22 /></div>
          <div ><OwlComp23 /></div>
          <div ><OwlComp24 /></div>
          <div ><OwlComp25 /></div>
          <div ><OwlComp26 /></div>
          <div ><OwlComp27 /></div>
          <div ><OwlComp28 /></div>
          <div ><OwlComp29 /></div>
          <div ><OwlComp30 /></div>
          <div ><OwlComp31 /></div>
          <div ><OwlComp32 /></div>
          <div ><OwlComp33 /></div>
          <div ><OwlComp34 /></div>
          <div ><OwlComp35 /></div>
          <div ><OwlComp36 /></div>
          <div ><OwlComp37 /></div>
          <div ><OwlComp38 /></div>
          <div ><OwlComp39 /></div>
          <div ><OwlComp40 /></div>
          <div ><OwlComp41 /></div>
          <div ><OwlComp42 /></div>
          <div ><OwlComp43 /></div>
          <div ><OwlComp44 /></div>
          <div ><OwlComp45 /></div>
          <div ><OwlComp46 /></div>
          <div ><OwlComp47 /></div>
          <div ><OwlComp48 /></div>
          <div ><OwlComp49 /></div>
        </OwlCarousel>
      </div>
    )
  }
}

export default Owl