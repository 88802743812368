import React from "react";
import Card2 from "../Images/Headers/team.jpg";
import "../Pages/OurHistory.css";
import "../Profiles/profileimg.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import Card11 from "../Images/Profiles/Profile/Patrick (2).jpg";

function PatrickWaweyo() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Mr. Patrick Waweyo</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">The Team</Breadcrumb.Item>
              <Breadcrumb.Item
                className="text1"
                href="/the-team/the-staff-team"
              >
                The Staff Team
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Mr. Patrick Waweyo
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Mr. Patrick Waweyo</b>
              </h>
              <br></br>
              <h>
                <b>IT Officer/ Data Manager</b>
              </h>
            </div>
            <div className="col-md-3 mt-2">
              <div className="imagecarded">
                <img
                  src={Card11}
                  alt=""
                  height="auto" width="100%"
                  style={{
                    borderRadius: "45%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                    event.target.style.transform = "scale(1.01)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </div>
            </div>
            <div className="col-md-9 mt-2">
              <p className="texty">
                Patrick Waweyo is a computer engineer whose career journey has
                spanned education, technology, and environmental advocacy. He
                holds a Bachelor's degree in Computer Engineering from Makerere
                University in Kampala, Uganda. His commitment to fostering
                learning and innovation is evident through his multifaceted
                professional experiences.
              </p>
              <p className="texty">
                Commencing his career as a lecturer at the Management Training
                and Advisory Centre Nakawa, Uganda (MTAC), Patrick played a
                pivotal role in equipping students with the skills needed for
                enterprise development. As a member of the Information and
                Communication Technology (ICT) department, he not only imparted
                technical expertise but also mentored and guided aspiring
                computer professionals, nurturing their talents and instilling a
                foundation for their future accomplishments.
              </p>
              <p className="texty">
                As the IT officer and Data Manager at CIRCODU, Patrick has
                showcased his adeptness in information systems, data & database
                management, data analysis, and website design & upkeep. Besides
                all that, he is also actively engaged in research and projects
                geared towards promoting indoor air pollution reduction and the
                promotion of eco-friendly practices and technologies, such as
                improved cookstoves and biogas solutions, underscores his
                dedication and commitment to a healthier and more sustainable
                future.
              </p>
              <p className="texty">
                Patrick's overarching passion for capacity building remains a
                driving force. He eagerly embraces new opportunities that enable
                him to further enrich his skill set and knowledge. Armed with a
                diverse background, Patrick is poised to make meaningful
                contributions across various domains, including computing and
                technology, academia, and non-profit work. His unique blend of
                experience and dedication positions him as a catalyst for
                positive change within society, embodying the values of
                education, innovation, and environmental stewardship.
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-2">
            <div className="container textcenter2">
              <div className="col-md-1">
                <a
                  href="https://www.linkedin.com/in/patrickwaweyo/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <LinkedInIcon
                      sx={{ fontSize: "22px", color: "darkblue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="https://twitter.com/NtabuPaddy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <TwitterIcon sx={{ fontSize: "22px", color: "blue" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a href="https://www.facebook.com/Circodu/" target="_blank" rel="noopener noreferrer">
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <FacebookOutlinedIcon
                      sx={{ fontSize: "22px", color: "blue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="https://wa.me/256705807536"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <WhatsAppIcon sx={{ fontSize: "22px", color: "teal" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="mailto:patrick.waweyo@circodu.org.ug"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <EmailIcon sx={{ fontSize: "22px", color: "red" }} />
                  </IconButton>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PatrickWaweyo;
