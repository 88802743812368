import React from "react";
import Card2 from "../Images/Headers/team.jpg";
import "../Pages/OurHistory.css";
import "../Profiles/profileimg.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import Card13 from "../Images/Profiles/Profile/Rashid.jpg";

function RashidSeggujja() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Mr. Rashid Seggujja</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">The Team</Breadcrumb.Item>
              <Breadcrumb.Item
                className="text1"
                href="/the-team/the-staff-team"
              >
                The Staff Team
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Mr. Rashid Seggujja
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Mr. Rashid Seggujja</b>
              </h>
              <br></br>
              <h>
                <b>Laboratory Technical Manager</b>
              </h>
            </div>
            <div className="col-md-3 mt-2">
              <div className="imagecarded">
                <img
                  src={Card13}
                  alt=""
                  height="auto" width="100%"
                  style={{
                    borderRadius: "45%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                    event.target.style.transform = "scale(1.01)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </div>
            </div>
            <div className="col-md-9 mt-2">
              <p className="texty">
                Rashid holds a Bachelor's Degree in Industrial and
                Organizational Psychology, a credential he earned from Makerere
                University. His educational journey is further enriched by a
                multitude of specialized certificates that highlight his
                commitment to professional growth.
              </p>
              <p className="texty">
                Among his accomplishments, Rashid has secured a certificate in
                the Implementation of ISO/IEC 17025:2017, which underscores his
                proficiency in maintaining the competence of testing and
                calibration laboratories. He has also obtained a Certificate in
                the Water Boiling Test Version 4.2.3, signifying his expertise
                in evaluating cookstove emissions and efficiency within
                controlled laboratory settings. Demonstrating his proficiency,
                Rashid holds a Certificate in the Controlled Cooking Test
                (C.C.T) Version 2.0, complemented by a Certificate in CIRCODU
                Emissions Testing methods and protocols. He is also certified in
                the testing of stoves using Uganda Standard US 761, showcasing
                his adherence to established industry benchmarks. Further
                substantiating his capabilities, Rashid possesses a Certificate
                in conducting internal Audits based on ISO:9001 and ISO/IEC
                17025. Additionally, he has earned recognition for his
                participation and innovation within the IDDS Cookstoves 2016
                initiative. His commitment to quality management is affirmed by
                a Certificate in Quality Management System based on ISO/IEC
                17025:2017, endorsed by the Uganda National Bureau of Standards
                (UNBS).
              </p>
              <p className="texty">
                Rashid's professional trajectory encompasses valuable roles at
                CIRCODU. He served as a Laboratory Technician from 2015 to 2019,
                contributing significantly to the organization's endeavors.
                Presently, Rashid holds the esteemed position of Technical
                Manager for CIRCODU's Cookstove Laboratory, a testament to his
                evolving responsibilities and leadership within the
                organization.
              </p>
              <p className="texty">
                His character is characterized by focus, intelligence, and an
                unwavering commitment to excellence. Rashid's interpersonal
                skills are exemplified through his adeptness in multicultural
                settings, where he thrives. His remarkable communication skills,
                both written and oral, underscore his ability to effectively
                convey ideas and collaborate with diverse stakeholders.
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-2">
            <div className="container textcenter2">
              <div className="col-md-1">
                <a href="https://ug.linkedin.com/company/centre-for-integrated-research-and-community-development-uganda-circodu" target="_blank" rel="noopener noreferrer">
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <LinkedInIcon
                      sx={{ fontSize: "22px", color: "darkblue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a href="https://twitter.com/CIRCODU1" target="_blank" rel="noopener noreferrer">
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <TwitterIcon sx={{ fontSize: "22px", color: "blue" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a href="https://www.facebook.com/rasheed.seggujja" target="_blank" rel="noopener noreferrer">
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <FacebookOutlinedIcon
                      sx={{ fontSize: "22px", color: "blue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a href="https://wa.me/256701447940" target="_blank" rel="noopener noreferrer">
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <WhatsAppIcon sx={{ fontSize: "22px", color: "teal" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="mailto:rashid.seggujja@circodu.org.ug"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <EmailIcon sx={{ fontSize: "22px", color: "red" }} />
                  </IconButton>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default RashidSeggujja;
