import React from "react";
import Card2 from "../Images/Headers/public-health.jpg";
import Card1 from "../Images/CIRCODU-BANNERS-3.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function PublicHealth() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Public Health</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Our Thematic Areas
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">Public Health</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Public Health</b>
              </h>
              <p className="texty">
                Public health is a crucial aspect of sustainable development,
                and CIRCODU recognizes the need to improve public health
                outcomes in Uganda. The organization is committed to working
                towards achieving this goal by partnering with other
                stakeholders to implement interventions that promote health and
                wellbeing. This may include activities such as providing access
                to healthcare services, promoting healthy lifestyle behaviors,
                advocating for policies that promote public health, and
                addressing the root causes of poor health outcomes, such as
                poverty and inequality. Through these efforts, CIRCODU aims to
                improve the overall health status of communities and promote
                sustainable development.
              </p>
            </div>
            <div className="col-md-4">
              <div className="imagecard">
                <img
                  src={Card1}
                  alt=""
                  height="100%"
                  width="100%"
                  style={{ borderRadius: "5%" }}
                />
              </div>
            </div>
            <div className="col-md-8">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Objectives of the Public Health Thematic Area</b>
              </h>
              <p className="texty"></p>
              <p className="texty"></p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PublicHealth;
