import React from "react";
import Card2 from "../Images/Headers/team.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import "../Profiles/profileimg.css";
import Card9 from "../Images/Profiles/Profile/Livingstone.jpg";

function LivingstoneKilwa() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Mr. Livingstone Kilwa</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">The Team</Breadcrumb.Item>
              <Breadcrumb.Item
                className="text1"
                href="/the-team/the-staff-team"
              >
                The Staff Team
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Mr. Livingstone Kilwa
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Mr. Livingstone Kilwa</b>
              </h>
              <br></br>
              <h>
                <b>Laboratory Technician</b>
              </h>
            </div>
            <div className="col-md-3">
              <div className="imagecarded">
                <img
                  src={Card9}
                  alt=""
                  height="auto" width="100%"
                  style={{
                    borderRadius: "45%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                    event.target.style.transform = "scale(1.01)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </div>
            </div>
            <div className="col-md-9 mt-2">
              <p className="texty">
                Kilwa Livingstone holds a Bachelor's Degree in Development
                Studies from Makerere University in Uganda. His educational
                foundation is fortified by an array of valuable certificates
                that underscore his commitment to skill acquisition and
                practical expertise.
              </p>
              <p className="texty">
                Livingstone's qualifications include a Certificate in Community
                Business Development from the Uganda National Entrepreneurship
                Development Institute (UNEDI), affirming his proficiency in
                fostering community-driven business initiatives. He has also
                acquired a Certificate in Laboratory Management Systems, a
                testament to his adeptness in overseeing the intricate aspects
                of laboratory operations.
              </p>
              <p className="texty">
                With a seasoned career spanning over three years, Livingstone
                has cultivated a robust expertise in Emissions and Air Quality
                monitoring. His competence is fortified by certificates in key
                methodologies, including the Water Boiling Test Version 4.2.3,
                which demonstrates his prowess in assessing emissions and
                efficiency within controlled laboratory settings. He is also
                certified in the Controlled Cooking Test (C.C.T), further
                attesting to his comprehensive grasp of testing methodologies.
                Moreover, Livingstone holds a Certificate in Emissions Testing
                Methods and Protocols, underpinning his meticulous approach to
                data collection and analysis. His proficiency extends to the
                testing of stoves using the Uganda Standard US 761, showcasing
                his commitment to adhering to industry benchmarks.
              </p>
              <p className="texty">
                In addition to his technical achievements, Livingstone has made
                impactful contributions in various roles. He previously served
                as a Community Business Development Advisor at The Uganda
                National Entrepreneurship Development Institute, where his
                insights were pivotal in fostering sustainable community-based
                businesses. His engagement further expanded as he took on the
                role of a Community Liaison Officer with Bugaya Area Cooperative
                Marketing Enterprise, demonstrating his commitment to effective
                community engagement and collaboration.
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-2">
            <div className="container textcenter2">
              <div className="col-md-1">
                <a href="https://www.linkedin.com/in/kilwa-livingstone-659436180/" target="_blank" rel="noopener noreferrer">
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <LinkedInIcon
                      sx={{ fontSize: "22px", color: "darkblue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a href="https://twitter.com/Stone8924" target="_blank" rel="noopener noreferrer">
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <TwitterIcon sx={{ fontSize: "22px", color: "blue" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a href="https://www.facebook.com/kilwa.livingstone/" target="_blank" rel="noopener noreferrer">
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <FacebookOutlinedIcon
                      sx={{ fontSize: "22px", color: "blue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a href="https://wa.me/256706901771" target="_blank" rel="noopener noreferrer">
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <WhatsAppIcon sx={{ fontSize: "22px", color: "teal" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="mailto:kilwa.livingstone@circodu.org.ug"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <EmailIcon sx={{ fontSize: "22px", color: "red" }} />
                  </IconButton>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default LivingstoneKilwa;
