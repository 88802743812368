import React from "react";
import Card2 from "../Images/Headers/team.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import "../Profiles/profileimg.css";
import Card5 from "../Images/Profiles/Profile/Davis.jpg";

function DavisKintu() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Mr. Davis Kintu</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">The Team</Breadcrumb.Item>
              <Breadcrumb.Item
                className="text1"
                href="/the-team/the-staff-team"
              >
                The Staff Team
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Mr. Davis Kintu
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Mr. Davis Kintu</b>
              </h>
              <br></br>
              <h>
                <b>Senior Laboratory Technician</b>
              </h>
            </div>
            <div className="col-md-3 mt-2">
              <div className="imagecarded">
                <img
                  src={Card5}
                  alt=""
                  height="auto" width="100%"
                  style={{
                    borderRadius: "45%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                    event.target.style.transform = "scale(1.01)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </div>
            </div>
            <div className="col-md-9 mt-2">
              <p className="texty">
                Davis Kintu graduated from Makerere University with a Bachelor's
                degree in Environmental Management. His educational foundation
                is fortified by a series of professional certifications that
                showcase his proficiency in various domains. These credentials
                include certificates in the Implementation of ISO/IEC
                17025:2017, which pertains to the competence of testing and
                calibration laboratories, as well as specialized training in
                methodologies such as the Water Boiling Test Version 4.2.3
                (focused on cookstove emissions and efficiency in controlled lab
                environments), Controlled Cooking Test (C.C.T) Version 2.0, and
                CIRCODU Emissions Testing methods and protocols. He also holds
                certificates in stove testing using the Uganda Standard US 761,
                conducting internal audits based on ISO 9001 and ISO/IEC 17025,
                and Quality Management System based on ISO/IEC 17025:2017 from
                the Uganda National Bureau of Standards (UNBS).
              </p>
              <p className="texty">
                Davis has an extensive professional background that encompasses
                diverse roles. He previously served as a Research Assistant and
                Laboratory Technician at CIRCODU, where he honed his expertise.
                Beyond CIRCODU, his experience includes a tenure as a Customer
                Care Officer at Impact Water and a role as a Research Assistant
                at Impact Carbon.
              </p>
              <p className="texty">
                Presently, Davis occupies the position of Senior Laboratory
                Technician at CIRCODU. His role is underscored by a
                comprehensive skill set that spans various testing
                methodologies, including WBTs, CCTs, safety assessments,
                durability tests, emissions evaluations, and kitchen performance
                tests. Beyond technical proficiency, he demonstrates adeptness
                in ensuring compliance with health and safety practices within
                the work environment. Moreover, Davis possesses the acumen to
                conduct Environmental Impact Assessments and Monitoring for
                developmental projects. His skill set extends to proficiently
                utilizing computer tools including Microsoft Word, Microsoft
                Excel, PowerPoint, Epidata, and SPSS. He is well-versed in
                employing diverse data collection techniques, encompassing Focus
                Group Discussions (FGDs), questionnaires, and key informant
                interviews. Impressively, he can harness technology for data
                collection through ODK forms and Taro works on smartphones.
                Davis' analytical prowess spans both quantitative and
                qualitative data, underpinning his capacity for incisive
                insights. His communication abilities are underscored by
                excellence in report writing and presentation, all of which are
                enhanced by his exceptional interpersonal skills.
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-2">
            <div className="container textcenter2">
              <div className="col-md-1">
                <a
                  href="https://ug.linkedin.com/in/kintu-davis-1744286b/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <LinkedInIcon
                      sx={{ fontSize: "22px", color: "darkblue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a href="https://twitter.com/CIRCODU1" target="_blank" rel="noopener noreferrer">
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <TwitterIcon sx={{ fontSize: "22px", color: "blue" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a href="https://www.facebook.com/muwanguzi.davis.12/" target="_blank" rel="noopener noreferrer">
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <FacebookOutlinedIcon
                      sx={{ fontSize: "22px", color: "blue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="https://wa.me/256700127623"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <WhatsAppIcon sx={{ fontSize: "22px", color: "teal" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="mailto:davis.kintu@circodu.org.ug"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <EmailIcon sx={{ fontSize: "22px", color: "red" }} />
                  </IconButton>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DavisKintu;
