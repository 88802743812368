import React from "react";
import Card2 from "../Images/Headers/energy.jpg";
import Card1 from "../Images/CIRCODU-BANNERS.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function SustainableEnergy() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Energy</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Our Thematic Areas
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">Energy</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Energy</b>
              </h>
              <p className="texty">
                Access to sustainable energy is critical for economic growth,
                environmental sustainability, and poverty reduction. CIRCODU
                recognizes the need to promote sustainable energy resource use
                in Uganda and is committed to working towards achieving this
                goal. The organization aims to partner with other stakeholders
                to promote the adoption of renewable energy technologies, such
                as solar, wind, biomass and hydropower, to replace traditional
                energy sources that are harmful to the environment and
                contribute to climate change.
              </p>
              <p className="texty">
                CIRCODU also seeks to promote energy efficiency by promoting the
                use of energy-efficient technologies and advocating for policies
                that promote sustainable energy practices. Through these
                efforts, CIRCODU aims to contribute to the achievement of
                Sustainable Development Goal 7, which seeks to ensure access to
                affordable, reliable, sustainable, and modern energy for all.
              </p>
            </div>
            <div className="col-md-8">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Objectives of the Energy Thematic Area</b>
              </h>
              <p className="texty"></p>
              <p className="texty">
                <b>Overall Objective:</b> Empower Ugandans to embrace and/ or
                significantly increase the the uptake and application of
                environmentally friendly/ clean energy solutions for cooking,
                lighting, and productive purposes within households,
                institutions, and commercial enterprises throughout the country.
              </p>
              <p className="texty">
                <b>Specific Objectives:</b>
                <ol className="mt-1">
                  <li>
                    <b>Pioneering Clean Energy Solutions:</b> Design and develop
                    innovative, efficient energy technologies that address
                    Uganda's evolving societal energy demands.
                  </li>
                  <li className="mt-1">
                    <b>Rigorous Testing for Reliable Results and Validation:</b> Provide comprehensive laboratory and field testing services
                    to ensure the performance and effectiveness of clean energy
                    technologies.
                  </li>
                  <li className="mt-1">
                    <b>
                      Capacity Building for Clean Energy Champions, Empowering
                      Innovation:
                    </b>{" "}
                    Equip stakeholders with the knowledge and skills necessary
                    to develop, implement, and maintain efficient and affordable
                    clean energy technologies for cooking/heating, lighting, and
                    productive uses.
                  </li>
                </ol>
              </p>
              <p className="texty">
                <b>Actions:</b>
                <ol className="mt-1">
                  <li>
                    <b>Expanding Testing Capabilities:</b> Enhance testing
                    services to encompass:
                  </li>
                  <ul className="mt-2">
                    <li>
                      <b>Emissions Analysis:</b> Measure total emissions and
                      black carbon levels of clean energy technologies.
                    </li>
                    <li>
                      <b>Fuel Evaluation:</b> Evaluate the performance and
                      suitability of various biofuels like briquettes and
                      ethanol.
                    </li>
                    <li>
                      <b>Electric Appliance Efficiency Testing:</b> Evaluate the
                      efficiency and safety of electric cooking appliances
                    </li>
                  </ul>
                  <li className="mt-1">
                    <b>Waste Heat Recovery Innovation:</b> Design and
                    implement a briquette dryer that utilizes recovered waste
                    heat energy, promoting a more sustainable and efficient
                    production process.
                  </li>
                  <li className="mt-1">
                    <b>Empowering Briquette Production:</b> Develop and deliver
                    training programs to equip communities with the knowledge
                    and skills for effective briquette production, fostering
                    local entrepreneurship and clean energy solutions
                  </li>
                </ol>
              </p>
            </div>
            <div className="col-md-4">
              <div className="imagecard">
                <img
                  src={Card1}
                  alt=""
                  height="100%"
                  width="100%"
                  style={{ borderRadius: "5%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SustainableEnergy;
