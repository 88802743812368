import React from "react";
import Card2 from "../Images/Headers/research.jpg";
import Card1 from "../Images/Asset 2-100.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function Research() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Research Projects</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">Our Services</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Research Projects
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Research Projects</b>
              </h>
              <p className="texty">
                CIRCODU, in collaboration with local and international
                organizations, has initiated various projects to advance
                sustainable development. These initiatives encompass a wide
                range of activities, such as conducting assessments of clean
                cooking energy requirements, establishing baseline and end-line
                studies, investigating behavioral changes, gauging willingness
                to pay, and assessing consumer acceptance. Additionally, CIRCODU
                has undertaken national charcoal surveys in Uganda, supported
                local government efforts in energy planning, provided valuable
                customer insights, and evaluated the impact of UpEnergy's
                energy-efficient cookstoves.
              </p>
              <p className="texty">
                Furthermore, CIRCODU actively participates in monitoring carbon
                projects adhering to the Gold Standard or Verra and Clean
                Development Mechanism. To fulfill these responsibilities, the
                organization has executed kitchen performance tests, conducted
                kitchen and usage surveys, carried out emission tests, monitored
                stove usage, performed water boiling tests, and conducted
                spot-check audits at both household and institutional levels.
                CIRCODU also contributes its expertise by reviewing standards
                and quality guidelines related to renewable energy sector
                products and services.
              </p>
            </div>
            <div className="col-md-4">
              <div className="imagecard">
                <img
                  src={Card1}
                  alt=""
                  height="250"
                  width="100%"
                  style={{ borderRadius: "5%" }}
                />
              </div>
            </div>
            <div className="col-md-8 mt-2">
              <p className="texty">
                Beyond its involvement in the energy sector, CIRCODU has engaged
                in research pertaining to sustainable agriculture. Their
                research focuses on technologies for converting animal waste
                into biogas, with the goal of optimizing biogas program
                effectiveness, quality, and service delivery. The organization
                assists development agencies advocating for biogas use by
                constructing business cases for both household biogas users and
                biogas construction companies, taking into account the benefits
                of using bio-slurry as a fertilizer to enhance agricultural
                yields and achieve energy savings through biogas utilization.
              </p>
              <p className="texty">
                Collaborating with partners, CIRCODU has conducted studies on
                wetland restoration in Uganda. This involves developing detailed
                designs for small-scale water storage and detention facilities,
                as well as comprehensive plans for silt traps, drainage, flood
                control, and maximum water recovery. CIRCODU's overarching
                mission in promoting sustainable development is to improve the
                well-being of individuals and communities while safeguarding the
                natural environment for future generations.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Research;
