import React from "react";
import Card2 from "../Images/Headers/team.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import "../Profiles/profileimg.css";
import Card8 from "../Images/Profiles/Jonah.jpg";

function GoodchildIsingomaYonah() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Mr. Goodchild Isingoma Yonah</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">The Team</Breadcrumb.Item>
              <Breadcrumb.Item
                className="text1"
                href="/the-team/the-staff-team"
              >
                The Staff Team
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Mr. Goodchild Isingoma Yonah
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Mr. Goodchild Isingoma Yonah</b>
              </h>
              <br></br>
              <h>
                <b>Transport Officer</b>
              </h>
            </div>
            <div className="col-md-3 mt-2">
              <div className="imagecarded">
                <img
                  src={Card8}
                  alt=""
                  height="auto" width="100%"
                  style={{
                    borderRadius: "45%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                    event.target.style.transform = "scale(1.01)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </div>
            </div>
            <div className="col-md-9 mt-2">
              <p className="texty">
                Isingoma Yonah possesses a secondary education certificate and
                holds a CM & B driving permit. His qualifications encompass
                certificates in competent driving, which have been awarded by
                the Ministry of Works. Additionally, he has obtained a
                certificate in safety observation and corrective action from
                Tullow Oil, along with a defensive driving certificate
                accredited by the Automobile Association of Uganda.
              </p>
              <p className="texty">
                His career journey has featured diverse roles. He began as a
                field assistant at Cash Farm Uganda and subsequently served as
                an agricultural manager at Rweboha Religious Community. Over the
                years, he has also honed his skills as a driver, gaining
                experience at Gate 1 Travel, Mineral Services Ltd, East African
                Diary and Food Limited, and Anifarm Creameries. Presently, he
                holds the role of Transport Officer at CIRCODU.
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-2">
            <div className="container textcenter2">
              <div className="col-md-1">
                <a href="https://ug.linkedin.com/company/centre-for-integrated-research-and-community-development-uganda-circodu" target="_blank" rel="noopener noreferrer">
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <LinkedInIcon
                      sx={{ fontSize: "22px", color: "darkblue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a href="https://twitter.com/CIRCODU1" target="_blank" rel="noopener noreferrer">
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <TwitterIcon sx={{ fontSize: "22px", color: "blue" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a href="https://www.facebook.com/Circodu/" target="_blank" rel="noopener noreferrer">
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <FacebookOutlinedIcon
                      sx={{ fontSize: "22px", color: "blue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="https://wa.me/256751178448"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <WhatsAppIcon sx={{ fontSize: "22px", color: "teal" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="mailto:isingoma.jonah@circodu.org.ug"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <EmailIcon sx={{ fontSize: "22px", color: "red" }} />
                  </IconButton>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default GoodchildIsingomaYonah;
