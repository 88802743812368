import * as React from "react";
import "../Components/CardOwl.css";
import Box from "@mui/material/Box";
import "../Components/Card.css";
import { Link } from "react-router-dom";
import FlipCard from "../Components/FlipCard";
import Card4 from "../Images/CIRCODU-BANNERS-5.jpg";

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const cards = [
  {
    id: "4",
    variant: "hover",
    front: (
      <div>
        <img
          src={Card4}
          alt=""
          style={{
            borderRadius: "5%",
            boxShadow: "none",
            transition: "box-shadow 0.3s ease-in-out",
          }}
        />
      </div>
    ),
    back: (
      <div className="cardp">
        <h5 className="cardh text-center" style={{ color: "#008250" }}>
          Agricultural Management
        </h5>
        <div className="underline-card mx-auto"></div>
        <p className="cardp">
          Agriculture is a critical sector for economic growth, food security,
          and poverty reduction in Uganda. CIRCODU recognizes the need to
          promote sustainable agricultural management practices to improve
          agricultural productivity, enhance food security, and promote
          environmental sustainability. The organization aims to partner with
          other stakeholders to promote the adoption of sustainable agricultural
          practices, such as conservation ....
        </p>
        <div className="text-center">
          <Link
            to="/our-thematic-areas/agricultural-management"
            className="btn btn-outline-success"
            onClick={scrollToTop}
          >
            Read More
          </Link>
        </div>
      </div>
    ),
  },
];

export default function MultiActionAreaCard() {
  return (
    <section className="section">
      <div className="container-fluid">
        <Box sx={{ width: "100%", bgcolor: "white" }} centered>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 d-flex flex-column flex-md-row justify-content-around align-items-center">
                {cards.map((card) => (
                  <FlipCard key={card.id} card={card} />
                ))}
              </div>
            </div>
          </div>
        </Box>
      </div>
    </section>
  );
}
