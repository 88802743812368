import React from "react";
import Card2 from "../Images/Headers/morning.jpg";
import Card1 from "../Images/Asset 2-100.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function MonitoringEvaluation() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Monitoring and Evaluation</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">Our Services</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Monitoring and Evaluation
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Monitoring and Evaluation</b>
              </h>
              <p className="texty">
                Ever since its inception, CIRCODU has been actively engaged in
                the surveillance of indoor air pollution and the advocacy for
                eco-friendly technologies. Specifically, the organization has
                been dedicated to enhancing the performance of wood and charcoal
                cookstoves used in households and institutions, with the
                ultimate goal of diminishing indoor air pollution levels and
                curbing fuel consumption.
              </p>
            </div>
            <div className="col-md-4">
              <div className="imagecard">
                <img
                  src={Card1}
                  alt=""
                  height="250"
                  width="100%"
                  style={{ borderRadius: "5%" }}
                />
              </div>
            </div>
            <div className="col-md-8 mt-2">
              <p className="texty">
                CIRCODU has undertaken monitoring initiatives concerning the
                enhancement of cookstoves, biogas systems, and carbon projects
                related to water resources in Uganda. Furthermore, the
                organization has contributed to independent verification and the
                ongoing monitoring of outcomes for the Uganda Clean Cooking
                Supply Chain Expansion Project. Through these concerted
                endeavors, CIRCODU strives to promote the widespread adoption of
                clean cooking technologies that not only reduce detrimental
                emissions but also lead to improved health outcomes.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MonitoringEvaluation;
